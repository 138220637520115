import { useEffect, useState } from "react";
import styles from "../leaderboard.module.css";
import { Button, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Search } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { TableLoader } from "../../../../components/LoaderC";

const AddLeaderboard = ({ list_id, property_id, setOpenAddLeaderModal, setLoading, setLeaderboards }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [contentLoading, setContentLoading] = useState(false);
    const [allProspectList, setAllProspectList] = useState([]);
    const [prospectList, setProspectList] = useState([]);
    const [filterData, setFilterData] = useState({ searchKey: "" });
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        const fetchProspects = async () => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/leaderboard-prospect-list/" + property_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setContentLoading(false);
                setAllProspectList(response.data.data);
            }).catch((error) => {
                setContentLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        if (property_id > 0)
            fetchProspects();
    }, [property_id, getAccessTokenSilently]);

    useEffect(() => {
        let prospectListTemp = allProspectList;

        if (filterData.searchKey !== "") {
            let inputVal = filterData.searchKey;
            inputVal = inputVal.toLowerCase();
            prospectListTemp = prospectListTemp.filter((item) => {
                let phone2 = item?.phone?.replace(/\D/g, "");
                return (item?.full_name?.toLowerCase().includes(inputVal) || item?.phone?.includes(inputVal) || phone2?.includes(inputVal));
            });
        }

        setProspectList(prospectListTemp);
    }, [allProspectList, filterData]);

    const addProspect = async () => {
        if (selectedIds.length === 0) {
            toast.error('Please select prospect to add to leaderboard.');
            return false;
        }
        setLoading(true);
        setOpenAddLeaderModal(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        await axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-multiple-leaderboard", { property_id: property_id, list_id: list_id, data: JSON.stringify(selectedIds) }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            setLeaderboards(prev => {
                return prev.concat(response.data.data);
            })
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return (<div className={`${styles.ModalFormGroupAdd}`}>
        <form>
            <div className={`${styles.AddDeviceSec}`}>
                <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" value={filterData?.searchKey} onChange={(e) => setFilterData(prev => {
                        return { ...prev, searchKey: e.target.value }
                    })} />
                </div>
            </div>
            <div className={`${styles.AddDeviceRow}`}>

                {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

                {prospectList?.map(item => {
                    return (<div className={`${styles.ProspectCardPop}`}>
                        <div className={`${styles.ProspectCardUnderPop}`}>
                            <div className={`${styles.ProspectCardHeadSection}`}>
                                <div className={`${styles.ProsIMGSec}`}>
                                    {(item?.user_avatar !== "") ? <img src={item?.user_avatar} className={`${styles.defaultImg}`} alt="" /> : ((item?.initial !== "?") ? <p>{item?.initial}</p> : <img src="/static/img/DefaultLogo.png" className={`${styles.defaultImg}`} alt="" />)}
                                </div>
                                <div className={`${styles.HeadConSec}`}>
                                    <div className={`${styles.prospectNameActionSec}`}>
                                        <p className={`${styles.ProsUserName} ${styles.LimitName}`}> {item?.full_name !== "" ? item?.full_name : "-"} </p>
                                        {item?.current_status === 0 && (<span className={`${styles.ProspectNonAppliedBadge}`}>Not Applied</span>)}
                                        {item?.current_status === 1 && (<span className={`${styles.ProspectAppliedBadge}`}>Applied</span>)}
                                        {item?.current_status === 2 && (<span className={`${styles.ProspectScreenedBadge}`}>Screened</span>)}
                                        {item?.current_status === 3 && (<span className={`${styles.ProspectApprovedBadge}`}>Approved</span>)}
                                        {item?.current_status === 4 && (<span className={`${styles.ProspectDeclinedBadge}`}>Declined</span>)}
                                    </div>
                                    <div className={`${styles.prospectNameActionSec}`}>
                                        <p className={`${styles.ProsUserName} ${styles.LimitName}`}>{item?.phone}</p>
                                        {(item?.afford_amount !== '' && item?.check_list?.income === 2) && <span className={`${styles.ProspectDeclinedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>}
                                        {(item?.afford_amount !== '' && item?.check_list?.income === 1) && <span className={`${styles.ProspectApprovedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>}
                                    </div>

                                    <div className={`${styles.ProsUserMandateSec}`}>
                                        <Tooltip title={item?.credit_score !== "" ? "Credit Score - " + item?.credit_score : "Credit Score - N/A"}>
                                            <span className={item?.check_list?.credit_score === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.credit_score === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                                <img src="/static/img/credit-score-icon.svg" alt="" />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={(item?.income_type === 'Voucher') ? (item?.voucher_amount !== "" ? "Voucher - $" + item?.formatted_voucher_amount : "Voucher - N/A") : (item?.income_per_year !== "" ? "Salary - $" + item?.formatted_income_per_year : "Salary - N/A")}>
                                            <span className={item?.check_list?.income === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.income === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                                <img src="/static/img/salary-icon.svg" alt="" />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={item?.ever_had_bankruptcy !== "" ? "Bankruptcy - " + item?.ever_had_bankruptcy : "Bankruptcy - N/A"}>
                                            <span className={item?.check_list?.bankruptcy === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.bankruptcy === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                                <img src="/static/img/bankruptcy-icon.svg" alt="" />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={item?.ever_case_filed !== "" ? "Housing Court Cases - " + item?.ever_case_filed : "Housing Court Cases - N/A"}>
                                            <span className={item?.check_list?.housing_court_case === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.housing_court_case === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                                <img src="/static/img/court-cases-icon.svg" alt="" />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.ProspectCardFootSec}`}>
                                <p className={`${styles.ProsCardDate}`}>&nbsp;</p>
                                <FormControlLabel className={`${styles.CustomCheck} CustomCheck`}
                                    control={
                                        <Checkbox
                                            name={"id" + item?.id}
                                            onChange={(e) => {
                                                console.log(e.target.checked);
                                                if (e.target.checked) {
                                                    setSelectedIds(prev => {
                                                        return prev.concat([item?.id]);
                                                    });
                                                } else {
                                                    setSelectedIds(prev => {
                                                        return prev.filter(i => i !== item?.id);
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>);
                })}

            </div>
            <div className={`${styles.ButtonAction}`}>
                <Button onClick={(e) => setOpenAddLeaderModal(false)} className="CancelPopupBU">Cancel</Button>
                <Button className="SubmitPopupBU" onClick={(e) => addProspect()}>Add</Button>
            </div>
        </form>
    </div>);
}

export default AddLeaderboard;