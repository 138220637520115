import React from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import "./appbar.css";
import { SmartToaster, toast } from "react-smart-toaster";
import { Grid, Hidden, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NotificationsCommon from "./NotificationsCommon";
//import MessagesDropdown from "./MessagesDropdown";
import { Home, Menu, PieChart, Smartphone, Users } from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  return (<React.Fragment>
    <AppBar position="sticky" elevation={0} className="TopHeader">
      <Toolbar className="toolbarPadding">
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item>
            <Link to={"/"}>{" "}<img className="appLogo" src="/static/img/logo.svg" alt="logo" /></Link>
          </Grid>
          <Grid item xs />
          <Grid item>
            {/* <MessagesDropdown /> */}
            <NotificationsCommon />
          </Grid>
        </Grid>

      </Toolbar>
    </AppBar>
    <SmartToaster
      store={toast}
      lightBackground={true}
      position={"top_center"}
    />

    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }} className="MobileHeader">
      <Link className="MobileMenuLink" to={"/dashboard"}><PieChart /><span>Dashboard</span></Link>
      <Link className="MobileMenuLink" to={"/properties/list"}><Home /><span>Properties</span></Link>
      <Link className="MobileMenuLink" to={"/people/tenants"}><Users /><span>People</span></Link>
      <Link className="MobileMenuLink" to={"/smart-devices/list"}><Smartphone /><span>Devices</span></Link>
      <Link className="MobileMenuLink" to={"/more"}><Menu /><span>More</span></Link>
    </AppBar>

  </React.Fragment>);
};

export default withTheme(AppBarComponent);