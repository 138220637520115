import { Check, ChevronDown, Eye, X } from 'react-feather';
import styles from '../rentalappplication.module.css';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-smart-toaster";

const FilesAccordion = ({ expanded, stepData, setStepData, accordinChange, lastStep, setLastStep, setCurrentStep, getNextStep, setPanelExpanded, panelRef, applicantId, setLoading }) => {
    const [uploadFiles, setUploadFiles] = useState([]);

    const schema = yup.object().shape({
        applicantfiles: yup.mixed().test("required", "Please upload supporting documents.", (file) => {
            if (uploadFiles.length !== 0) return true;
            return false;
        })
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (stepData) {
            if (stepData?.fileList) {
                setUploadFiles(JSON.parse(stepData?.fileList));
            }
        }
    }, [stepData]);

    const onSubmit = (data) => {
        let postData = { ...data, is_upload_files: 1, fileList: JSON.stringify(uploadFiles) };
        setStepData(prev => {
            return { ...prev, ...postData };
        });

        if (applicantId) {
            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/save-applicants/' + applicantId, postData);
        }

        let currentStepTemp = getNextStep(7);
        setCurrentStep(currentStepTemp);
        setPanelExpanded('panel' + currentStepTemp);
        if (lastStep < currentStepTemp) {
            setLastStep(currentStepTemp);
        }
    }

    const fileHandler = async (e) => {
        let extErr = false;
        let sizeErr = false;

        let files = e.target.files;
        if (files.length > 0) {
            if (files.length > 0) {
                let item = files[0];
                let fileName = item.name;
                let fileSize = item.size;
                let fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
                fileNameExt = '.' + fileNameExt;

                if (['.pdf', '.png', '.jpg', '.jpeg'].indexOf(fileNameExt) === -1) {
                    extErr = true;
                }

                if (fileSize >= 5242880) {
                    sizeErr = true;
                }
            }

            if (extErr) {
                toast.error("File type is not allowed.");
                return false;
            }

            if (sizeErr) {
                toast.error("File size should not be greater than 5 MB.");
                return false;
            }

            setLoading(true);

            let formData = new FormData();
            formData.append("fileUpload", files[0]);

            axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/applicants/file-upload', formData).then(response => {
                setLoading(false);

                setUploadFiles(prev => {
                    return prev.concat([response.data.data]);
                })
            }).catch(error => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
                setLoading(false);
            });
        }
    }

    const filePreviewFunc = async (item) => {
        let fileType = item.file_type.toLowerCase();
        let file_url = item.file_url;
        if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'pdf' || fileType === 'doc' || fileType === 'docx') {
            window.open(file_url, "_blank");
        } else {
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(file_url), "_blank");
        }
    }

    const deleteFile = (item) => {
        setLoading(true);
        let formData = {
            file: item.file_path
        }

        setUploadFiles(prev => {
            return prev.filter(i => i?.file_path !== item.file_path);
        })

        axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/applicants/file-delete', formData).then(response => {
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        });
    }

    return (<Accordion expanded={expanded === 'panel7'} onChange={accordinChange('panel7', 7)} disabled={lastStep < 7}>
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1bh-content" id="panel1bh-header" ref={panelRef}>
            <div className={`${styles.FormAccoHead}`}>
                <p className={`${styles.FormAccoHeadTitle}`}>Upload files</p>
                <div className={((lastStep > 7) ? `${styles.AccoCheck} ${styles.Active}` : `${styles.AccoCheck}`)}><Check /></div>
            </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.AccoFormSec}`}>

                    <div className={`${styles.DivFull}`}>
                        <p className={`${styles.FileUpTitle}`}>Please upload all the following supporting documents:</p>
                        <ul className={`${styles.FileUpList}`}>
                            {/* <li>Copy of credit report</li> */}
                            {/* <li>Recent W2s</li>
                            <li>Last 4 Pay Stubs or Bank Statement for last 3 months</li>
                            <li>Driver’s License</li> */}
                            <li>Recent W2s or Tax Return</li>
                            <li>Last 4 Pay stubs, Housing Voucher Award Letter  (if applicable)</li>
                            <li>Most Recent Bank Statement</li>
                        </ul>
                    </div>

                    <div className={`${styles.FileIMGDiv}`}>
                        <div className={`${styles.FileUpDuBUDiv}`}>
                            <label for="file-upload" className={`${styles.UploadLabel}`}>
                                <span>
                                    <div className={`${styles.UpIcon}`}><img src="/static/img/upload-floor-RA.png" alt="" /></div>
                                    <p>Upload Files</p>
                                    <p className={`${styles.UploadText}`}>Maximum file size 5MB. Only PDF, png, jpeg files are supported.</p>
                                </span>
                            </label>
                            <input id="file-upload" type="file" onChange={fileHandler} accept={'.pdf,.png,.jpg,.jpeg'} />
                        </div>
                        {(uploadFiles.length > 0) && uploadFiles.map((item, index) => {
                            return <div className={`${styles.UpFileList}`} key={index}>
                                <p className={`${styles.FileName}`}>{item?.file_name}</p>
                                <div className={`${styles.FileListRight}`}>
                                    <p className={`${styles.FileSize}`}>{item?.file_size_str}</p>
                                    <button type='button' onClick={(e) => filePreviewFunc(item)}><Eye /></button>
                                    <button type='button' onClick={(e) => deleteFile(item)}><X /></button>
                                </div>
                            </div>
                        })}
                    </div>

                    <input className="inputBtn" type="hidden" {...register('applicantfiles')} />
                    {(errors.applicantfiles && uploadFiles.length === 0) && <p className={`${styles.ErrorM}`}>{errors.applicantfiles.message}</p>}

                    <div className={`${styles.AccoFormBUsec}`}>
                        <button type="submit" className={`${styles.applicantNextBU}`}>NEXT</button>
                    </div>

                </div>
            </form>
        </AccordionDetails>
    </Accordion >);
}

export default FilesAccordion;