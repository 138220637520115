import React, { useEffect } from "react";
import moment from 'moment'
import styles from './complaintsdetails.module.css';
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Helmet from "react-helmet";
import { Home, MoreVertical, Trash, Edit, Search, Plus, X, Trash2, Clock, Calendar, ArrowLeft, ChevronDown, } from "react-feather";
import { Typography, Box, AppBar, Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Dropdown } from 'react-bootstrap';
import Footer from "../../components/Footer";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import Select from 'react-select';
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { AddLogCallAPI } from "../../components/AddLogs";
import { useAuth0 } from "@auth0/auth0-react";
import UploaderComponent from '../../components/UploaderComponent';

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};


const defaultTimeStart = moment("12:00 AM", "LT");
const schemaAppointment = yup.object().shape({
  assignee_id: yup.object().required('This field is Required'),
  date: yup.date().required('This field is Required'),
  time: yup.date().required('This field is Required'),
});
const schemaNote = yup.object().shape({
  note: yup.string().required('This field is Required').max(200, 'Notes cannot be more than 200 characters'),
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function SimpleTabs(props) {
  const { user, isAuthenticated } = useAuth0();
  const { register: registerAppointment, control, handleSubmit: appointmentSubmit, errors: appointmenterrors } = useForm({
    resolver: yupResolver(schemaAppointment)
  });
  const { register, handleSubmit, errors, setValue: setNoteValue } = useForm({
    resolver: yupResolver(schemaNote)
  });
  let alldata = props.itemData;
  let appointments = [];
  if (props.itemData.appointments) {
    appointments = props.itemData.appointments;
  }
  let histories = [];
  if (props.itemData.histories) {
    histories = props.itemData.histories;
  }
  let images = [];
  if (props.itemData.images) {
    images = props.itemData.images;
  }
  let asssigneeList = [];
  asssigneeList = props.filterAssigneeList

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.noteID) {
      async function fetchByID() {
        await axios.get(props.audience + 'v1/complaints/' + alldata.id + '/notes/' + props.noteID, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }).then(response => {
          const resData = response.data.data;
          const fields = ['note'];
          fields.forEach(field => setNoteValue(field, resData[field]));

        }).catch(error => {
          if (typeof error.response !== 'undefined')
            toast.error(error.response.data.message);
        });
      }
      fetchByID();
    }


  }, [alldata.id, props.token, props.noteID, props.audience, setNoteValue]);

  let tabIndex = -1;
  let tabPanelIndex = -1;

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Summary" {...a11yProps(++tabIndex)} />
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-appointment:crud') > -1 || user['https://propertydek.com/permissions'].indexOf('complaint-appointment:read') > -1)) && <Tab label="Appointments" {...a11yProps(++tabIndex)} />}
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-note:show') > -1)) && <Tab label="Notes" {...a11yProps(++tabIndex)} />}
          {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-history:show') > -1)) && <Tab label="History" {...a11yProps(++tabIndex)} />}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={++tabPanelIndex}>
        <div className={`${styles.BorderDivSumm}`}>
          <div className={`${styles.Row}`}>
            <div className={`${styles.SummFormFull}`}>
              <p className={`${styles.SummFormLabel}`}>Description</p>
              <p className={`${styles.DesText}`}>{alldata.description}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.BorderDivSumm}`}>
          <p className={`${styles.SummFormLabel}`}>Photos</p>

          <div className={`${styles.Row}`}>
            {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <UploaderComponent allowedExtensions={".jpg,.png,.jpeg,.svg"} isMuliple={true} maxFileSize={5242880} uploadUrl={process.env.REACT_APP_CASE_API_URL + 'v1/complaints/' + props?.itemData?.id + '/files/upload'} setLoading={setLoading.bind(this)} onUploadLocal={props?.onFileUploadSuccess.bind(this)} buttonText={"Upload Photo"} helpText={"File size upto 5 MB  JPEG, JPG, PNG"} />}

            {images && images.map((itemFile, indexF) => {
              return <div key={indexF} className={`${styles.SummFormSemiHalf}`}>
                <img style={{ cursor: "pointer" }} onClick={props.imageShow.bind(this, itemFile)} src={itemFile.file_public_url} alt="" />
                {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <Button onClick={props.deleteImageModalShow.bind(this, itemFile, indexF)}><X /></Button>}
              </div>
            })}
          </div>
          <Dialog
            open={props.imageDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={props.delImageModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
          >
            <DialogTitle id="alert-dialog-title">Delete Image<button onClick={props.delImageModalClose}><X /></button></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="ModalFormGroup">
                  <label className="PopupBodyText">Are you sure you want to delete this file?</label>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.deleteImage} className="DeletePopupBU">Delete</Button>
              <Button onClick={props.delImageModalClose} className="CancelPopupBU">Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>
      </TabPanel>
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-appointment:crud') > -1 || user['https://propertydek.com/permissions'].indexOf('complaint-appointment:read') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        <div className={`${styles.BorderDivSumm}`}>
          <div className={`${styles.AppointmentsHead}`}>
            <p className={`${styles.SummFormLabel}`}>Appointments</p>
            {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <button onClick={props.appointmentOpen.bind(this, '')} className={`${styles.AddNoteBu}`}><Plus /></button>}
          </div>


          {props.appoitmentModalOpen && <div className={`${styles.AddCommDiv}`}>
            <form onSubmit={appointmentSubmit(props.appointmentSubmit.bind(this))}>
              <div className={`${styles.AppoAddTab}`}>
                <div className={`${styles.AppoDropDown}`}>
                  <p className={`${styles.SummFormLabelForm}`}>Assignee<span className={`${styles.errorSpan}`}>*</span></p>
                  <Controller
                    name="assignee_id"
                    control={control}
                    options={asssigneeList}
                    as={Select}
                    ref={registerAppointment}
                  />
                  {appointmenterrors.assignee_id && <p className={`${styles.ErrorM}`}>{appointmenterrors.assignee_id.message}</p>}
                </div>
                <div className={`${styles.AppoDropDown}`}>
                  <p className={`${styles.SummFormLabelForm}`}>Date<span className={`${styles.errorSpan}`}>*</span></p>
                  <div className={`${styles.TimePick} TimePick`}>
                    <Calendar className={`${styles.ClockIcon}`} />
                    <Controller
                      defaultValue={moment()}
                      control={control}
                      name="date"
                      ref={registerAppointment}
                      render={(props) => (
                        <DatePicker
                          format="MM/dd/yyyy"
                          value={props.value}
                          onChange={(e) => props.onChange(e)}
                        />
                      )}
                    />
                    {appointmenterrors.date && <p className={`${styles.ErrorM}`}>{appointmenterrors.date.message}</p>}

                  </div>
                </div>
                <div className={`${styles.AppoDropDown}`}>
                  <p className={`${styles.SummFormLabelForm}`}>Time<span className={`${styles.errorSpan}`}>*</span></p>
                  <div className={`${styles.TimePick} TimePick`}>
                    <Clock className={`${styles.ClockIcon}`} />
                    <Controller
                      defaultValue={defaultTimeStart}
                      control={control}
                      name="time"
                      render={(props) => (
                        <TimePicker
                          value={props.value}
                          onChange={(e) => props.onChange(e)}
                        />
                      )}
                    />
                    {appointmenterrors.time && <p className={`${styles.ErrorM}`}>{appointmenterrors.time.message}</p>}

                  </div>
                </div>
              </div>
              <input onClick={props.appointmentModalClose.bind()} type="button" value="Cancel" className={`${styles.ButtonVoiletLine}`} />
              <input type="submit" value="Add" className={`${styles.ButtonVoiletSolid}`} />
            </form>
          </div>}

          {appointments.map((itemApt, indexA) => {
            return <div className={`${styles.NotesRow}`}>

              <div className={`${styles.NotesCard}`}>
                <div className={`${styles.PropeCardInFR}`}>
                  <div className={`${styles.MainReqConDivBig}`}>
                    <p className={`${styles.InsideConName}`}>{itemApt.assignee_name}</p>
                  </div>
                  <div className={`${styles.MainReqConDivSmall}`}>
                    <div className={`${styles.MainReqConUR}`}>
                      <p className={`${styles.MainReqConDe}`}>{moment(itemApt.date).format("L")}</p>
                      <p className={`${styles.MainReqConDe}`}>{itemApt.time}</p>
                    </div>
                  </div>
                  {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1) && <div className={`${styles.MainReqConAction}`}>
                    <div className={`${styles.MainReqConUR}`}>
                      <Button onClick={props.appointmentDeleteModal.bind(this, itemApt.id)}><Trash2 /></Button>

                    </div>
                  </div>}
                </div>
              </div>

            </div>
          })}
          {appointments.length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
              <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p></div>}
          <Dialog
            open={props.appointmentDeleteModalOpen}
            fullWidth
            maxWidth="sm"
            onClose={props.delAppointmentClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
          >

            <DialogTitle id="alert-dialog-title">Delete Appointment <button onClick={props.delAppointmentClose}><X /></button></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="ModalFormGroup">
                  <label className="PopupBodyText" >Are you sure you want to delete this item?</label>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.delAppointment} className="DeletePopupBU">
                Delete
              </Button>
              <Button onClick={props.delAppointmentClose} className="CancelPopupBU">
                Cancel
              </Button>
            </DialogActions>

          </Dialog>

        </div>
      </TabPanel>}
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-note:show') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        <div>
          <div className={`${styles.NotesSearchArea}`}>
            <div className={`${styles.NotesSearchMain}`}>
              <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={props.searchData.bind(this)} />
              <Search />
            </div>
            {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint-note:create') > -1) && <button onClick={props.handleClickOpen.bind(this, '')} className={`${styles.AddNoteBu}`}><Plus /></button>}
          </div>
          {props.noteModalOpen && !props.noteID && <div className={`${styles.AddCommDiv}`}>
            <form id="myForm" onSubmit={handleSubmit(props.notesubmit.bind(this))}>
              <div className={`${styles.formGroup}`}>

                <textarea placeholder="Add text here..." type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register}></textarea>

                {errors.note && <p className={`${styles.ErrorM}`}>{errors.note.message}</p>}
              </div>
              <Button onClick={props.handleClose2} className={`${styles.ButtonVoiletLine}`}>
                Cancel
              </Button>
              <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>
                {((props.noteID) ? 'Update' : 'Add')}
              </Button>
            </form>
          </div>}

          {props.noteSearchItems().map((itemNote, indexN) => {
            return <div className={`${styles.ReferBodySummCommNotes}`} key={indexN}>
              <img src={(itemNote.user_image === '') ? "/static/img/default_avataar.svg" : itemNote.user_image} alt="" />
              <div className={`${styles.ReferBodySummCommBody}`}>
                <div className={`${styles.NoteListTitle}`}>
                  <p className={`${styles.SummCommBodyName}`}>{itemNote.user_name} <span>{itemNote.role} @{itemNote.property}</span></p>
                  <div className={`${styles.NoteListIcons}`}>
                    {(isAuthenticated && user['https://propertydek.com/permissions'].indexOf('complaint-note:update') > -1) && <Dropdown className={`${styles.DropDownMain}`}>
                      <Dropdown.Toggle id="dropdown-basic" className={`${styles.FilesUpCardDD}`}>
                        <MoreVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="DropDownMenuBoxCus">
                        <Dropdown.Item className="DropDListWIcon" onClick={props.handleClickOpen.bind(this, itemNote.id)}><Edit /> Edit</Dropdown.Item>
                        <Dropdown.Item className="DropDListWIcon" onClick={props.noteDeleteModalOpen.bind(this, itemNote.id)}><Trash /> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>}
                  </div>
                </div>
                <p className={`${styles.SummCommBodyDate}`}>{itemNote.time_str}</p>
                <p className={`${styles.SummCommBodyText}`}>



                  {(props.noteModalOpen && props.noteID && (props.noteID === itemNote.id)) && <div className={`${styles.AddCommDiv}`}>
                    <form id="myForm" onSubmit={handleSubmit(props.notesubmit.bind(this))}>
                      <div className={`${styles.formGroup}`}>

                        <textarea placeholder="Add text here..." type="text" className={`${styles.ReferCommentArea}`} name="note" ref={register}></textarea>

                        {errors.note && <p className={`${styles.ErrorM}`}>{errors.note.message}</p>}
                      </div>
                      <Button onClick={props.handleClose2} className={`${styles.ButtonVoiletLine}`}>
                        Cancel
                      </Button>
                      <Button type="submit" className={`${styles.ButtonVoiletSolid}`}>
                        {((props.noteID) ? 'Update' : 'Add')}
                      </Button>
                    </form>
                  </div>}

                  {(!props.noteID) && itemNote.note}


                </p>
              </div>
            </div>
          })}
          {props.noteSearchItems().length === 0 && <div className={`${styles.NoDataMain}`}>
            <div className={`${styles.NoDataIMG}`}>
              <img src="/static/img/nodata.svg" alt="No Data" />
            </div>
            <p className={`${styles.NoDataText}`}>No Data Found</p></div>}

          <Dialog
            open={props.noteDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={props.delNoteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
          >

            <DialogTitle id="alert-dialog-title">Delete Note <button onClick={props.delNoteClose}><X /></button></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="ModalFormGroup">
                  <label className="PopupBodyText">Are you sure you want to delete this item?</label>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.delNote} className="DeletePopupBU">
                Delete
              </Button>
              <Button onClick={props.delNoteClose} className="CancelPopupBU">
                Cancel
              </Button>
            </DialogActions>

          </Dialog>
        </div>
      </TabPanel>}
      {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint-history:show') > -1)) && <TabPanel value={value} index={++tabPanelIndex}>
        {histories && histories.map((itemHistory, indexH) => {
          return <div className={`${styles.BorderDivSumm}`} key={indexH}>
            <div className={`${styles.HistoryTitle}`}>
              <img src="/static/img/RollCheck.svg" alt="" />
              <p className={`${styles.HistoryTitleCon}`}>{itemHistory.title} <span>{moment(itemHistory.created_at).format("L")}</span></p>
            </div>
            <div className={`${styles.BorderDivSummHis}`}>
              <p className={`${styles.SummCommBodyText}`} dangerouslySetInnerHTML={{ __html: itemHistory.description }}></p>
            </div>
          </div>
        })}
        {histories.length === 0 && <div className={`${styles.NoDataMain}`}>
          <div className={`${styles.NoDataIMG}`}>
            <img src="/static/img/nodata.svg" alt="No Data" />
          </div>
          <p className={`${styles.NoDataText}`}>No Data Found</p></div>}
      </TabPanel>}
    </div >
  );
}

class ComplaintsDetails extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.id = this.props.match.params.id;
    this.state = {
      allData: [], data: [], filterStatusList: [{ value: 0, label: 'Open' }, { value: 1, label: 'In-Progress' }, { value: 2, label: 'Resolved' }, { value: 3, label: 'Cancelled' }], filterPriorityList: [{ value: 1, label: 'Low' }, { value: 2, label: 'Medium' }, { value: 3, label: 'High' }], filterAssigneeList: [], statSelectHtml: '', assigneeSelectHtml: '', prioritySelectHtml: '', noteModalOpen: false, noteID: '', noteDeleteModal: false, deNoteID: '', noteSearchKey: '', appoitmentModalOpen: false, appoitmentID: '', appointmentDeleteModalOpen: false, delAppoitmentID: '', rowDeleteModal: false, rowDelID: '', imageDeleteModal: false, rowImageID: '', rowArchiveModal: false, rowArchiveID: ''
    };
    this.audience = process.env.REACT_APP_CASE_API_URL;
    this.fileAllowedExtensions = '.jpg,.png,.jpeg,.svg'
    this.fileAsyncSettings = {
      saveUrl: process.env.REACT_APP_CASE_API_URL + 'v1/complaints/' + this.props.match.params.id + '/files/upload',
      removeUrl: ''
    };

  }
  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    this.getAssigneeList();
    if (this.state.filterAssigneeList) {
      this.getDetails(this.id);
    }


  }
  async getDetails(fId) {
    try {
      const { user, isAuthenticated } = this.props.auth0;
      this.setState({ ...this.state, contentLoading: true });
      await axios.get(this.audience + 'v1/complaints/' + fId, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let seSelect = this.state.filterStatusList.filter(i => i.value === response.data.data.current_status);
        let statusDrop = <Select styles={customStyles} onChange={this.changeCStatus.bind(this, response.data.data, response.data.data.current_status)} defaultValue={seSelect} options={this.state.filterStatusList} isDisabled={!(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1))} />;

        let seSelectAssignee = this.state.filterAssigneeList.filter(i => i.value === response.data.data.assignee_id);
        let assigneeDrop = <Select styles={customStyles} onChange={this.changeAssignee.bind(this, response.data.data, response.data.data.assignee_id)}
          defaultValue={seSelectAssignee}
          options={this.state.filterAssigneeList} />;
        let sePriority = this.state.filterPriorityList.filter(i => i.value === response.data.data.priority);
        let priorityDrop = <Select styles={customStyles} onChange={this.changePriority.bind(this, response.data.data, response.data.data.priority)} defaultValue={sePriority} options={this.state.filterPriorityList} isDisabled={!(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1))} />;
        this.setState({
          ...this.state, data: response.data.data, statSelectHtml: statusDrop, assigneeSelectHtml: assigneeDrop, prioritySelectHtml: priorityDrop, contentLoading: false
        });

      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false, contentLoading2: false });
    }
  }
  async getAssigneeList() {
    try {
      this.setState({ ...this.state, contentLoading: true });
      await axios.get(this.audience + 'v1/assignees', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        this.setState({
          ...this.state, filterAssigneeList: response.data.data, contentLoading: false
        });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false, contentLoading2: false });
    }
  }
  searchData(e) {
    let searchKey = e.target.value;
    searchKey = searchKey.toLowerCase();
    this.setState({ ...this.state, noteSearchKey: searchKey }, this.noteSearchItems);
  }

  noteSearchItems = () => {
    let searchKey = this.state.noteSearchKey;
    let filteredData = [];
    if (this.state.data && this.state.data.notes)
      filteredData = this.state.data.notes;
    if (searchKey !== '') {
      filteredData = filteredData.filter((item) => item.note.toLowerCase().includes(searchKey));
    }
    return filteredData;
  }

  changeCStatus(data, cVal, e) {
    if (e.value !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/complaints/' + data.id,
        { 'current_status': e.value }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(response => {
        let newData = this.state.data;
        let respData = response.data.data;
        newData = { ...newData, current_status: e.value };
        toast.success(response.data.message);
        this.setState({ ...this.state, data: newData, loading: false });
        this.getDetails(this.id)
        let statusText = 'Open';
        if (respData.current_status === 1) {
          statusText = 'In-Progress';
        }
        if (respData.current_status === 2) {
          statusText = 'Resolved';
        }
        if (respData.current_status === 3) {
          statusText = 'Cancelled';
        }
        let logData = {
          'title': 'Complaint status changed',
          'description': [
            'Name: ' + respData.title,
            'Complaint number: ' + respData.complaint_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
            'Status: ' + statusText,
          ]
        }
        AddLogCallAPI(logData, this.token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  changeAssignee(data, cVal, e) {
    if (e.value !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/complaints/' + data.id,
        { 'assignee_id': e.value }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(response => {
        let newData = this.state.data;
        newData = { ...newData, assignee_id: e.value };
        toast.success(response.data.message);
        this.setState({ ...this.state, data: newData, loading: false });
        this.getDetails(this.id)
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  changePriority(data, cVal, e) {
    if (e.value !== cVal) {
      this.setState({ ...this.state, loading: true });
      axios.put(this.audience + 'v1/complaints/' + data.id,
        { 'priority': e.value }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(response => {

        let newData = this.state.data;
        let respData = response.data.data;
        newData = { ...newData, priority: e.value };
        toast.success(response.data.message);
        this.setState({ ...this.state, data: newData, loading: false });
        this.getDetails(this.id);
        let logData = {
          'title': 'Complaint priority changed',
          'description': [
            'Name: ' + respData.title,
            'Complaint number: ' + respData.complaint_no,
            'Property: ' + respData.property_name,
            'Apartment: ' + respData.apt_name,
            'Tenant: ' + respData.tenant_name,
            'Priority: ' + respData.priority_text,
          ]
        }
        AddLogCallAPI(logData, this.token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  archiveRowOpen(rowarchiveID, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, rowArchiveModal: true, rowarchiveID: rowarchiveID });
  }
  archiveModalClose() {
    this.setState({ ...this.state, rowArchiveModal: false, rowarchiveID: '' });
  }

  rowAchive(e) {
    this.setState({ ...this.state, rowArchiveModal: false, loading: true });
    let data1 = [this.state.data].filter(item => item.id === this.state.rowarchiveID);
    let data = data1[0];
    let is_archived = (1 - data.is_archived);
    axios.put(this.audience + 'v1/complaints/' + data.id,
      { 'is_archived': is_archived }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      let newData = this.state.data;
      let respData = response.data.data;
      newData = { ...newData, is_archived: is_archived };
      toast.success(response.data.message);
      this.setState({ ...this.state, data: newData, loading: false, rowArchiveModal: false, rowarchiveID: '' });
      let logData = {
        'title': (is_archived ? 'Complaint is archived' : 'Complaint is unarchived'),
        'description': [
          'Name: ' + respData.title,
          'Complaint number: ' + respData.request_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });
    });
  }

  deleteRowOpen(rowDelID, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, rowDeleteModal: true, rowDelID: rowDelID });
  }

  delModalClose() {
    this.setState({ ...this.state, rowDeleteModal: false, rowDelID: '' });
  }

  rowDelete() {
    this.setState({ ...this.state, rowDeleteModal: false, loading: true });
    return axios.delete(this.audience + 'v1/complaints/' + this.state.rowDelID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let respData = response.data.data;
      this.setState({ ...this.state, rowDelID: '', loading: false, rowDeleteModal: false });

      let logData = {
        'title': 'Complaint is deleted',
        'description': [
          'Name: ' + respData.title,
          'Complaint number: ' + respData.request_no,
          'Property: ' + respData.property_name,
          'Apartment: ' + respData.apt_name,
          'Tenant: ' + respData.tenant_name,
        ]
      }
      AddLogCallAPI(logData, this.token);
      setTimeout(() => {
        this.props.history.push('/cases/complaints');
      }, 2000);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }


  fileUploadButtonClick() {
    document.getElementById('fileUploadID').getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
    return false;
  }
  onFileSelected(args) {
    if (args.filesData[0].statusCode === '0') {
      if (args.filesData[0].validationMessages.maxSize === '') {
        toast.error(args.filesData[0].status);
      } else {
        toast.error('The image size should not be greater than 5MB.');
      }
    }
  }
  onFileFileUpload(args) {
    if (args.file.statusCode === "0") {
      toast.error(args.statusText);
    }
    this.setState({ ...this.state, loading: false });
  }
  onFileFileProgress(args) {
    this.setState({ ...this.state, loading: true });
  }
  async onFileUploadSuccess(files) {
    let all_files = this.state.data.images;
    all_files = files.concat(all_files);
    let data = { ...this.state.data, images: [] };
    this.setState({ ...this.state, data: data });
    let data2 = { ...this.state.data, images: all_files };
    this.setState({ ...this.state, data: data2 });


    toast.success('Files uploaded successfully.');
    let logData = {
      'title': 'Complaint image uploaded',
      'description': [
        'Name: ' + this.state.data.title,
        'Complaint number: ' + this.state.data.complaint_no,
        'Property: ' + this.state.data.property_name,
        'Apartment: ' + this.state.data.apt_name,
        'Tenant: ' + this.state.data.tenant_name,

      ]
    }
    AddLogCallAPI(logData, this.token);
  }

  deleteImageModalShow(rowImageID, event) {
    this.setState({ ...this.state, imageDeleteModal: true, rowImageID: rowImageID.id });
  }
  delImageModalClose() {
    this.setState({ ...this.state, imageDeleteModal: false, rowImageID: '' });
  }
  deleteImage() {
    this.setState({ ...this.state, loading: true });

    return axios.delete(this.audience + 'v1/complaints/' + this.state.data.id + '/files/' + this.state.rowImageID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let all_files = this.state.data.images.filter(item => item.id !== this.state.rowImageID);
      let data = { ...this.state.data, images: all_files };
      this.setState({ ...this.state, data: data, loading: false, imageDeleteModal: false, rowImageID: '' });
      let logData = {

        'title': 'Complaint image deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Complaint number: ' + this.state.data.complaint_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,

        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }

  noteDeleteModalOpen(noteID, event) {
    this.setState({ ...this.state, noteDeleteModal: true, deNoteID: noteID });
  }

  delNoteClose() {
    this.setState({ ...this.state, noteDeleteModal: false });
  };

  delNote() {
    this.setState({ ...this.state, noteDeleteModal: false, loading: true });
    let fId = this.state.data.id;
    return axios.delete(this.audience + 'v1/complaints/' + fId + '/notes/' + this.state.deNoteID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let allNotes = this.state.data.notes.filter(item => item.id !== this.state.deNoteID);
      let notes = this.state.data.notes.filter(item => item.id === this.state.deNoteID);
      let data = { ...this.state.data, notes: allNotes };
      this.setState({ ...this.state, data: data, noteID: '', loading: false });
      let logData = {
        'title': 'Complaint comment is deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Complaint number: ' + this.state.data.complaint_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,
          'Comment: ' + notes[0].note,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }

  handleClickOpen(noteID, event) {
    this.setState({ ...this.state, noteModalOpen: true, noteID: noteID, appoitmentModalOpen: false, appointmentID: '' });
  };

  handleClose2() {
    this.setState({ ...this.state, noteModalOpen: false, noteID: false });
  };

  notesubmit(data) {
    this.setState({ ...this.state, noteModalOpen: false, loading: true });
    let mrid = this.state.data.id;
    const postData = data;
    if (!this.state.noteID) {
      return axios.post(this.audience + 'v1/complaints/' + mrid + '/notes', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {

        toast.success(response.data.message);
        let notes = response.data.data;
        let respData = response.data.data;
        let allNotes = this.state.data.notes;
        allNotes = [notes].concat(allNotes);
        let data = { ...this.state.data, notes: allNotes };
        this.setState({ ...this.state, data: data, noteID: '', loading: false });
        let logData = {
          'title': 'Complaint new note is created',
          'description': [
            'Name: ' + this.state.data.title,
            'Complaint number: ' + this.state.data.complaint_no,
            'Property: ' + this.state.data.property_name,
            'Apartment: ' + this.state.data.apt_name,
            'Tenant: ' + this.state.data.tenant_name,
            'Comment: ' + respData.note,
          ]
        }

        AddLogCallAPI(logData, this.token);
      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    } else {
      return axios.put(this.audience + 'v1/complaints/' + mrid + '/notes/' + this.state.noteID, postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let notes = response.data.data;
        let respData = response.data.data;
        let allNotes = this.state.data.notes.map(el => (
          el.id === this.state.noteID ? { ...el, note: notes.note } : el
        ))
        let data = { ...this.state.data, notes: allNotes };
        this.setState({ ...this.state, data: data, noteID: '', loading: false });
        let logData = {
          'title': 'Complaint note is updated',
          'description': [
            'Name: ' + this.state.data.title,
            'Complaint number: ' + this.state.data.complaint_no,
            'Property: ' + this.state.data.property_name,
            'Apartment: ' + this.state.data.apt_name,
            'Tenant: ' + this.state.data.tenant_name,
            'Comment: ' + respData.note,
          ]
        }
        AddLogCallAPI(logData, this.token);


      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }

  };

  appointmentOpen(appointmentID, event) {
    this.setState({ ...this.state, appoitmentModalOpen: true, appointmentID: appointmentID, noteModalOpen: false, noteID: false });
  }

  appointmentModalClose() {
    this.setState({ ...this.state, appoitmentModalOpen: false, appointmentID: '' });
  }

  appointmentSubmit(data) {
    this.setState({ ...this.state, appoitmentModalOpen: false, loading: true });
    let mrid = this.state.data.id;

    let postData = {
      date: moment(data.date).format('YYYY-MM-DD'),
      time: moment(data.time).format('LT'),
      assignee_id: data.assignee_id.value
    }
    if (!this.state.appointmentID) {
      return axios.post(this.audience + 'v1/complaints/' + mrid + '/appointments', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let appointments = response.data.data;
        let respData = response.data.data;
        let allAppointments = this.state.data.appointments;
        allAppointments = [appointments].concat(allAppointments);
        let data = { ...this.state.data, appointments: allAppointments };
        this.setState({ ...this.state, data: data, appointmentID: '', loading: false });
        this.setState({ ...this.state, loading: false });
        let logData = {
          'title': 'Complaint new appointment is created',
          'description': [
            'Name: ' + this.state.data.title,
            'Complaint number: ' + this.state.data.complaint_no,
            'Property: ' + this.state.data.property_name,
            'Apartment: ' + this.state.data.apt_name,
            'Tenant: ' + this.state.data.tenant_name,
            'Assigee: ' + respData.assignee_name,
            'Appointment date: ' + moment(respData.date).format("L"),
            'Appointment time: ' + respData.time,
          ]
        }
        AddLogCallAPI(logData, this.token);

      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    } else {
      return axios.put(this.audience + 'v1/complaints/' + mrid + '/notes/' + this.state.noteID, postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let notes = response.data.data;
        let allNotes = this.state.data.notes.map(el => (
          el.id === this.state.noteID ? { ...el, note: notes.note } : el
        ))
        let data = { ...this.state.data, notes: allNotes };
        this.setState({ ...this.state, data: data, noteID: '', loading: false });


      }).catch(error => {
        if (typeof error.response !== 'undefined')
          toast.error(error.response.data.message);
        this.setState({ ...this.state, loading: false });
      });
    }

  }

  appointmentDeleteModal(appointmentDelID, event) {
    this.setState({ ...this.state, appointmentDeleteModalOpen: true, delAppoitmentID: appointmentDelID });
  }

  delAppointmentClose() {
    this.setState({ ...this.state, appointmentDeleteModalOpen: false });
  };

  delAppointment() {
    this.setState({ ...this.state, appointmentDeleteModalOpen: false, loading: true });
    let fId = this.state.data.id;
    return axios.delete(this.audience + 'v1/complaints/' + fId + '/appointments/' + this.state.delAppoitmentID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let allAppointments = this.state.data.appointments.filter(item => item.id !== this.state.delAppoitmentID);
      let appointments = this.state.data.appointments.filter(item => item.id === this.state.delAppoitmentID);;
      let data = { ...this.state.data, appointments: allAppointments };
      this.setState({ ...this.state, data: data, delAppoitmentID: '', loading: false });
      let logData = {
        'title': 'Complaint appointment is deleted',
        'description': [
          'Name: ' + this.state.data.title,
          'Complaint number: ' + this.state.data.complaint_no,
          'Property: ' + this.state.data.property_name,
          'Apartment: ' + this.state.data.apt_name,
          'Tenant: ' + this.state.data.tenant_name,
          'Assigee: ' + appointments[0].assignee_name,
          'Appointment date: ' + moment(appointments[0].date).format("L"),
          'Appointment time: ' + appointments[0].time,
        ]
      }
      AddLogCallAPI(logData, this.token);
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
      this.setState({ ...this.state, loading: false });

    });
  }

  imageShow(data, e) {
    window.open(data.file_public_url, "_blank");
  }

  render() {
    const { user, isAuthenticated } = this.props.auth0;

    return (<React.Fragment>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className={`${styles.LoaderCard}`}><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <Helmet title={this.state.data.title} />}
      <div className={`${styles.pageTitleWrap}`}>
        <div>
          <button onClick={() => this.props.history.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /> Back</button>
          <Typography display="inline" className={`${styles.pageTitle}`}>{this.state.data.title}</Typography>
        </div>

        <nav aria-label="breadcrumb">
          <ol className={`${styles.breadcrumb}`}>
            <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Cases</li>
            <li className={`${styles.breadcrumbItem}`}><Link to={'/cases/complaints'} >Complaint</Link></li>
            <li className={`${styles.breadcrumbItem} ${styles.active}`}>{this.state.data.title}</li>
          </ol>
        </nav>
      </div>
      {!this.state.contentLoading && <div className={`${styles.mainCard}`}>

        <div className={`${styles.SMReferDeBigDiv}`}>
          <div className={`${styles.Card}`}>

            <div className={`${styles.ActionRefMain}`}>
              <div className={`${styles.TitleSummary}`}><p className={`${styles.ReqDeTitle}`}>{this.state.data.title}</p>
                {this.state.data.overdue && <p className={`${styles.TagDiv} ${styles.Red}`}>{this.state.data.overdue}</p>}
              </div>
              <div className={`${styles.InsideConCheck}`}>
                {(isAuthenticated && (user['https://propertydek.com/permissions'].indexOf('complaint:update') > -1 || user['https://propertydek.com/permissions'].indexOf('complaint:delete') > -1)) && <Dropdown>
                  <Dropdown.Toggle className={`${styles.ActionsBU} ActionsBU`} id="dropdown-basic">
                    Actions <ChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                    <Dropdown.Item className={`${styles.ActionsBUListItem}`} as={Link} to={`/cases/complaints/edit/${this.id}`}>Edit</Dropdown.Item>
                    <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={this.archiveRowOpen.bind(this, this.state.data.id)}>{this.state.data.is_archived ? 'Unarchive' : 'Archive'}</Dropdown.Item>
                    <Dropdown.Item className={`${styles.ActionsBUListItem} ${styles.Delete}`} onClick={this.deleteRowOpen.bind(this, this.state.data.id)}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
              </div>
            </div>
            <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
              <SimpleTabs itemState={this.state} itemData={this.state.data} filterAssigneeList={this.state.filterAssigneeList} appoitmentModalOpen={this.state.appoitmentModalOpen} appoitmentID={this.state.appoitmentID} appointmentOpen={this.appointmentOpen.bind(this)} appointmentModalClose={this.appointmentModalClose.bind(this)} appointmentSubmit={this.appointmentSubmit.bind(this)} appointmentDeleteModalOpen={this.state.appointmentDeleteModalOpen} appointmentDeleteModal={this.appointmentDeleteModal.bind(this)} delAppointmentClose={this.delAppointmentClose.bind(this)} delAppointment={this.delAppointment.bind(this)} delAppoitmentID={this.state.delAppoitmentID} searchData={this.searchData.bind(this)} noteSearchItems={this.noteSearchItems.bind(this)} notesubmit={this.notesubmit.bind(this)} delNote={this.delNote.bind(this)} noteDeleteModal={this.state.noteDeleteModal} noteDeleteModalOpen={this.noteDeleteModalOpen.bind(this)} delNoteClose={this.delNoteClose.bind(this)} deNoteID={this.state.deNoteID} noteID={this.state.noteID} noteModalOpen={this.state.noteModalOpen} handleClickOpen={this.handleClickOpen.bind(this)} handleClose2={this.handleClose2.bind(this)} fileUploadButtonClick={this.fileUploadButtonClick.bind(this)} fileAsyncSettings={this.fileAsyncSettings} fileAllowedExtensions={this.fileAllowedExtensions} onFileUploadSuccess={this.onFileUploadSuccess.bind(this)} onFileFileUpload={this.onFileFileUpload.bind(this)} onFileFileProgress={this.onFileFileProgress.bind(this)} audience={this.audience} imageShow={this.imageShow.bind(this)} token={this.token} onFileSelected={this.onFileSelected.bind(this)} imageDeleteModal={this.state.imageDeleteModal} rowImageID={this.state.rowImageID} deleteImageModalShow={this.deleteImageModalShow.bind(this)} delImageModalClose={this.delImageModalClose.bind(this)} deleteImage={this.deleteImage.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className={`${styles.SMProCusSmallDiv}`}>
          <div className={`${styles.Card}`}>
            <ul className={`${styles.CaseSummSideUL}`}>
              <li>
                <p className={`${styles.ULtitle}`}>Apartment Number</p>
                <p className={`${styles.ULData}`}>{this.state.data.apt_name}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Submitted By</p>
                <p className={`${styles.ULData}`}>{this.state.data.submitted_by_name}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Tenant</p>
                <p className={`${styles.ULData}`}><Link to={`/people/tenants/details/${this.state.data.tenant_id}`}>{this.state.data.tenant_name}</Link></p>
              </li>
              <li className={`${styles.Dropdown}`}>
                <p className={`${styles.ULtitle}`}>Priority</p>
                {this.state.prioritySelectHtml}

              </li>
              <li className={`${styles.Dropdown}`}>
                <p className={`${styles.ULtitle}`}>Status</p>
                {this.state.statSelectHtml}

              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Submitted Date</p>
                <p className={`${styles.ULData}`}>{moment(this.state.data.created_at).format("L")}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>best way to contact tenant</p>
                <p className={`${styles.ULData}`}>{this.state.data.contact_way}: <a href={(this.state.data.contact_way === 'Email' ? `mailto:${this.state.data.contact_val}` : `tel:${this.state.data.contact_val}`)}>{this.state.data.contact_val}</a></p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>best time to contact tenant</p>
                <p className={`${styles.ULData}`}>{this.state.data.contact_time_val} | {this.state.data.contact_time_start} - {this.state.data.contact_time_end}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Complaint Number</p>
                <p className={`${styles.ULData}`}>{this.state.data.complaint_no}</p>
              </li>
              <li>
                <p className={`${styles.ULtitle}`}>Property</p>
                <p className={`${styles.ULData}`}>{this.state.data.property_name}</p>
              </li>
            </ul>
          </div>
        </div>

      </div>}
      <Dialog
        open={this.state.rowArchiveModal}
        fullWidth
        maxWidth="sm"
        onClose={this.archiveModalClose.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to {this.state.data.is_archived ? 'unarchive' : 'archive'} the Complaint Record?<button onClick={this.archiveModalClose.bind(this)}><X /></button></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="ModalFormGroup">
              <label className="PopupBodyText">{this.state.data.is_archived ? 'The Complaint Record will be unarchived immediately.You can archive it at any time.' : 'The Complaint Record will be archived immediately.You can unarchive it at any time.'}</label>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.rowAchive.bind(this)} className="SubmitPopupBU">{this.state.data.is_archived ? 'Unarchive' : 'Archive'}</Button>
          <Button onClick={this.archiveModalClose.bind(this)} className="CancelPopupBU">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.rowDeleteModal}
        fullWidth
        maxWidth="sm"
        onClose={this.delModalClose.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="NewPopUp"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete the Complaint Record?<button onClick={this.delModalClose.bind(this)}><X /></button></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="ModalFormGroup">
              <label className="PopupBodyText">The Complaint Record will be removed immediately.You can't undo this action.</label>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.rowDelete.bind(this)} className="DeletePopupBU">Delete</Button>
          <Button onClick={this.delModalClose.bind(this)} className="CancelPopupBU">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </React.Fragment>);
  }

}

export default withAuthenticationRequired(withAuth0(ComplaintsDetails), {
  onRedirecting: () => <Loader />,
});