import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import styles from "../userlist.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/system";
import { AddLogCallAPI } from "../../../components/AddLogs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema = yup.object().shape({
    first_name: yup.string().required('This field is Required').max(50, 'First name cannot be more than 50 characters'),
    last_name: yup.string().required('This field is Required').max(50, 'Last name cannot be more than 50 characters'),
    email: yup.string().required('This field is Required').email('Invalid E-mail').max(50, 'Email cannot be more than 50 characters'),
    phone: yup.string().required('This field is Required').matches(phoneRegExp, 'Invalid phone number'),
    property: yup.array().required('This field is Required'),
    role: yup.string().required('This field is Required'),
});

function PhoneField(props) {
    return (
        <NumberFormat
            prefix=""
            displayType="input"
            type="tel"
            format={"(###) ###-####"}
            {...props}
        />
    );
}

const UserAdd = ({ setAddModalOpen, setLoading, setSnackbarMsg, setSnackbarOpen, userAddLocal, editData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [roleList, setRoleList] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [prevRole, setPrevRole] = useState('');
    const [selectPropertyIds, setSelectPropertyIds] = useState([]);

    const { handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema), mode: 'onChange',
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            role: '',
            property: []
        }
    });

    useEffect(() => {
        if (editData) {
            setValue('first_name', editData?.first_name);
            setValue('last_name', editData?.last_name);
            setValue('email', editData?.email);
            setValue('phone', editData?.phone);
            setValue('property', editData?.property_ids);
            setSelectPropertyIds(editData?.property_ids);

            let sRole = roleList?.filter(i => i?.name === editData?.role);
            if (sRole.length > 0) {
                setValue('role', sRole[0]?.id);
                setPrevRole(sRole[0]?.id);
            }

            if (editData?.role === 'Admin') {
                setIsAdmin(true);
                //setValue('property', ['All']);
            }
        }
    }, [editData, roleList, setValue]);

    useEffect(() => {
        const fetchRoles = () => {
            return axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/oauth/token', { "client_id": process.env.REACT_APP_AUTH0_AUDIENCE_CLIENT_ID, "client_secret": process.env.REACT_APP_AUTH0_AUDIENCE_CLIENT_SECRET, "audience": 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/", "grant_type": "client_credentials" }, {
                'access-control-allow-origin': '*',
            }).then(response => {
                let managementToken = response?.data?.access_token;

                axios.get('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/roles', {
                    headers: {
                        Authorization: `Bearer ${managementToken}`,
                    }
                }).then(response2 => {
                    let roleListTemp = response2?.data?.filter(i => i?.name !== "Super Admin");
                    setRoleList(roleListTemp);
                }).catch(error => {
                    console.log(error);
                });

            }).catch(error => {
                console.log(error);
            });
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        const fetchProperties = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_USER_API_URL + 'v1/get-all-properties', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                setPropertyList(response?.data?.data);
            }).catch(error => {
                setPropertyList([]);
            });
        }

        fetchProperties();
    }, [getAccessTokenSilently]);

    const onSubmit = async (data) => {
        setLoading(true);
        let roleLabel = '';
        if (data?.role) {
            let sRole = roleList?.filter(i => i?.id === data?.role);
            if (sRole.length > 0) {
                roleLabel = sRole[0].name;
            }
        }
        data = { ...data, role_label: roleLabel };
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        return axios.post(process.env.REACT_APP_USER_API_URL + 'v1/users-new', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setAddModalOpen(false);
            setSnackbarOpen(true);
            setSnackbarMsg('User added successfully.');

            setLoading(false);

            userAddLocal(response.data.data);

            let respData = response.data.data;
            let logData = {
                'title': 'New user is added',
                'description': [
                    'Name: ' + respData.name,
                    'Email: ' + respData.email,
                    'Role: ' + respData.role
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUpdate = async (data) => {
        setLoading(true);
        let roleLabel = '';
        if (data?.role) {
            let sRole = roleList?.filter(i => i?.id === data?.role);
            if (sRole.length > 0) {
                roleLabel = sRole[0].name;
            }
        }
        data = { ...data, role_label: roleLabel, user_id: editData?.user_id, app_user_id: editData?.app_user_id, prev_role: prevRole };

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        return axios.post(process.env.REACT_APP_USER_API_URL + 'v1/users-new/' + editData?.id, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setAddModalOpen(false);
            setSnackbarOpen(true);
            setSnackbarMsg('User updated successfully.');

            setLoading(false);

            userAddLocal(response.data.data);

            let respData = response.data.data;
            let logData = {
                'title': 'User is updated',
                'description': [
                    'Name: ' + respData.name,
                    'Email: ' + respData.email,
                    'Role: ' + respData.role
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.AddPropertyRow}`}>
        <form onSubmit={handleSubmit(editData ? onUpdate : onSubmit)}>
            <div className={`${styles.AddPropertyHalfSec}`}>
                <div className={`${styles.AddPropertyHalfSecRow}`}>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                        <Controller
                            name={`first_name`}
                            control={control}
                            render={(field) => (
                                <TextField
                                    onChange={(e) => field.onChange(e)}
                                    id="outlined-basic"
                                    label="First Name*"
                                    variant="outlined"
                                    value={field.value}
                                />
                            )}
                        />
                        {errors.first_name && (<p className={`${styles.ErrorM}`}>{errors?.first_name?.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                        <Controller
                            name={`last_name`}
                            control={control}
                            render={(field) => (
                                <TextField
                                    onChange={(e) => field.onChange(e)}
                                    id="outlined-basic"
                                    label="Last Name*"
                                    variant="outlined"
                                    value={field.value}
                                />
                            )}
                        />
                        {errors.last_name && (<p className={`${styles.ErrorM}`}>{errors?.last_name?.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                        <Controller
                            name={`email`}
                            control={control}
                            render={(field) => (
                                <TextField
                                    onChange={(e) => field.onChange(e)}
                                    id="outlined-basic"
                                    label="Email*"
                                    variant="outlined"
                                    value={field.value}
                                    disabled={editData}
                                />
                            )}
                        />
                        {errors.email && (<p className={`${styles.ErrorM}`}>{errors?.email?.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                        <Controller
                            name={`phone`}
                            control={control}
                            render={(field) => (
                                <TextField
                                    onChange={(e) => field.onChange(e)}
                                    id="outlined-basic"
                                    label="Phone*"
                                    variant="outlined"
                                    value={field.value}
                                    InputProps={{
                                        inputComponent: PhoneField,
                                    }}
                                />
                            )}
                        />
                        {errors.phone && (<p className={`${styles.ErrorM}`}>{errors?.phone?.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecHalf} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role*</InputLabel>
                            <Controller
                                name="role"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Role*"
                                        onChange={(e) => {
                                            field.onChange(e);

                                            let sRole = roleList?.filter(i => i?.id === e.target.value);
                                            if (sRole.length > 0) {
                                                if (sRole[0].name === 'Admin') {
                                                    setIsAdmin(true);
                                                    setValue('property', ['All']);
                                                } else {
                                                    setIsAdmin(false);
                                                    //setValue('property', []);
                                                }
                                            } else {
                                                setIsAdmin(false);
                                                //setValue('property', []);
                                            }
                                        }}
                                    >
                                        {roleList?.map(item => {
                                            return (<MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>)
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.role && (<p className={`${styles.ErrorM}`}>{errors.role.message}</p>)}
                    </div>
                    <div className={`${styles.HalfSecFull} FormGroup`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Property*</InputLabel>
                            <Controller
                                name="property"
                                control={control}
                                render={(field) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Property*"
                                        multiple
                                        onChange={(e) => {
                                            field.onChange(e);

                                            console.log(e.target.value);

                                            if (isAdmin) {
                                                setSelectPropertyIds(e.target.value);
                                            } else {
                                                if (e.target.value?.indexOf('All') > -1) {
                                                    if (selectPropertyIds.length === propertyList.length) {
                                                        setValue('property', []);
                                                        setSelectPropertyIds([]);
                                                    } else {
                                                        let allP = propertyList?.map(i => i?.value);
                                                        setValue('property', allP);
                                                        setSelectPropertyIds(allP);
                                                    }
                                                } else {
                                                    setSelectPropertyIds(e.target.value);
                                                }
                                            }
                                        }}
                                        input={<OutlinedInput label="Property*" />}
                                        renderValue={(selected) => {
                                            return (isAdmin ? <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                <Chip label={"All Properties"} />
                                            </Box> : <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {propertyList?.map(item => {
                                                    return (selected?.indexOf(item?.value) > -1) ? (<Chip key={item?.value} label={item?.label} />) : null;
                                                })}
                                            </Box>)
                                        }}
                                        MenuProps={MenuProps}
                                        disabled={isAdmin}
                                    >
                                        <MenuItem value="All">
                                            <Checkbox
                                                checked={propertyList.length > 0 && selectPropertyIds.length === propertyList.length}
                                                indeterminate={selectPropertyIds.length > 0 && selectPropertyIds.length < propertyList.length}
                                            />
                                            <ListItemText primary="All Properties" />
                                        </MenuItem>
                                        {propertyList?.map(item => {
                                            return (<MenuItem key={item?.value} value={item?.value}>
                                                <Checkbox checked={selectPropertyIds.indexOf(item?.value) > -1} />
                                                <ListItemText primary={item?.label} />
                                            </MenuItem>)
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.property && (<p className={`${styles.ErrorM}`}>{errors.property.message}</p>)}
                    </div>
                </div>
            </div>
            <div className={`${styles.ButtonSec}`}>
                <button type="button" onClick={(e) => setAddModalOpen(false)} className="CancelPopupBU">Cancel</button>
                <button type="submit" className="SubmitPopupBU">{editData ? 'Update' : 'Add'}</button>
            </div>
        </form>
    </div>);
}

export default UserAdd;