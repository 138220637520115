import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import userInfoReducer from './userInfoReducer'
import prospectReducer from "./prospectCounterReducer";
import communicationChatBoxReducer from "./communicationChatBoxReducer";
import communicationChatReloadReducer from "./communicationChatReloadReducer";
import conversationIdReducer from "./communicationConversationIdReducer";
import communicationVoiceCallInfoReducer from "./communicationVoiceCallInfoReducer";
import communicationVoiceCallScreenReducer from "./communicationVoiceCallScreenReducer";
export const rootReducer = combineReducers({
  themeReducer,
  userInfoReducer,
  prospectReducer,
  communicationChatBoxReducer,
  communicationChatReloadReducer,
  conversationIdReducer,
  communicationVoiceCallInfoReducer,
  communicationVoiceCallScreenReducer
});
