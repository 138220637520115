import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import styles from "./articles.module.css";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, TablePagination, Typography } from "@mui/material";
import { ArrowLeft, Home, X } from "react-feather";
import Footer from "../../../components/Footer";
import ArticleFilter from "./components/ArticleFilter";
import ArticleCard from "./components/ArticleCard";
import ArticleAdd from "./components/ArticleAdd";
import axios from "axios";
import { TableLoader } from "../../../components/LoaderC";
import { toast } from 'react-smart-toaster';
import { AddLogCallAPI } from "../../../components/AddLogs";

const ArticleList = ({ history, match }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(true);
    const [allArticleList, setAllArticleList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(8);
    const [filterData, setFilterData] = useState({ searchKey: "", property: "", category: "" });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [selectecId, setSelectecId] = useState(0);
    const [modalDisplay, setModalDisplay] = useState(true);
    const [isPublished, setIsPublished] = useState(true);

    useEffect(() => {
        async function getAllArticles() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/articles", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setContentLoading(false);
                setAllArticleList(response.data.data);
            }).catch((error) => {
                setContentLoading(false);
            });
        }
        getAllArticles();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (allArticleList.length > 0) {
            let filteredData = allArticleList;

            if (filterData.searchKey !== "") {
                let inputVal = filterData.searchKey;
                inputVal = inputVal.toLowerCase();
                filteredData = filteredData.filter((item) => {
                    return (item.title.toLowerCase().includes(inputVal) || item.author_name.toLowerCase().includes(inputVal) || item.property_names?.join(', ').toLowerCase().includes(inputVal) || item.category_names?.join(', ').toLowerCase().includes(inputVal));
                });
            }

            if (filterData.property !== "" && filterData.property !== "all") {
                filteredData = filteredData.filter((i) => i.property_id_arr.indexOf(filterData.property) > -1);
            }

            if (filterData.category !== "" && filterData.category !== "all") {
                filteredData = filteredData.filter((i) => i.category_id_arr.indexOf(filterData.category) > -1);
            }

            setArticleList(filteredData);
        } else {
            setArticleList([]);
        }
    }, [filterData, allArticleList]);

    const onAdd = (data) => {
        setAllArticleList((prev) => {
            return [data].concat(prev);
        });
    }

    const onUpdate = (data) => {
        setAllArticleList(prev => {
            let updateList = prev?.map(item => {
                if (item?.id === data?.id)
                    return data;
                else
                    return item;
            })
            return updateList;
        });
    }

    const confirmStChange = async () => {
        let data = {};
        if (isPublished) {
            data = { is_published: 1 };
        } else {
            data = { is_published: 0 };
        }
        setShowEditPopup(false);
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.put(process.env.REACT_APP_RESOURCE_API_URL + 'v1/articles/' + selectecId, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
            setLoading(false);
            setSelectecId(0);

            onUpdate(response.data.data);

            let logData = {
                'title': isPublished ? 'Article is published' : 'Article is draft',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Categories: ' + response.data.data?.category_names.join(', '),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }

            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const confirmDelete = async () => {
        setShowDeletePopup(false);
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_RESOURCE_API_URL + 'v1/articles/' + selectecId, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
            setLoading(false);

            setAllArticleList(prev => {
                let updateList = prev?.filter(i => i?.id !== selectecId);
                return updateList;
            })
            setSelectecId(0);

            let logData = {
                'title': 'An article is deleted',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Categories: ' + response.data.data?.category_names.join(', '),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }

            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<React.Fragment>
        {loading && <Loader />}

        <Helmet title="Knowledge Base" />
        <div className={`${styles.pageTitleWrap}`}>
            <Typography display="inline" className={`${styles.pageTitle}`}>Knowledge Base</Typography>
            <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Resources</li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>Knowledge Base</li>
                </ol>
            </nav>
        </div>
        <div className={`${styles.BodyCon}`}>
            <div className={`${styles.mainCardDiv}`}>
                <div className={`${styles.mainCard}`}>
                    <div className="control-pane">
                        {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

                        {!contentLoading && (<div className={`${styles.Row}`}>
                            <ArticleFilter setFilterData={setFilterData.bind(this)} setShowAddForm={setShowAddForm.bind(this)} />
                            <div className={`${styles.NotesRow}`}>

                                {articleList.map((item, index) => {
                                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<ArticleCard item={item} setShowDeletePopup={setShowDeletePopup.bind(this)} setShowEditForm={setShowEditForm.bind(this)} setShowEditPopup={setShowEditPopup.bind(this)} setIsPublished={setIsPublished.bind(this)} setSelectecId={setSelectecId.bind(this)} />) : null;
                                })}

                                {articleList.length > 0 && (<div className="Pagination TablePaginationNew List">
                                    <TablePagination
                                        count={articleList.length}
                                        color="primary"
                                        page={page}
                                        rowsPerPage={perPage}
                                        onPageChange={(e, v) => setPage(v)}
                                        rowsPerPageOptions={[
                                            { label: '4', value: 4 },
                                            { label: '8', value: 8 },
                                            { label: '16', value: 16 },
                                            { label: 'All', value: -1 },
                                        ]}
                                        onRowsPerPageChange={(e) => {
                                            setPage(0);
                                            setPerPage(e.target.value);
                                        }}
                                        labelRowsPerPage={'Alert per Page:'}
                                    />
                                </div>)}

                                {!contentLoading && articleList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                                    <div className={`${styles.NoDataIMG}`}>
                                        <img src="/static/img/article-kb.png" alt="No Data" />
                                    </div>
                                    <p className={`${styles.NoDataText}`}>Oops!</p>
                                    <p className={`${styles.NoDataTextSub}`}>The articles don't seem to exist.</p>
                                </div>)}

                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>

        <Dialog
            open={showAddForm}
            onClose={(e) => setShowAddForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm CustomWidthLeasePops"
            fullWidth
            maxWidth="md"
            style={{ display: modalDisplay ? `block` : `none` }}
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
                New Article
                <button onClick={(e) => setShowAddForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <ArticleAdd setShowAddForm={setShowAddForm.bind(this)} setLoading={setLoading.bind(this)} onAdd={onAdd.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} isEdit={false} selectecId={0} setModalDisplay={setModalDisplay.bind(this)} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showEditForm}
            onClose={(e) => setShowEditForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm CustomWidthLeasePops"
            fullWidth
            maxWidth="md"
            style={{ display: modalDisplay ? `block` : `none` }}
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowEditForm(false)}><ArrowLeft /></button>
                Update Article
                <button onClick={(e) => setShowEditForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <ArticleAdd setShowAddForm={setShowEditForm.bind(this)} setLoading={setLoading.bind(this)} onAdd={onUpdate.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} isEdit={true} selectecId={selectecId} setModalDisplay={setModalDisplay.bind(this)} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showEditPopup}
            onClose={(e) => setShowEditPopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {isPublished ? 'Are you sure you want to publish the article?' : 'Are you sure you want to draft the article?'}
                <button onClick={(e) => setShowEditPopup(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">{isPublished ? 'This article will be published immediately. You can draft it at any time.' : 'This article will be draft immediately. You can publish it at any time.'}</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={(e) => setShowEditPopup(false)} className="CancelPopupBU">Cancel</button>
                <button onClick={(e) => confirmStChange()} className="SubmitPopupBU">{isPublished ? 'Publish' : 'Draft'}</button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={showDeletePopup}
            onClose={(e) => setShowDeletePopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete the article?
                <button onClick={(e) => setShowDeletePopup(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">This article will be removed immediately. You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="CancelPopupBU" onClick={(e) => confirmDelete()}>Delete</button>
                <button onClick={(e) => setShowDeletePopup(false)} className="SubmitPopupBU">Cancel</button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={snackbarOpen}
            onClose={(e) => setSnackbarOpen(false)}
            TransitionComponent={Slide}
            message={snackbarMsg}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setSnackbarOpen(false)}
                >
                    <X />
                </IconButton>
            }
        />

        <Footer />
    </React.Fragment>);
};

export default withAuthenticationRequired(ArticleList, {
    onRedirecting: () => <Loader />,
});
