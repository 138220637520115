import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useSocket from "../../services/socketService";
import ProspectTenantSearch from "./components/tenant-propect-search";
import ProspectList from "./components/prospectList";
import TenantList from "./components/tenantList";
import ProspectListunread from "./components/prospectListunread";
import TenantListunread from "./components/tenantListunread";
import ChatMessages from "./components/chat-messages";
import styles from "./communication-chat.module.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ChevronLeft, PhoneCall, X } from "react-feather";
import ChatInputBox from "./components/chatInputBox";
import { toast } from "react-smart-toaster";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { communicationChatBoxOpen } from "../../redux/actions/communicationChatBoxAction";
import { communicationChatReload } from "../../redux/actions/communicationChatRloadAction";
import { resetConversationId } from "../../redux/actions/communicationConversationId";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setVoiceCallInfo } from "../../redux/actions/communicationVoiceCallInfo";
import { communicationVoiceCallScreenOpen } from "../../redux/actions/communicationVoiceCallScreenAction";
import Loader from "../Loader";

const BootstrapTooltip = styled(({ className, ...props }) => (<Tooltip {...props} arrow classes={{ popper: className }} />))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
}));

const CommunicationChat = () => {
  const socket = useSocket();
  const { isAuthenticated } = useAuth0();
  const isCommunicationChatBoxOpen = useSelector((store) => store.communicationChatBoxReducer);
  const isCommunicationChatReload = useSelector((store) => store.communicationChatReloadReducer);
  const isConversationIdExist = useSelector((store) => store.conversationIdReducer);
  const voiceCallInfo = useSelector((store) => store.communicationVoiceCallInfoReducer);
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const [chatMessagesList, setChatMessagesList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [prospectList, setProspectList] = useState([]);
  const [prospectTenantList, setProspectTenantList] = useState([]);
  const [isShowGlobalList, setIsShowGlobalList] = useState(false);
  const [isShowUnreadMessageList, setIsShowUnreadMessageList] = useState(true);
  const [isShowChatMessages, setIsShowChatMessages] = useState(false);
  const [prospectTenantDetails, setProspectTenantDetails] = useState(null);
  const [unreadMessageList, setUnreadMessageList] = useState([]);
  const [accordionOnForProspect, setaccordionOnForProspect] = useState(false);
  const [accordionOnForProspectUnRead, setaccordionOnForProspectUnRead,] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [accordionOnForTenant, setaccordionOnForTenant] = useState(false);
  const [accordionOnForTenantUnRead, setaccordionOnForTenantUnRead] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [unreadMessagesCounter, setUnreadMessagesCounter] = useState(0);
  const [messageTextVal, setMessageTextVal] = useState("");
  const [typeaheadProspectTenantList, setTypeaheadProspectTenantList] = useState([]);
  const [messageLoader, setMessageLoader] = useState(false);
  const [prospectTenantNames, setProspectTenantNames] = useState([]);
  const [isShowCommunicationChat, setIsShowCommunicationChat] = useState(true);
  const [prospectTenantNamesForToolTip, setProspectTenantNamesForToolTip] = useState("");
  const messageLoaderRef = useRef(messageLoader);
  const chatMessagesListRef = useRef(chatMessagesList);
  const prospectTenantDetailsRef = useRef(prospectTenantDetails);
  const unreadMessagesCounterRef = useRef(unreadMessagesCounter);
  const prospectTenantListRef = useRef(prospectTenantList);
  const conversationListRef = useRef(conversationList);
  const unreadMessageListRef = useRef(unreadMessageList);
  const typeaheadProspectTenantListRef = useRef(typeaheadProspectTenantList);
  const location = useLocation();
  const firstPath = "/" + location.pathname.split("/")[1];
  const [openCallModal, setOpenCallModal] = useState(false);
  const [prospectInfoObj, setProspectInfoObj] = useState(null);
  const [loading, setLoading] = useState(false);

  const allowedRoutes = ["", "/people", "/dashboard", "/properties", "/cases", "/leases", "/schedule", "/payments", "/smart-devices", "/resources", "/logs", "/notifications", "/more"];
  const isChatVisible = allowedRoutes.includes(firstPath);

  useEffect(() => {
    if (isAuthenticated) {
      getProspectTenantList();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setIsShowCommunicationChat(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Connected ");
        dispatch(communicationChatReload(true));
      });
    }
  }, [socket]);

  useEffect(() => {
    async function reloadChat() {
      try {
        if (isCommunicationChatReload) {
          const r = await getProspectTenantList();

          if (prospectTenantDetailsRef.current && prospectTenantList.length) {
            const value = prospectTenantDetailsRef.current;

            // const findTenantProspect = prospectTenantList.find(
            //   (pt) => pt.conversation_id === value.conversation_id
            // );
            const findTenantProspect = prospectTenantListRef.current.find((pt) => pt.conversation_id === value.conversation_id);

            if (findTenantProspect) {
              setDetails(findTenantProspect);
            } else {
              backToList();
            }
            const findPropectTenantName = typeaheadProspectTenantListRef.current.filter((pt) => value.conversation_id === pt.conversation_id);

            setProspectTenantNames(findPropectTenantName);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    reloadChat();
  }, [isCommunicationChatReload]);

  useEffect(() => {
    if (isConversationIdExist) {
      const findTenantProspect = prospectTenantList.find((pt) => pt.conversation_id === isConversationIdExist);

      if (findTenantProspect) {
        setDetails(findTenantProspect);
        dispatch(communicationChatBoxOpen(true));
      }
    }
  }, [isConversationIdExist]);

  const communicationChatBoxHandler = () => {
    dispatch(communicationChatBoxOpen(!isCommunicationChatBoxOpen));
  };

  async function getProspectTenantList() {
    let pList = [];
    let tList = [];
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    await axios.get(process.env.REACT_APP_COMMUNICATION_NODE_API_URL + "prospect/get-prospect-tenant-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      if (response.data.data) {
        setTypeaheadProspectTenantList(response.data.data);
        typeaheadProspectTenantListRef.current = response.data.data;
        //Filter prospect and tenants
        const conversationMap = new Map(); // To remove duplicates

        response.data.data.forEach((item) => {
          // Remove duplicates based on conversation ID
          if (!conversationMap.has(item.conversation_id)) {
            conversationMap.set(item.conversation_id, true);

            // Calculate total unread message count
            if (item.type === "Prospect") {
              pList.push(item);
            } else if (item.type === "Tenant") {
              tList.push(item);
            }
          }
        });

        // Group tList by property_id
        const groupedTList = tList.reduce((acc, curr) => {
          if (!acc[curr.property_id]) {
            acc[curr.property_id] = [];
          }
          acc[curr.property_id].push(curr);
          return acc;
        }, {});

        // Fetch property names
        const propertyNamesMap = new Map();
        response.data.data.forEach((item) => {
          if (!propertyNamesMap.has(item.property_id)) {
            propertyNamesMap.set(item.property_id, item.property_name);
          }
        });

        // Construct the final array with property name and tenant list
        const finalList = [];
        propertyNamesMap.forEach((propertyName, propertyId) => {
          finalList.push({ propertyName: propertyName, property_id: propertyId, tenants: groupedTList[propertyId] || [] });
        });

        setProspectList(pList);
        setTenantList(finalList);

        // Calculate total unread message count for prospects and tenants
        const prospectUnreadCount = pList.reduce((acc, curr) => acc + curr.unread_no, 0);
        const tenantUnreadCount = finalList.reduce((acc, curr) => acc + curr.tenants.reduce((a, c) => a + c.unread_no, 0), 0);

        setUnreadMessagesCounter(prospectUnreadCount + tenantUnreadCount);
        unreadMessagesCounterRef.current = prospectUnreadCount + tenantUnreadCount;

        //Create unique prospectTenantList, all unique prospect and tenant will be merged together
        const mergeProspectTenantList = [...pList, ...tList];
        setProspectTenantList(mergeProspectTenantList);
        prospectTenantListRef.current = mergeProspectTenantList;

        //Filter unread Messages list , will show in unread_message tab. showing all the conversations
        // const filterUnreadMessages = mergeProspectTenantList.filter(
        //   (v) => v.unread_no
        // );
        const filterUnreadMessages = mergeProspectTenantList;

        setUnreadMessageList(filterUnreadMessages);
        unreadMessageListRef.current = filterUnreadMessages;
      }

      //set reload state false. this used to reload communication chat when new prospect is added or edited
      dispatch(communicationChatReload(false));
    }).catch((error) => {
      console.log("error", error);

      return false;
    });

    return true;
  }

  useEffect(() => {
    const broadcastMessageHandlerForProspect = async (msg) => {
      try {
        let message = msg.data;

        // const userProperties = userInformation.current.properties;
        // console.log(userProperties);
        // if (!userInformation.current || !userInformation.current?.properties) {
        //     return;
        // }
        // const checkUserPermission = await userInformation.current.properties.find(
        //     (p) => p.id === message.propertyId
        // );
        // console.log(checkUserPermission);

        //When we get the new message update chatMessages if chat message is open otherwise update conversation-list, counter and unread message list

        if (prospectTenantDetailsRef.current && prospectTenantDetailsRef.current.conversation_id === message.cid) {
          chatMessagesListRef.current = [...chatMessagesListRef.current, message];
          setChatMessagesList([...chatMessagesListRef.current]);

          //Increment unread_no for prospectTenantNames, that is used in message details
          // prospectTenantNames = prospectTenantNames.map((ptn) =>{
          //   ptn.unread_no = ptn.unread_no + 1
          //   return ptn;
          // })
          // setProspectTenantNames([...prospectTenantNames])

          //When get new message, need to increment unread_no for messageDetails object
          //     const incrementunReadNo = prospectTenantDetailsRef.current
          //     incrementunReadNo.unread_no =  incrementunReadNo.unread_no + 1;

          // prospectTenantDetailsRef.current = {...incrementunReadNo};
          // setProspectTenantDetails({...incrementunReadNo});
        }

        //Update message counter
        const updateMessageCounter = unreadMessagesCounterRef.current + 1;
        unreadMessagesCounterRef.current = updateMessageCounter;
        setUnreadMessagesCounter(updateMessageCounter);

        //Update conversation list
        const updateConversationList = conversationListRef.current.map((c) => {
          if (message.cid === c.conversationId) {
            c.messages = [...c.messages, message];
          }
          return c;
        });
        setConversationList(updateConversationList);
        conversationListRef.current = updateConversationList;

        //Update unread message list
        const isExist = unreadMessageListRef.current.find((um) => um.conversation_id === message.cid);

        if (isExist) {
          const updateList = unreadMessageListRef.current.map((um) => {
            if (message.cid === um.conversation_id) {
              um.unread_no = um.unread_no + 1;
              um.message = message.message;
              um.message_time = new Date().toISOString();
            }
            return um;
          });

          unreadMessageListRef.current = updateList;
          setUnreadMessageList(updateList);
        } else {
          const findPropectTenant = prospectTenantListRef.current.find((pt) => pt.conversation_id === message.cid);

          if (findPropectTenant) {
            findPropectTenant.unread_no = 1;
            findPropectTenant.message = message.message;
            findPropectTenant.message_time = new Date().toISOString();
            unreadMessageListRef.current = [findPropectTenant, ...unreadMessageListRef.current];

            setUnreadMessageList([...unreadMessageListRef.current]);
          } else {
            getProspectTenantList();
            //create prospect strcture and add it
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const broadcastMessageHandlerForTenant = async (msg) => {
      try {
        let message = msg.data;
        // const userProperties = userInformation.current.properties;
        // console.log(userProperties);
        // if (!userInformation.current || !userInformation.current?.properties) {
        //     return;
        // }
        // const checkUserPermission = await userInformation.current.properties.find(
        //     (p) => p.id === message.propertyId
        // );
        // console.log(checkUserPermission);

        //When we get the new message update chatMessages if chat message is open otherwise update conversation-list, counter and unread message list

        if (prospectTenantDetailsRef.current && prospectTenantDetailsRef.current.conversation_id === message.cid) {
          chatMessagesListRef.current = [...chatMessagesListRef.current, message];
          setChatMessagesList([...chatMessagesListRef.current]);

          //When get new message, need to increment unread_no for messageDetails object
          //const incrementunReadNo = prospectTenantDetailsRef.current
          //     incrementunReadNo.unread_no =  incrementunReadNo.unread_no + 1;

          // prospectTenantDetailsRef.current = {...incrementunReadNo};
          // setProspectTenantDetails({...incrementunReadNo});
        }
        //Update message counter
        const updateMessageCounter = unreadMessagesCounterRef.current + 1;
        unreadMessagesCounterRef.current = updateMessageCounter;
        setUnreadMessagesCounter(updateMessageCounter);

        //Update conversation list
        const updateConversationList = conversationListRef.current.map((c) => {
          if (message.cid === c.conversationId) {
            c.messages = [...c.messages, message];
          }
          return c;
        });
        setConversationList(updateConversationList);
        conversationListRef.current = updateConversationList;

        //Update unread message list
        const isExist = unreadMessageListRef.current.find((um) => um.conversation_id === message.cid);

        if (isExist) {
          const updateList = unreadMessageListRef.current.map((um) => {
            if (message.cid === um.conversation_id) {
              um.unread_no = um.unread_no + 1;
              um.message = message.message;
              um.message_time = new Date().toISOString();
            }
            return um;
          });

          unreadMessageListRef.current = updateList;
          setUnreadMessageList(updateList);
        } else {
          const findPropectTenant = prospectTenantListRef.current.find((pt) => pt.conversation_id === message.cid);

          if (findPropectTenant) {
            findPropectTenant.unread_no = 1;
            findPropectTenant.message = message.message;
            findPropectTenant.message_time = new Date().toISOString();
            unreadMessageListRef.current = [findPropectTenant, ...unreadMessageListRef.current];
            setUnreadMessageList([...unreadMessageListRef.current]);
          } else {
            getProspectTenantList();
            //create prospect strcture and add it
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getConversationHandlerForProspect = (messages) => {
      messageLoaderRef.current = false;
      setMessageLoader(false);
      setChatMessagesList(messages.data);
      chatMessagesListRef.current = messages.data;
      if (messages.data.length) {
        const conversation = { conversationId: messages.data[0].cid, messages: messages.data };
        conversationList.push(conversation);
        setConversationList(conversationList);
        conversationListRef.current = conversationList;
      }

      // chatMessagesRef.current = messages.data;
    };
    const getConversationHandlerForTenant = (messages) => {
      messageLoaderRef.current = false;
      setMessageLoader(false);
      setChatMessagesList(messages.data);
      chatMessagesListRef.current = messages.data;
      if (messages.data.length) {
        const conversation = { conversationId: messages.data[0].cid, messages: messages.data };
        conversationList.push(conversation);
        setConversationList(conversationList);
        conversationListRef.current = conversationList;
      }
    };

    if (socket) {
      socket.on("broadcast-message-for-prospect", broadcastMessageHandlerForProspect);
      socket.on("get-conversation-for-prospect", getConversationHandlerForProspect);
      socket.on("broadcast-message-for-tenant", broadcastMessageHandlerForTenant);

      socket.on("get-conversation-for-tenant", getConversationHandlerForTenant);
    }
  }, [socket]);

  useEffect(() => {
    if (prospectTenantNames.length > 1) {
      const names = prospectTenantNames.splice(1).map((tenant) => `${tenant.first_name} ${tenant.last_name}`).join(", ");
      setProspectTenantNamesForToolTip(names);
    }
  }, prospectTenantNames);

  const tabHandler = (value) => {
    if (value === "globalListTab") {
      setIsShowGlobalList(true);
      setIsShowUnreadMessageList(false);
    } else if (value === "unreadMessageTab") {
      setIsShowGlobalList(false);
      setIsShowUnreadMessageList(true);
    }
  };
  const setDetails = (value) => {
    try {
      setMessageTextVal("");
      setChatMessagesList([]);
      chatMessagesListRef.current = [];
      setProspectTenantDetails(value);
      prospectTenantDetailsRef.current = value;
      setMessageLoader(true);
      messageLoaderRef.current = true;
      setIsShowChatMessages(true);
      const payload = { conversationId: value.conversation_id };
      const isconversationExist = conversationList.find((c) => c.conversationId === value.conversation_id);
      const findPropectTenantName = typeaheadProspectTenantList.filter((pt) => value.conversation_id === pt.conversation_id);
      setProspectTenantNames(findPropectTenantName);
      // if (!isconversationExist) {
      if (true) {
        if (value.type === "Prospect") {
          socket.emit("/request-conversation-for-prospect", payload);
        } else {
          socket.emit("/request-conversation-for-tenant", payload);
        }
      } else {
        setMessageLoader(false);

        setChatMessagesList(isconversationExist.messages);
        chatMessagesListRef.current = isconversationExist.messages;
      }

      dispatch(resetConversationId());

      //Find unread message from the selected conversation and reset to 0
      //we will subtract unread counter of particular conversation from unreadMessageList
      // const findUnreadCounter = unreadMessageList.find(
      //   (um) => um.conversation_id === value.conversation_id
      // );
      // console.log("findUnreadCounter", findUnreadCounter);

      // if (findUnreadCounter) {
      // const updateCounter =
      //   unreadMessagesCounter - findUnreadCounter.unread_no;
      // setUnreadMessagesCounter(updateCounter);
      // unreadMessagesCounterRef.current = updateCounter;

      //update unread_message_list, conversation removed from message list
      // const filterList = unreadMessageList.filter(
      //   (um) => um.conversation_id !== value.conversation_id
      // );
      // setUnreadMessageList(filterList);
      // unreadMessageListRef.current = filterList;

      //update unread_no on server set to zero
      // socket.emit("read-message-for-prospect", {
      //   conversationId: value.conversation_id,
      // });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const markAsReadConversationMessages = (conversationId) => {
    //Increment unread_no for prospectTenantNames, that is used in message details
    // let updateProspectTenantNames = prospectTenantNames.map((ptn) =>{
    //   ptn.unread_no = 0
    //   return ptn;
    // })
    // setProspectTenantNames([...updateProspectTenantNames])
    //Find unread message from the selected conversation and reset to 0
    //we will subtract unread counter of particular conversation from unreadMessageList
    const findUnreadCounter = unreadMessageList.find((um) => um.conversation_id === conversationId);

    if (findUnreadCounter) {
      const updateCounter = unreadMessagesCounter - findUnreadCounter.unread_no;
      setUnreadMessagesCounter(updateCounter);
      unreadMessagesCounterRef.current = updateCounter;

      //Reset message counter to zero for selected conversation
      const filterList = unreadMessageList.map((um) => {
        if (um.conversation_id === conversationId) {
          um.unread_no = 0;
        }
        return um;
      });
      setUnreadMessageList(filterList);
      unreadMessageListRef.current = filterList;

      //update unread_no on server set to zero

      socket.emit("read-message-for-prospect", {
        conversationId: conversationId,
      });
    }
  };

  const toggleAccordionForProspect = () => {
    setaccordionOnForProspect(!accordionOnForProspect);
  };
  const toggleAccordionForProspectUnRead = () => {
    setaccordionOnForProspectUnRead(!accordionOnForProspectUnRead);
  };
  const toggleAccordionForTenantUnRead = () => {
    setaccordionOnForTenantUnRead(!accordionOnForTenantUnRead);
  };

  const toggleAccordionForTenant = (id) => {
    setSelectedProperty(id);
    if (selectedProperty === id) {
      setaccordionOnForTenant(!accordionOnForTenant);
    } else {
      setaccordionOnForTenant(true);
    }
  };
  const handleChange = (e) => {
    setMessageTextVal(e.target.value);
  };

  const sendMessage = () => {
    try {
      const userId = user.sub;

      let currentVal = messageTextVal;
      currentVal = currentVal.trim();
      if (currentVal) {
        if (!prospectTenantDetails.conversation_id) {
          toast.error("No conversation found");
        }
        const messagePayload = { conversationId: prospectTenantDetails.conversation_id, senderId: userId, senderName: user.name, message: currentVal };
        const currentDate = new Date();
        const isoDate = currentDate.toISOString();
        const messageItem = { cid: messagePayload.conversationId, author: messagePayload.senderName, createdAt: isoDate, message: currentVal, senderId: userId, media: [] };

        setChatMessagesList([...chatMessagesList, messageItem]);
        chatMessagesListRef.current = [...chatMessagesList, messageItem];

        if (prospectTenantDetails.type === "Prospect") {
          socket.emit("send-message-for-prospect", messagePayload);
        } else {
          socket.emit("send-message-for-tenant", messagePayload);
        }

        setMessageTextVal("");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const backToList = () => {
    try {
      setIsShowChatMessages(false);

      const updateConversationList = conversationList.map((c) => {
        if (c.conversationId === prospectTenantDetails.conversation_id) {
          c.messages = [...chatMessagesList];
        }
        return c;
      });
      setConversationList(updateConversationList);
      conversationListRef.current = updateConversationList;

      //update unread_no on server set to zero
      // socket.emit("read-message-for-prospect", {
      //   conversationId: prospectTenantDetails.conversation_id,
      // });

      //Add last conversation message to unread list tp update  local unreadMessage list

      if (chatMessagesList && chatMessagesList.length) {
        let msg = "";
        const lastMessage = chatMessagesList[chatMessagesList.length - 1];
        const time = lastMessage.createdAt;
        if (lastMessage.senderId) {
          msg = `You: ${lastMessage.message}`;

          const updateList = unreadMessageListRef.current.map((um) => {
            if (prospectTenantDetails.conversation_id === um.conversation_id) {
              um.message = msg;
              um.message_time = time;
            }
            return um;
          });

          unreadMessageListRef.current = updateList;
          setUnreadMessageList(updateList);
        }
      }

      //reset details informations
      setProspectTenantDetails(null);
      prospectTenantDetailsRef.current = null;
      setProspectTenantNames([]);
    } catch (error) {
      console.log(error);
    }
  };

  const filterByCallback = (option, props) => {
    const inputValue = props.text.toLowerCase();
    const firstName = option.first_name ? option.first_name.toLowerCase() : "";
    const lastName = option.last_name ? option.last_name.toLowerCase() : "";
    const phone = option.phone ? option.phone.toLowerCase() : "";

    return (firstName.includes(inputValue) || lastName.includes(inputValue) || phone.includes(inputValue));
  };

  const onSelectTypeahead = (value) => {
    value = value[0];

    const findTenantProspect = prospectTenantList.find((pt) => pt.conversation_id === value.conversation_id);

    if (findTenantProspect) {
      setDetails(findTenantProspect);
    } else {
      toast.error("Prospect or Tenant not found");
    }
  };

  const navigateTo = (v) => { };

  const initiateVoiceCall = (item) => {
    if (voiceCallInfo) {
      toast.error("You are on anotehr call.");
    } else {
      setOpenCallModal(true);
      setProspectInfoObj(item);
    }
  }
  const initiateVoiceCallConfirm = async () => {
    setOpenCallModal(false);

    let initailTemp = "?";
    if (prospectInfoObj?.first_name !== '' && prospectInfoObj?.last_name !== '') {
      initailTemp = prospectInfoObj?.first_name.charAt(0);
      initailTemp += prospectInfoObj?.last_name.charAt(0);
      initailTemp = initailTemp.toUpperCase();
    }

    let newProspectInfo = { id: prospectInfoObj?.id, full_name: prospectInfoObj?.first_name + " " + prospectInfoObj?.last_name, initial: initailTemp, phone: prospectInfoObj?.phone, user_avatar: "" };
    dispatch(communicationVoiceCallScreenOpen(true));
    dispatch(setVoiceCallInfo(newProspectInfo));
  }

  return (<>
    {isChatVisible && (<div className={isCommunicationChatBoxOpen ? `${styles.CommunicationPopupSec} CommunicationPopupSec ${styles.Open} Open` : `${styles.CommunicationPopupSec} CommunicationPopupSec`}>
      <div className={`${styles.ChatPopBox}`}>
        <div className={`${styles.PopupSecHead}`}>
          <div className={`${styles.DisFlex}`}>
            {isShowChatMessages && (<a className={`${styles.AllListBU}`} onClick={() => backToList()}><ChevronLeft /></a>)}
            {!isShowChatMessages && <p>SMS Chat</p>}
            {!isShowChatMessages && (<div className={`${styles.MobileCloseBU}`} onClick={communicationChatBoxHandler}><X /></div>)}
            {isShowChatMessages && (<div className={`${styles.ChatDeHeadRow}`}>
              {prospectTenantNames.slice(0, 1).map((pt, i) => {
                return (<p key={i} className={`${styles.ChatDeHeadName}`}>
                  {pt?.type === "Prospect" && (<span>
                    {" "}
                    <Link to={`/people/prospects/details/${pt.id}`}>{pt.first_name ? pt.first_name + " " + pt.last_name : pt.phone}</Link>
                  </span>)}
                  {pt?.type === "Tenant" && (<span>
                    {" "}
                    <Link to={`/people/tenants/details/${pt.tenant_id}`}>{pt.first_name ? pt.first_name + " " + pt.last_name : pt.phone}</Link>
                  </span>)}
                  {prospectTenantNames.length > 1 && ","}&nbsp;
                  {prospectTenantNames.length === 2 && (<BootstrapTooltip title={prospectTenantNamesForToolTip}>1 {pt.type === "Tenant" ? "Tenant" : "Prospect"}</BootstrapTooltip>)}
                  {prospectTenantNames.length > 2 && (<BootstrapTooltip title={prospectTenantNamesForToolTip}>{prospectTenantNamesForToolTip.length}{" "}{pt.type === "Tenant" ? "Tenants" : "Prospects"}</BootstrapTooltip>)}
                  {pt.type === "Tenant" && (<img src="/static/img/verified.svg" className={`${styles.VarifiedBadgeTenantChatHead}`} alt="" />)}
                  {pt.type === "Prospect" && pt?.current_status === 0 && (<span className={`${styles.StatusBadge} ${styles.NotApplied}`}>Not Applied</span>)}
                  {pt.type === "Prospect" && pt?.current_status === 1 && (<span className={`${styles.StatusBadge} ${styles.Applied}`}>Applied</span>)}
                  {pt.type === "Prospect" && pt?.current_status === 2 && (<span className={`${styles.StatusBadge} ${styles.Screened}`}>Screened</span>)}
                  {pt.type === "Prospect" && pt?.current_status === 3 && (<span className={`${styles.StatusBadge} ${styles.Approved}`}>Approved</span>)}
                  {pt.type === "Prospect" && pt?.current_status === 4 && (<span className={`${styles.StatusBadge} ${styles.Declined}`}>Declined</span>)}
                  {/* <Dropdown className={`${styles.ActionLeaseBUSec}`}>
                        <Dropdown.Toggle className={`${styles.ActionsLeaseBU} ActionsBU`} id="dropdown-basic">
                            <MoreVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={() =>markAsReadConversationMessages(prospectTenantNames[0]?.conversation_id)}>
                                Mark all as read
                            </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                  <button className={`${styles.PhoneCallBU}`} onClick={(e) => { e.preventDefault(); initiateVoiceCall(prospectTenantNames[0]); }}><PhoneCall /></button>
                </p>);
              })}
              {prospectTenantNames.length > 0 && prospectTenantNames[0]?.property_name && (<div className={`${styles.SubHeadSec}`}>
                <p className={`${styles.ChatDeHeadDetails}`}>
                  <span>{prospectTenantNames[0]?.property_name}</span>
                  <span>{prospectTenantNames[0]?.apt_no}</span>
                </p>
                {prospectTenantNames[0].unread_no > 0 && (<button className={`${styles.MarkReadBU}`} onClick={() => markAsReadConversationMessages(prospectTenantNames[0]?.conversation_id)}>Mark as read</button>)}
              </div>)}
            </div>)}
          </div>
          {!isShowChatMessages && (<div className={`${styles.PopupSecHeadTabSec}`}>
            <button onClick={() => tabHandler("globalListTab")} className={isShowGlobalList === true ? `${styles.Active}` : ""}>
              <span>Global List</span>
            </button>
            <button onClick={() => tabHandler("unreadMessageTab")} className={isShowGlobalList === false ? `${styles.Active}` : ""}>
              <span>Messages</span>
              {unreadMessagesCounter > 0 && (<span className={`${styles.UnreadCounter}`}>
                ({unreadMessagesCounter})
              </span>)}
            </button>
          </div>)}
        </div>

        <div className={`${styles.PopupSecBody} PopupSecBody`}>
          {!isShowChatMessages && (<ProspectTenantSearch
            onSelectTypeahead={onSelectTypeahead}
            typeaheadProspectTenantList={typeaheadProspectTenantList}
            filterByCallback={filterByCallback}
          />)}
          {!isShowChatMessages && (<div className={` ${styles.PopupContent}`}>
            {!isShowChatMessages && isShowGlobalList && (<ProspectList
              toggleAccordionForProspect={toggleAccordionForProspect}
              accordionOnForProspect={accordionOnForProspect}
              prospectTenantDetails={prospectTenantDetails}
              setDetails={setDetails}
              prospectList={prospectList}
            />)}
            {!isShowChatMessages && isShowGlobalList && (<TenantList
              toggleAccordionForTenant={toggleAccordionForTenant}
              accordionOnForTenant={accordionOnForTenant}
              selectedProperty={selectedProperty}
              prospectTenantDetails={prospectTenantDetails}
              setDetails={setDetails}
              tenantList={tenantList}
              typeaheadProspectTenantList={typeaheadProspectTenantList}
            />)}
            {!isShowChatMessages && isShowUnreadMessageList && (<ProspectListunread
              toggleAccordionForProspectUnRead={toggleAccordionForProspectUnRead}
              accordionOnForProspectUnRead={accordionOnForProspectUnRead}
              prospectTenantDetails={prospectTenantDetails}
              setDetails={setDetails}
              unreadMessageList={unreadMessageList}
            />)}
            {!isShowChatMessages && isShowUnreadMessageList && (<TenantListunread
              toggleAccordionForTenantUnRead={toggleAccordionForTenantUnRead}
              accordionOnForTenantUnRead={accordionOnForTenantUnRead}
              prospectTenantDetails={prospectTenantDetails}
              setDetails={setDetails}
              unreadMessageList={unreadMessageList}
            />)}

            {/* {!isShowChatMessages && isShowUnreadMessageList && (
                  <UnreadMessagesList
                    unreadMessageList={unreadMessageList}
                    prospectTenantDetails={prospectTenantDetails}
                    setDetails={setDetails}
                  />
                )} */}
          </div>)}
          {isShowChatMessages && (<div className={` ${styles.PopupContentMessageSec}`}>
            <ChatMessages
              messageLoader={messageLoader}
              chatMessagesList={chatMessagesList}
              prospectTenantNames={prospectTenantNames}
              unreadMessageList={unreadMessageList}
            />
            <ChatInputBox
              messageTextVal={messageTextVal}
              handleChange={handleChange}
              sendMessage={sendMessage}
            />
          </div>)}
        </div>
      </div>
      {isShowCommunicationChat && (<div onClick={communicationChatBoxHandler} className={`${styles.ChatBoxOpenBU}`}>
        <div className={`${styles.BUOpen}`}>
          {unreadMessagesCounter > 0 && (<span className={`${styles.ChatBoxOpenBUCounter}`}>{unreadMessagesCounter}</span>)}
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 39 37" class="conversations-visitor-open-icon">
            <defs>
              <path id="conversations-visitor-open-icon-path-1:r0:" d="M31.4824242 24.6256121L31.4824242 0.501369697 0.476266667 0.501369697 0.476266667 24.6256121z"></path>
            </defs>
            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
              <g transform="translate(-1432 -977) translate(1415.723 959.455)">
                <g transform="translate(17 17)">
                  <g transform="translate(6.333 .075)">
                    <path fill="#ffffff" d="M30.594 4.773c-.314-1.943-1.486-3.113-3.374-3.38C27.174 1.382 22.576.5 15.36.5c-7.214 0-11.812.882-11.843.889-1.477.21-2.507.967-3.042 2.192a83.103 83.103 0 019.312-.503c6.994 0 11.647.804 12.33.93 3.079.462 5.22 2.598 5.738 5.728.224 1.02.932 4.606.932 8.887 0 2.292-.206 4.395-.428 6.002 1.22-.516 1.988-1.55 2.23-3.044.008-.037.893-3.814.893-8.415 0-4.6-.885-8.377-.89-8.394"></path>
                  </g>
                  <g fill="#ffffff" transform="translate(0 5.832)">
                    <path d="M31.354 4.473c-.314-1.944-1.487-3.114-3.374-3.382-.046-.01-4.644-.89-11.859-.89-7.214 0-11.813.88-11.843.888-1.903.27-3.075 1.44-3.384 3.363C.884 4.489 0 8.266 0 12.867c0 4.6.884 8.377.889 8.393.314 1.944 1.486 3.114 3.374 3.382.037.007 3.02.578 7.933.801l2.928 5.072a1.151 1.151 0 001.994 0l2.929-5.071c4.913-.224 7.893-.794 7.918-.8 1.902-.27 3.075-1.44 3.384-3.363.01-.037.893-3.814.893-8.414 0-4.601-.884-8.378-.888-8.394"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`${styles.BUClose}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19 18" class="conversations-visitor-close-icon">
            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
              <g fill="#ffffff" transform="translate(-927 -991) translate(900.277 962)">
                <g transform="translate(27 29)">
                  <path d="M10.627 9.013l6.872 6.873.708.707-1.415 1.414-.707-.707-6.872-6.872L2.34 17.3l-.707.707L.22 16.593l.707-.707L7.8 9.013.946 2.161l-.707-.708L1.653.04l.707.707L9.213 7.6 16.066.746l.707-.707 1.414 1.414-.707.708-6.853 6.852z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>)}
    </div>)}

    <Dialog
      open={openCallModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenCallModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Prospect Call Confirmation
        <button onClick={(e) => setOpenCallModal(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">Please confirm you wish to initiate a call to this prospect.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpenCallModal(false)} className="CancelPopupBU">Cancel</Button>
        <Button onClick={(e) => initiateVoiceCallConfirm()} className="SubmitPopupBU">Proceed</Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default CommunicationChat;
