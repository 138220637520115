
const communicationVoiceCallScreenOpen = (flag) => {

    return {
        type: 'screenopen',
        payload: flag

    }
}


export { communicationVoiceCallScreenOpen }