import { Mail, X } from "react-feather";
import styles from "../tour.module.css";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setConversationId } from "../../../redux/actions/communicationConversationId";

const EventView = (props) => {
  const dispatch = useDispatch();

  const openProspectConversation = (e, conversation_id) => {
    e.preventDefault();
    dispatch(setConversationId(conversation_id));
  };

  return (<>
    <DialogTitle id="alert-dialog-title">
      {props?.isTour ? "Tour" : "Open House"}
      <button onClick={(e) => props.setOpenHouseFormOpen(false)}><X /></button>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <div className={`${styles.CalendarEditPopup}`}>
          <ul>
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Prospect:</p>
              <Link className={`${styles.CalendarEditPopupCon}`} to={"/people/prospects/details/" + props?.selectedEvent?.extendedProps?.prospect_id}>{props?.selectedEvent?.extendedProps?.prospect_name}</Link>
              <button className={`${styles.ChatIcon}`} onClick={(e) => { props.setOpenHouseFormOpen(false); openProspectConversation(e, props?.selectedEvent?.extendedProps?.conversation_id); }}><Mail /></button>
            </li>)}
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Phone:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{(props?.selectedEvent?.extendedProps?.prospect_phone !== '' ? props?.selectedEvent?.extendedProps?.prospect_phone : '-')}</p>
            </li>)}
            {props?.isTour && (<li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Email:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{(props?.selectedEvent?.extendedProps?.prospect_email !== '' ? props?.selectedEvent?.extendedProps?.prospect_email : '-')}</p>
            </li>)}
            <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Property:</p>
              <Link className={`${styles.CalendarEditPopupCon}`} to={"/properties/details/" + props?.selectedEvent?.extendedProps?.property_id}>{props?.selectedEvent?.extendedProps?.property_name}</Link>
            </li>
            {props?.selectedEvent?.extendedProps?.apt_no !== "" && <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Apartment:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{props?.selectedEvent?.extendedProps?.apt_no}</p>
            </li>}
            <li>
              <p className={`${styles.CalendarEditPopupTitle}`}>Date:</p>
              <p className={`${styles.CalendarEditPopupCon}`}>{dayjs(props?.selectedEvent?.extendedProps?.date_time).format("MM/DD/YYYY hh:mmA")}</p>
            </li>
          </ul>
        </div>
      </DialogContentText>
    </DialogContent>

    {(props?.selectedEvent?.extendedProps?.is_completed === 0) && <DialogActions>
      <Button className="CancelPopupBU" onClick={(e) => { props.setOpenHouseFormOpen(false); props.setOpenDeleteModal(true); }}>Delete</Button>
      <Button className="SubmitPopupBU" onClick={(e) => { props.setOpenHouseFormOpen(false); props.setEditEventOpen(true); }}>Reschedule</Button>
      <Button className="SubmitPopupBU" onClick={(e) => { props.setOpenHouseFormOpen(false); props.setOpenCompleteModal(true); }}>Mark Complete</Button>
    </DialogActions>}
  </>);
};

export default EventView;
