import React, { useEffect, useState } from "react";
import moment from "moment";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import styles from "./prospectlist.module.css";
import { Link, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Archive, ArrowRight, Award, ChevronDown, Filter, Home, Mail, MoreVertical, PhoneCall, Plus, Send, Trash2, X } from "react-feather";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import Footer from "../../components/Footer";
import { Search, Calendar } from "react-feather";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Button, Dropdown } from "react-bootstrap";
import { AddLogCallAPI } from "../../components/AddLogs";
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Snackbar, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TablePagination, Menu } from "@mui/material";
import dayjs from "dayjs";
import DateRangePicker from "rsuite/DateRangePicker";
import IconButton from "@mui/material/IconButton";
import StepOneForm from "./Components/AddProspect/StepOneForm";
import StepTwoForm from "./Components/AddProspect/StepTwoForm";
import StepThreeForm from "./Components/AddProspect/StepThreeForm";
import PeoplePageTitle from "../tenants/components/PeoplePageTitle";
import { communicationChatReload } from "../../redux/actions/communicationChatRloadAction";
import { setConversationId } from "../../redux/actions/communicationConversationId";
import { communicationVoiceCallScreenOpen } from "../../redux/actions/communicationVoiceCallScreenAction";
import { setVoiceCallInfo } from "../../redux/actions/communicationVoiceCallInfo";

const ProspectList = ({ history, match }) => {
  const voiceCallInfo = useSelector((store) => store.communicationVoiceCallInfoReducer);
  const redirect = useHistory();
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [prospectList, setProspectList] = useState([]);
  const [prospectFilteredList, setFilteredProspectList] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [perPage, setPerPage] = useState(8);
  const [page, setPage] = useState(0);

  const [filterData, setFilterData] = useState({ searchKey: "", created: null, property: "", prospect: "", credit: "", income: "" });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [openAddProspectModal, setOpenAddProspectModal] = useState(false);
  const [addFormStep, setAddFormStep] = useState(1);
  const [stepOneData, setStepOneData] = useState(null);
  const [stepTwoData, setStepTwoData] = useState(null);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [archiveId, setArchiveId] = useState(0);
  const [isArchived, setIsArchived] = useState(0);
  const [applicationModalOpen, setApplicationModalOpen] = useState(false);
  const [openLeaderBoardModal, setOpenLeaderBoardModal] = useState(false);
  const [openCallModal, setOpenCallModal] = useState(false);
  const [prospectInfoObj, setProspectInfoObj] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchAllProperties = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        let propertyData = response.data.data;
        propertyData = [{ value: "all", label: "Any" }].concat(propertyData);

        setAllProperties(propertyData);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchProspect = async () => {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setContentLoading(false);
        setProspectList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setContentLoading(false);
      });
    };
    fetchProspect();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filteredList = prospectList;
    let filterDataTemp = filterData;

    if (filterDataTemp.searchKey !== "") {
      let inputVal = filterData.searchKey;
      inputVal = inputVal.toLowerCase();
      filteredList = filteredList.filter((item) => {
        let phone2 = item?.phone?.replace(/\D/g, "");
        return (item?.full_name?.toLowerCase().includes(inputVal) || item?.phone?.includes(inputVal) || phone2?.includes(inputVal) || item?.credit_score?.includes(inputVal) || item?.income_per_year?.includes(inputVal) || item?.formatted_income_per_year?.includes(inputVal) || item?.property_name?.toLowerCase().includes(inputVal) || item?.apt_no?.toLowerCase().includes(inputVal));
      });
    }

    if (filterDataTemp.created && filterDataTemp.created !== "") {
      filteredList = filteredList.filter((i) => {
        var dateR = filterData.created;
        if (moment(moment(i.submitted_at).format("YYYY-MM-DD")).isBetween(moment(dateR[0]).format("YYYY-MM-DD"), moment(dateR[1]).format("YYYY-MM-DD"), undefined, [])) {
          return true;
        }
        return false;
      });
    }

    if (filterDataTemp.property !== "" && filterDataTemp.property !== "all") {
      filteredList = filteredList.filter((i) => i.property_id === filterDataTemp.property);
    }

    if (filterDataTemp.prospect !== "" && filterDataTemp.prospect !== "all") {
      if (filterDataTemp.prospect === "archived") {
        filteredList = filteredList.filter((i) => i.is_archived === 1);
      } else {
        if (filterDataTemp.prospect === "not_applied") {
          filteredList = filteredList.filter((i) => i.current_status === 0 && i.is_archived === 0);
        }
        if (filterDataTemp.prospect === "applied") {
          filteredList = filteredList.filter((i) => i.current_status === 1 && i.is_archived === 0);
        }
        if (filterDataTemp.prospect === "screened") {
          filteredList = filteredList.filter((i) => i.current_status === 2 && i.is_archived === 0);
        }
        if (filterDataTemp.prospect === "approved") {
          filteredList = filteredList.filter((i) => i.current_status === 3 && i.is_archived === 0);
        }
        if (filterDataTemp.prospect === "declined") {
          filteredList = filteredList.filter((i) => i.current_status === 4 && i.is_archived === 0);
        }
      }
    }

    if (filterDataTemp.prospect === "") {
      filteredList = filteredList.filter((i) => i.is_archived === 0);
    }

    if (filterDataTemp.credit !== "" && filterDataTemp.credit !== "all") {
      if (filterDataTemp.credit === "g_than") {
        filteredList = filteredList.filter((i) => parseFloat(i.credit_score) >= 650);
      }
      if (filterDataTemp.credit === "l_than") {
        filteredList = filteredList.filter((i) => parseFloat(i.credit_score) < 650);
      }
    }

    if (filterDataTemp.income !== "" && filterDataTemp.income !== "all") {
      if (filterDataTemp.income === "b_20") {
        filteredList = filteredList.filter((i) => parseFloat(i.income_per_year) <= 20000);
      }
      if (filterDataTemp.income === "20_b_40") {
        filteredList = filteredList.filter((i) => parseFloat(i.income_per_year) > 20000 && parseFloat(i.income_per_year) <= 40000);
      }
      if (filterDataTemp.income === "40_b_60") {
        filteredList = filteredList.filter((i) => parseFloat(i.income_per_year) > 40000 && parseFloat(i.income_per_year) <= 60000);
      }
      if (filterDataTemp.income === "60_b_80") {
        filteredList = filteredList.filter((i) => parseFloat(i.income_per_year) > 60000 && parseFloat(i.income_per_year) <= 80000);
      }
      if (filterDataTemp.income === "a_80") {
        filteredList = filteredList.filter((i) => parseFloat(i.income_per_year) > 80000);
      }
    }

    setFilteredProspectList(filteredList);
    setPage(0);
  }, [prospectList, filterData]);

  const onChangeHandler = (e) => {
    let filterDataTemp = filterData;
    filterDataTemp = { ...filterDataTemp, searchKey: e.target.value };

    setFilterData(filterDataTemp);
  };

  const handleChangeFilter = (stype, selectedOption) => {
    let filterDataTemp = filterData;
    if (stype === "property") {
      filterDataTemp = { ...filterDataTemp, property: selectedOption?.target?.value };
    }
    if (stype === "prospect") {
      filterDataTemp = { ...filterDataTemp, prospect: selectedOption?.target?.value };
    }
    if (stype === "credit") {
      filterDataTemp = { ...filterDataTemp, credit: selectedOption?.target?.value };
    }
    if (stype === "income") {
      filterDataTemp = { ...filterDataTemp, income: selectedOption?.target?.value };
    }
    setFilterData(filterDataTemp);
  };

  const changeDateFilter = (date) => {
    let filterDataTemp = filterData;
    filterDataTemp = { ...filterDataTemp, created: date };
    setFilterData(filterDataTemp);
  };

  const deleteConfirm = async () => {
    let deleteIdTemp = deleteId;
    setOpenDeleteModal(false);
    setDeleteId(0);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/" + deleteIdTemp, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let respData = response.data.data;

      let prospectListTemp = prospectList;
      prospectListTemp = prospectListTemp.filter((i) => i.id !== deleteIdTemp);
      setProspectList(prospectListTemp);

      let logData = {
        title: "Prospect is deleted",
        description: [
          "Name: " + respData?.full_name,
          "Email: " + respData?.email,
          "Phone: " + respData?.phone,
          "Property: " + respData?.property_name,
          "Apartment: " + respData?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);

      dispatch(communicationChatReload(true));
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const onSubmitOne = (data) => {
    setAddFormStep(2);
    setStepOneData(data);
  };

  const onSubmitTwo = (data) => {
    let cData = {};
    if (typeof data.credit_score !== "undefined") {
      cData = { ...cData, credit_score: data.credit_score };
    }
    if (typeof data.have_pets !== "undefined") {
      cData = { ...cData, have_pets: data.have_pets };
    }
    if (typeof data.income_per_year !== "undefined") {
      cData = { ...cData, income_per_year: data.income_per_year };
    }
    if (typeof data.bedrooms !== "undefined") {
      cData = { ...cData, bedrooms: data.bedrooms };
    }
    if (typeof data.move_in_date !== "undefined") {
      cData = { ...cData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss") };
    }
    if (typeof data.bathrooms !== "undefined") {
      cData = { ...cData, bathrooms: data.bathrooms };
    }
    if (typeof data.do_you_smoke !== "undefined") {
      cData = { ...cData, do_you_smoke: data.do_you_smoke };
    }
    if (typeof data.is_tour !== "undefined") {
      cData = { ...cData, is_tour: data.is_tour };
    }

    setAddFormStep(3);
    setStepTwoData(cData);
  };

  const onAddProspectSubmit = async (data) => {
    let postData = {};
    let is_tour = false;
    postData = { ...postData, ...stepOneData, ...stepTwoData };
    if (addFormStep === 2) {
      if (typeof data.credit_score !== "undefined") {
        postData = { ...postData, credit_score: data.credit_score };
      }
      if (typeof data.have_pets !== "undefined") {
        postData = { ...postData, have_pets: data.have_pets };
      }
      if (typeof data.income_per_year !== "undefined") {
        postData = { ...postData, income_per_year: data.income_per_year };
      }
      if (typeof data.bedrooms !== "undefined") {
        postData = { ...postData, bedrooms: data.bedrooms };
      }
      if (typeof data.move_in_date !== "undefined") {
        postData = { ...postData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD HH:mm:ss") };
      }
      if (typeof data.bathrooms !== "undefined") {
        postData = { ...postData, bathrooms: data.bathrooms };
      }
      if (typeof data.do_you_smoke !== "undefined") {
        postData = { ...postData, do_you_smoke: data.do_you_smoke };
      }
    }

    if (typeof postData.is_tour !== "undefined") {
      is_tour = postData.is_tour;
    }

    if (typeof data.tour_date_time !== "undefined") {
      postData = { ...postData, tour_date_time: data.tour_date_time };
    }

    setOpenAddProspectModal(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    await axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-prospect", postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);
      dispatch(communicationChatReload(true));
      let prospectListTemp = prospectList;
      prospectListTemp = [response.data.data].concat(prospectListTemp);

      setProspectList(prospectListTemp);

      let logData = {
        title: "A new prospect is added",
        description: [
          "Name: " + response?.data?.data?.full_name,
          "Phone: " + response?.data?.data?.phone,
          "Property: " + response?.data?.data?.property_name,
          "Apartment: " + response?.data?.data?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);

      if (is_tour) {
        let logData = {
          title: "A new prospect tour is added",
          description: [
            "Name: " + response?.data?.data?.full_name,
            "Phone: " + response?.data?.data?.phone,
            "Property: " + response?.data?.data?.property_name,
            "Date/Time: " +
            moment(data.tour_date_time).format("MM/DD/YYYY hh:mm a"),
          ],
        };
        AddLogCallAPI(logData, token);
      }
    }).catch((error) => {
      setLoading(false);
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const FilterOpenFunction = () => {
    setFilterOpen(true);
  };
  const FilterCloseFunction = () => {
    setFilterOpen(false);
  };

  const openProspectConversation = (e, item) => {
    const { conversation_id } = item;
    e.preventDefault();
    dispatch(setConversationId(conversation_id));
  };

  const archiveConfirm = async () => {
    setArchiveModalOpen(false);
    setLoading(true);

    let URL = "";

    if (isArchived === 0) {
      URL = process.env.REACT_APP_APPLICANT_API_URL + "v1/prospect-archive/" + archiveId;
    } else {
      URL = process.env.REACT_APP_APPLICANT_API_URL + "v1/prospect-unarchive/" + archiveId;
    }

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.put(URL, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let respData = response.data.data;

      setProspectList((prev) => {
        return prev.map((i) => {
          if (i.id === archiveId) {
            return { ...i, is_archived: isArchived ? 0 : 1 };
          }
          return i;
        });
      });

      dispatch(communicationChatReload(true));

      let logData = {
        title: "Prospect is " + (isArchived ? "unarchived" : "archived"),
        description: [
          "Name: " + respData?.full_name,
          "Email: " + respData?.email,
          "Phone: " + respData?.phone,
          "Property: " + respData?.property_name,
          "Apartment: " + respData?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const sendApplicationLInk = async () => {
    setApplicationModalOpen(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/send-application-link/" + archiveId, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let respData = response.data.data;

      dispatch(communicationChatReload(true));

      let logData = {
        title: "Application link has been sent successfully.",
        description: [
          "Name: " + respData?.full_name,
          "Email: " + respData?.email,
          "Phone: " + respData?.phone,
          "Property: " + respData?.property_name,
          "Apartment: " + respData?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const addToLeaderBoard = async () => {
    setOpenLeaderBoardModal(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-to-leaderboard/" + archiveId, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      setProspectList((prev) => {
        return prev.filter((i) => i.id !== archiveId);
      });

      setArchiveId(0);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const initiateVoiceCall = (item) => {
    if (voiceCallInfo) {
      toast.error("You are on anotehr call.");
    } else {
      setOpenCallModal(true);
      setProspectInfoObj(item);
    }
  }
  const initiateVoiceCallConfirm = () => {
    setOpenCallModal(false);
    let newProspectInfo = { id: prospectInfoObj?.id, full_name: prospectInfoObj?.full_name, initial: prospectInfoObj?.initial, phone: prospectInfoObj?.phone, user_avatar: prospectInfoObj?.user_avatar };
    dispatch(communicationVoiceCallScreenOpen(true));
    dispatch(setVoiceCallInfo(newProspectInfo));
  }


  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title="Prospects" />
    <div className={`${styles.pageTitleWrap}`}>
      <div className={`${styles.PageMainTTSec}`}>
        <PeoplePageTitle defaultPage={"Prospects"} />
        <Typography display="inline" className={`${styles.pageTitle} ${styles.Small}`} onClick={(e) => setAnchorEl(e.currentTarget)}>Prospects <ChevronDown /></Typography>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => setAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <MenuItem className={`${styles.DropdownPageTitleMenu}`}>
            <Link to="/people/prospects">Prospect</Link>
          </MenuItem>
          <MenuItem className={`${styles.DropdownPageTitleMenu}`}>
            <Link to="/people/prospects/leaderboards">Leaderboard</Link>
          </MenuItem>
        </Menu>
        {prospectList?.length > 0 && (<p className={`${styles.CountText}`}>({prospectList?.length} Prospects)</p>)}
      </div>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>People</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Prospects</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}
          {!contentLoading && (<div className="control-pane">
            <div className={`${styles.Row}`}>
              <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={onChangeHandler.bind(this)} />
                  </div>
                </div>

                <div className={`${styles.FilterSec}`}>
                  <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                    <p className={`${styles.FilterTitle}`}>Filters<span onClick={FilterCloseFunction}><ArrowRight /></span></p>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width220} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Property"
                          onChange={handleChangeFilter.bind(this, "property")}
                        >
                          {allProperties?.map((i) => {
                            return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard}`}>
                      <div className={`${styles.DashFilterSec} DashFilterSec Filter`}>
                        <DateRangePicker
                          format={"MM/dd/yy"}
                          placeholder={"Filter by date"}
                          placement={"bottomEnd"}
                          caretAs={Calendar}
                          showOneCalendar
                          onChange={changeDateFilter}
                        />
                      </div>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Prospects</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Prospects"
                          onChange={handleChangeFilter.bind(this, "prospect")}
                        >
                          <MenuItem value="all">All Prospects</MenuItem>
                          <MenuItem value="applied">Applied</MenuItem>
                          <MenuItem value="not_applied">Not Applied</MenuItem>
                          <MenuItem value="screened">Screened</MenuItem>
                          <MenuItem value="approved">Approved</MenuItem>
                          <MenuItem value="declined">Declined</MenuItem>
                          <MenuItem value="archived">Archived</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter by Credit</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Filter by Credit"
                          onChange={handleChangeFilter.bind(this, "credit")}
                        >
                          <MenuItem value="all">All Credit Scores</MenuItem>
                          <MenuItem value="l_than">Below 650</MenuItem>
                          <MenuItem value="g_than">650 & Above</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width180} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter by Salary</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Filter by Salary"
                          onChange={handleChangeFilter.bind(this, "income")}
                        >
                          <MenuItem value="all">All Salary</MenuItem>
                          <MenuItem value="b_20">&lt; $20,000</MenuItem>
                          <MenuItem value="20_b_40">$20,000 - $40,000</MenuItem>
                          <MenuItem value="40_b_60">$40,000 - $60,000</MenuItem>
                          <MenuItem value="60_b_80">$60,000 - $80,000</MenuItem>
                          <MenuItem value="a_80">&gt; $80,000</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Link className={`${styles.FilterBu}`} onClick={FilterOpenFunction}><Filter /></Link>
                  {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:create") > -1 && (<button className={`${styles.AddNoteBu}`} onClick={(e) => { setOpenAddProspectModal(true); setAddFormStep(1); }}>
                    <Plus /> <span>Prospect</span>
                  </button>)}
                </div>
              </div>

              <div className={`${styles.NotesRow}`}>
                {prospectFilteredList.map((item, index) => {
                  return perPage === -1 || (index >= page * perPage && index < page * perPage + perPage) ? (<div className={`${styles.ProspectCard}`}>
                    <Link to={`/people/prospects/details/${item.id}`}>
                      <div className={`${styles.ProspectCardUnder}`}>
                        <div className={`${styles.ProspectCardHeadSection}`}>
                          <div className={`${styles.ProsIMGSec}`}>
                            {item?.user_avatar !== "" ? (<img src={item?.user_avatar} className={`${styles.defaultImg}`} alt="" />) : item?.initial !== "?" ? (<p>{item?.initial}</p>) : (<img src="/static/img/DefaultLogo.png" className={`${styles.defaultImg}`} alt="" />)}
                          </div>

                          <div className={`${styles.HeadConSec}`}>
                            <div className={`${styles.prospectNameActionSec}`}>
                              <Tooltip title={item?.full_name} placement="bottom-start">
                                <p className={`${styles.ProsUserName} ${styles.LimitName}`}>
                                  {item?.full_name !== "" ? item?.full_name : "-"}
                                  {item?.is_verified === 1 && (<img src="/static/img/verified.svg" className={`${styles.Varified}`} alt="" />)}
                                </p>
                              </Tooltip>
                              {item?.is_archived === 1 && (<span className={`${styles.ProspectNonAppliedBadge}`}>Archived</span>)}
                              {item?.is_archived === 0 && (<>
                                {item?.current_status === 0 && (<span className={`${styles.ProspectNonAppliedBadge}`}>Not Applied</span>)}
                                {item?.current_status === 1 && (<span className={`${styles.ProspectAppliedBadge}`}>Applied</span>)}
                                {item?.current_status === 2 && (<span className={`${styles.ProspectScreenedBadge}`}>Screened</span>)}
                                {item?.current_status === 3 && (<span className={`${styles.ProspectApprovedBadge}`}>Approved</span>)}
                                {item?.current_status === 4 && (<span className={`${styles.ProspectDeclinedBadge}`}>Declined</span>)}
                              </>)}

                              {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:delete") > -1 && (<Dropdown onClick={(e) => { e.preventDefault(); }}>
                                <Dropdown.Toggle className={`${styles.ActionsBU} ProsDrBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>

                                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                  {item?.is_payment === 0 && item?.is_applied === 1 && (<Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setOpenDeleteModal(false); setOpenLeaderBoardModal(false); setArchiveModalOpen(false); setApplicationModalOpen(true); setDeleteId(0); setArchiveId(item?.id); setIsArchived(0); }}><Send />Send Application Link</Dropdown.Item>)}
                                  <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setOpenDeleteModal(false); setOpenLeaderBoardModal(false); setApplicationModalOpen(false); setArchiveModalOpen(true); setDeleteId(0); setArchiveId(item?.id); setIsArchived(item?.is_archived); }}><Archive />{item?.is_archived ? "Unarchive" : "Archive"}</Dropdown.Item>
                                  {item?.is_archived === 0 && (<Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setOpenDeleteModal(false); setOpenLeaderBoardModal(true); setApplicationModalOpen(false); setArchiveModalOpen(false); setDeleteId(0); setArchiveId(item?.id); }}><Award /> Add to Leaderboard</Dropdown.Item>)}
                                  {item?.is_prospect_applied !== 1 && (<Dropdown.Item className={`${styles.ActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setArchiveModalOpen(false); setApplicationModalOpen(false); setOpenLeaderBoardModal(false); setOpenDeleteModal(true); setDeleteId(item?.id); setArchiveId(0); }}><Trash2 />Delete</Dropdown.Item>)}
                                </Dropdown.Menu>
                              </Dropdown>)}
                            </div>
                            <div className={`${styles.prospectNameActionSec}`}>
                              <Tooltip title="Start a voice call">
                                <p className={`${styles.ProsUserName} ${styles.CallLink}`} onClick={(e) => { e.preventDefault(); initiateVoiceCall(item); }}>
                                  {item?.phone}
                                </p>
                              </Tooltip>

                              {item?.afford_amount !== "" && item?.check_list?.income === 2 && (<span className={`${styles.ProspectDeclinedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>)}
                              {item?.afford_amount !== "" && item?.check_list?.income === 1 && (<span className={`${styles.ProspectApprovedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>)}
                            </div>

                            <div className={`${styles.ProsUserMandateSec}`}>
                              <Tooltip title={item?.credit_score !== "" ? "Credit Score - " + item?.credit_score : "Credit Score - N/A"}>
                                <span className={item?.check_list?.credit_score === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.credit_score === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                  <img src="/static/img/credit-score-icon.svg" alt="" />
                                </span>
                              </Tooltip>
                              <Tooltip title={item?.income_type === "Voucher" ? item?.voucher_amount !== "" ? "Voucher - $" + item?.formatted_voucher_amount : "Voucher - N/A" : item?.income_per_year !== "" ? "Salary - $" + item?.formatted_income_per_year : "Salary - N/A"}>
                                <span className={item?.check_list?.income === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.income === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                  <img src="/static/img/salary-icon.svg" alt="" />
                                </span>
                              </Tooltip>
                              <Tooltip title={item?.ever_had_bankruptcy !== "" ? "Bankruptcy - " + item?.ever_had_bankruptcy : "Bankruptcy - N/A"}>
                                <span className={item?.check_list?.bankruptcy === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.bankruptcy === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                  <img src="/static/img/bankruptcy-icon.svg" alt="" />
                                </span>
                              </Tooltip>
                              <Tooltip title={item?.ever_case_filed !== "" ? "Housing Court Cases - " + item?.ever_case_filed : "Housing Court Cases - N/A"}>
                                <span className={item?.check_list?.housing_court_case === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.housing_court_case === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                  <img src="/static/img/court-cases-icon.svg" alt="" />
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles.ProspectCardBodySec}`}>
                          <ul>
                            <li>
                              <p className={`${styles.Title}`}>Credit Score:</p>
                              <p className={`${styles.Content}`}>{item?.credit_score !== "" ? item?.credit_score : "-"}{" "} {parseInt(item?.is_credit_score_verify) === 1 && (<img src="/static/img/verified.svg" className={`${styles.Varified}`} alt="" />)}</p>
                            </li>
                            {item?.income_type === "Voucher" ? (<li>
                              <p className={`${styles.Title}`}>Voucher:</p>
                              <p className={`${styles.Content}`}>{item?.voucher_amount !== "" ? "$" + item?.formatted_voucher_amount : "-"}</p>
                            </li>) : (<li>
                              <p className={`${styles.Title}`}>Salary:</p>
                              <p className={`${styles.Content}`}>{item?.income_per_year !== "" ? "$" + item?.formatted_income_per_year : "-"}</p>
                            </li>)}
                            <li>
                              <p className={`${styles.Title}`}>Current Rent: </p>
                              <p className={`${styles.Content}`}>{item?.current_rent !== "" ? "$" + item?.formatted_current_rent : "-"}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}>Move-In Date:</p>
                              <p className={`${styles.Content}`}>{item?.move_in_date !== "" ? item?.formatted_move_in_date : "-"}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}>Property:</p>
                              <p className={`${styles.Content} ${styles.Hover}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); redirect.push("/properties/details/" + item?.property_id); }}>{item?.property_name && item?.property_name !== "" ? item?.property_name : "-"}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}>Unit:</p>
                              <p className={`${styles.Content}`}>{item?.apt_no && item?.apt_no !== "" ? item?.apt_no : "-"}</p>
                            </li>
                          </ul>
                        </div>
                        <div className={`${styles.ProspectCardFootSec}`}>
                          <p className={`${styles.ProsCardDate}`}>{item?.submitted_date_str}</p>
                          <button className={`${styles.ProsCardMailBU}`} onClick={(e) => openProspectConversation(e, item)}>
                            {item?.unread_no > 0 && (<span>{item?.unread_no}</span>)}
                            <Mail />
                          </button>
                        </div>
                      </div>
                    </Link>
                  </div>) : null;
                })}

                {prospectFilteredList.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={prospectFilteredList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: "4", value: 4 },
                      { label: "8", value: 8 },
                      { label: "16", value: 16 },
                      { label: "All", value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={"Prospects per Page:"}
                  />
                </div>
                )}

                {!contentLoading && prospectFilteredList?.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-prospect.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Let's get started!</p>
                  <p className={`${styles.NoDataTextSub}`}>Prospect List is Currently Empty.</p>
                </div>)}
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>

    <Dialog
      open={openDeleteModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => {
        setOpenDeleteModal(false);
        setDeleteId(0);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete the Prospect Record?
        <button onClick={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Prospect Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => { deleteConfirm(); }}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={archiveModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setArchiveModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to {isArchived ? "unarchive" : "archive"} the Prospect Record?
        <button onClick={(e) => setArchiveModalOpen(false)}> <X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">{isArchived ? "The Prospect Record will be unarchived immediately. You can archive it at any time." : "The Prospect Record will be archived immediately. You can unarchive it at any time."}</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => archiveConfirm()}>{isArchived ? "Unarchive" : "Archive"}</Button>
        <Button className="SubmitPopupBU" onClick={(e) => setArchiveModalOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openAddProspectModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => { setOpenAddProspectModal(false); }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      {addFormStep === 1 && (<StepOneForm
        setOpenAddProspectModal={setOpenAddProspectModal.bind(this)}
        onSave={onSubmitOne.bind(this)}
        selectedProperty={0}
      />)}
      {addFormStep === 2 && (<StepTwoForm
        setOpenAddProspectModal={setOpenAddProspectModal.bind(this)}
        onSave={onSubmitTwo.bind(this)}
        onSubmit={onAddProspectSubmit.bind(this)}
        isTourOption={true}
      />)}
      {addFormStep === 3 && (<StepThreeForm
        setOpenAddProspectModal={setOpenAddProspectModal.bind(this)}
        onSubmit={onAddProspectSubmit.bind(this)}
        prospect_name={stepOneData?.prospect_name}
        property_id={stepOneData?.property_id}
      />)}
    </Dialog>

    <Dialog
      open={applicationModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setApplicationModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to send application link?
        <button onClick={(e) => setApplicationModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">Send a application link via SMS. This link will allow to continue application.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => sendApplicationLInk(e)} className="CancelPopupBU">Confirm</Button>
        <Button onClick={(e) => setApplicationModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openLeaderBoardModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenLeaderBoardModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to add to leader board?
        <button onClick={(e) => setOpenLeaderBoardModal(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Prospect Record will be added to leader board immediately. You can remove from leader board it at any time.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpenLeaderBoardModal(false)} className="CancelPopupBU">Cancel</Button>
        <Button onClick={(e) => addToLeaderBoard(e)} className="SubmitPopupBU">Confirm</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openCallModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenCallModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Prospect Call Confirmation
        <button onClick={(e) => setOpenCallModal(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">Please confirm you wish to initiate a call to this prospect.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpenCallModal(false)} className="CancelPopupBU">Cancel</Button>
        <Button onClick={(e) => initiateVoiceCallConfirm()} className="SubmitPopupBU">Proceed</Button>
      </DialogActions>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(ProspectList, {
  onRedirecting: () => <Loader />,
});