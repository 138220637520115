import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import './sidebar.css';
import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Chip, Grid, Avatar, Collapse, Drawer as MuiDrawer, List as MuiList, ListItem, ListItemText, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { sidebarRoutes as routes } from "../routes/index";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;



const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
    darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarCategory = ({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) => {
  return (<Category {...rest}>
    {icon}
    <CategoryText>{name}</CategoryText>
    {isCollapsable ? (isOpen ? (<CategoryIconMore />) : (<CategoryIconLess />)) : null}
    {badge ? <CategoryBadge label={badge} /> : ""}
  </Category>);
};

const SidebarLink = ({ name, to, badge, icon, alternatePaths }) => {
  return (<Link button dense component={NavLink} exact to={to} activeClassName="active" isActive={(match, location) => {
    const matches = alternatePaths.filter(s => location.pathname.includes(s));
    if (!match && matches.length === 0)
      return false;
    return true
  }}>
    <LinkText>{name}</LinkText>
    {badge ? <LinkBadge label={badge} /> : ""}
  </Link>);
};

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const { user, isAuthenticated } = useAuth0();
  const [userImage, setuserImage] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      setuserImage(user.picture);
      if (user['https://propertydek.com/connection'] === 'google-oauth2') {
        user['https://propertydek.com/identities'].map(item => {
          if (item.provider === 'google-oauth2') {
            setuserImage(item?.profileData?.picture);
          }
          return item;
        });
      }
    }
  }, [isAuthenticated, setuserImage, user]);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach((item) =>
      openRoutes[index] ||
      setOpenRoutes((openRoutes) =>
        Object.assign({}, openRoutes, { [item]: false })
      )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    < Drawer variant="permanent" {...rest}>
      {/* <Brand component={NavLink} to="/" button>
        <BrandIcon />{" "}
        <Box ml={1}>
          Material App <BrandChip label="PRO" />
        </Box>
      </Brand> */}
      < SidebarFooter id="noPadding" className="MenuAvatar" >
        <Grid id="avataarBack" container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar className="SidebarAvatar"
                alt={isAuthenticated ? user.name : ''}
                src={isAuthenticated ? userImage : ''}
              />
            </SidebarFooterBadge>
          </Grid>
          <Grid item className="avataarMainNameWrap">
            <span className="avataarMainNameText">{isAuthenticated ? user.given_name : ''}</span>
          </Grid>
        </Grid>
      </SidebarFooter >
      <Scrollbar className="SideNav">
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{index}{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    {((isAuthenticated && (!category.permission.length || ((category.permission.filter(i => user['https://propertydek.com/permissions'].indexOf(i) > -1)).length > 0))) ? <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    /> : null)}

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index) => (
                        ((isAuthenticated && (!route.permission.length || ((route.permission.filter(i => user['https://propertydek.com/permissions'].indexOf(i) > -1)).length > 0))) ? <SidebarLink
                          key={index}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                          alternatePaths={route.alternatePaths}
                        /> : null)
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  ((isAuthenticated && (!category.permission.length || ((category.permission.filter(i => user['https://propertydek.com/permissions'].indexOf(i) > -1)).length > 0))) ? <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                    isActive={(match, location) => {
                      const matches = category.alternatePaths.filter(s => location.pathname.includes(s));
                      if (!match && matches.length === 0)
                        return false;
                      return true
                    }}
                  /> : null)
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer >
  );
};

export default withRouter(Sidebar);
