import React from "react";
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import styles from './headerpublic.module.css';
import { Link, scroller } from 'react-scroll'
import { Menu } from 'react-feather';
import { Link as Linkto } from "react-router-dom";
import 'react-sticky-header/styles.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: ''
    };
  }

  componentDidMount() {
    if (this.props.location.hash !== '') {
      this.scrollTo(this.props.location.hash);
    }
  }

  scrollTo(id) {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })

  }

  toggleMenu() {
    let isOpen = this.state.isMenuOpen;
    if (isOpen === '') {
      isOpen = 'Open';
    } else {
      isOpen = '';
    }
    this.setState({ isMenuOpen: isOpen })
  }

  render() {
    return (<div className={`${styles.Header}`}>
      <div className={`${styles.Container}`}>
        <div className={`${styles.HeadRow}`}>
          <div className={`${styles.Logo}`}>
            <Link to="/"><img src="/static/img/logo.svg" alt="logo" /></Link>
          </div>
          <div className={`${styles.RightSec}`}>
            <div className={(this.state.isMenuOpen === '') ? `${styles.MenuArea}` : `${styles.MenuArea} ${styles.Open}`} >
              <ul>
                <li><Link activeclass={`${styles.active}`} to="#HomeSec">Home</Link></li>
                <li><Link activeclass={`${styles.active}`} to="#features">Features</Link></li>
                <li><Link activeclass={`${styles.active}`} to="#blogs">Blog</Link></li>
                {/* <li><Linkto to={{ pathname: "https://fbc.nyc/" }} target="_blank">Company</Linkto></li> */}
                <li><Linkto activeClass={`${styles.active}`} to={'/contact-us'}>Contact Us</Linkto></li>
              </ul>
              <Linkto target="_blank" to={'/login'} className={`${styles.ContactLinkHead}`}>Log in</Linkto>
              {/* <Linkto to={'/dashboard'} className={`${styles.ContactBUHead}`} target="_blank">Sign up</Linkto> */}
            </div>
            <Linkto className={`${styles.MenuHamBU}`} onClick={this.toggleMenu.bind(this)} ><Menu /></Linkto>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default withRouter(withAuth0(Header));