import React, { useEffect, useState } from "react";
import styles from "../prospectdetails.module.css";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TablePagination } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";
import { ChevronDown, ChevronUp, Code } from "react-feather";
import Loader from "../../../components/Loader";
import { TableLoader } from "../../../components/LoaderC";

const ConnectedAccountsTab = ({ prospect, setIsConnectAccountModalOpen, setSnackbarMsg, setSnackbarOpen }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [currentAccountId, setCurrentAccountId] = useState(0);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [allTransactionList, setAllTransactionList] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [tranFilterType, setTranFilterType] = useState({ credit: true, debit: true });
    const [sortItem, setSortItem] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [contentLoading, setContentLoading] = useState(true);
    const [isAccounChange, setIsAccounChange] = useState(false);

    useEffect(() => {
        const getAccountList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/fc-account-list/" + prospect?.id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let accList = response.data.data;
                if (accList.length > 0) {
                    setAccountList(response.data.data);
                    setCurrentAccountId(accList[0]?.id);
                } else {
                    setContentLoading(false);
                }
            }).catch((error) => {
                setContentLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }

        getAccountList();
    }, [prospect, getAccessTokenSilently]);

    useEffect(() => {
        const getTransactionList = async () => {
            if (isAccounChange) {
                setLoading(true);
            }
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/fc-account-transaction-list/" + currentAccountId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setContentLoading(false);
                setLoading(false);
                setAllTransactionList(response.data.data);
            }).catch((error) => {
                setContentLoading(false);
                setLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        }

        if (currentAccountId > 0) {
            let selAccount = accountList?.filter(i => i?.id === currentAccountId);
            if (selAccount.length > 0) {
                setCurrentAccount(selAccount[0]);
                getTransactionList();
            }
        }
    }, [currentAccountId, isAccounChange, accountList, getAccessTokenSilently]);

    useEffect(() => {
        let transactionListTemp = allTransactionList;

        if (tranFilterType?.credit && tranFilterType?.debit) {
            transactionListTemp = allTransactionList;
        } else if (tranFilterType?.credit) {
            transactionListTemp = allTransactionList?.filter(i => i?.type === 'credit');
        } else if (tranFilterType?.debit) {
            transactionListTemp = allTransactionList?.filter(i => i?.type === 'debit');
        } else {
            transactionListTemp = [];
        }

        setTransactionList(transactionListTemp);
    }, [allTransactionList, tranFilterType]);

    const changeSortOrder = (type) => {
        if (type === sortItem) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else {
                setSortOrder('asc');
            }
        } else {
            setSortOrder('asc');
        }
        setSortItem(type);
    }

    return (<>
        {loading && <Loader />}

        {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

        {!currentAccount && !contentLoading && <>
            <div className={`${styles.NotesRow}`}>
                <div className={`${styles.NoDataMain}`}>
                    <div className={`${styles.NoDataIMG}`}>
                        <img src="/static/img/unlink.png" alt="" />
                    </div>
                    <p className={`${styles.NoDataText}`}>Bank Account Not Connected!</p>
                    <p className={`${styles.NoDataTextSub}`}>Account statements will appear here when it is connected.</p>
                    <button className={`${styles.AddLeaseBU}`} onClick={(e) => setIsConnectAccountModalOpen(true)}>Connect Account</button>
                </div>
            </div>
        </>}

        {currentAccount && <>
            {!contentLoading && (<>
                <div className={`${styles.DahhboardHead}`}>
                    <div className={`${styles.SwitchDiv} FormGroup Filter`}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Account</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Account"
                                value={currentAccountId}
                                onChange={(e) => {
                                    setIsAccounChange(true);
                                    setCurrentAccountId(e.target.value);
                                }}
                            >
                                {accountList?.map(i => {
                                    // return <MenuItem value={i?.id}>{i.display_name} - {i.institution_name} - {i.last4}</MenuItem>
                                    return <MenuItem value={i?.id}>{i.institution_name} - {i.last4}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={`${styles.AccBalanceSec}`}>
                        <div>
                            <p className={`${styles.AccBalanceTT}`}>Current Balance</p>
                            <p className={`${styles.AccBalanceAmount}`}>${currentAccount?.current_balance_str}</p>
                        </div>
                        <div>
                            <p className={`${styles.AccBalanceTT}`}>Available Balance</p>
                            <p className={`${styles.AccBalanceAmount}`}>${currentAccount?.available_balance_str}</p>
                        </div>
                    </div>
                    <Dropdown className={`${styles.AccountDropdown}`}>
                        <Dropdown.Toggle className={`${styles.ActionsBULine} ActionsBULine`} id="dropdown-basic">
                            Show: All <ChevronDown />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <FormGroup row className={`${styles.CheckFilter}`}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="All"
                                            color="primary"
                                            indeterminate={!(tranFilterType?.credit && tranFilterType?.debit) && (tranFilterType?.credit || tranFilterType?.debit)}
                                            checked={tranFilterType?.credit && tranFilterType?.debit}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setTranFilterType({ credit: true, debit: true });
                                                } else {
                                                    setTranFilterType({ credit: false, debit: false });
                                                }
                                            }}
                                        />
                                    }
                                    label="All"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="credit"
                                            color="primary"
                                            checked={tranFilterType?.credit}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setTranFilterType(prev => {
                                                        return { ...prev, credit: true };
                                                    });
                                                } else {
                                                    setTranFilterType(prev => {
                                                        return { ...prev, credit: false };
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    label="Credits"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="debit"
                                            color="primary"
                                            checked={tranFilterType?.debit}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setTranFilterType(prev => {
                                                        return { ...prev, debit: true };
                                                    });
                                                } else {
                                                    setTranFilterType(prev => {
                                                        return { ...prev, debit: false };
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    label="Debits"
                                />
                            </FormGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className={`${styles.AccountHolderDesSec}`}>
                    <p className={`${styles.AccountHolderTitle}`}>Account Holder</p>
                    {currentAccount?.owners?.map(i => {
                        return (<div className={`${styles.AccountHolderDesRow}`}>
                            <div className={`${styles.DesSmallSec}`}>
                                <p><span>Name:</span> {i?.ownership_name}</p>
                            </div>
                            <div className={`${styles.DesSmallSec}`}>
                                <p><span>Phone:</span> {i?.ownership_phone}</p>
                            </div>
                            <div className={`${styles.DesBigSec}`}>
                                <p><span>Address:</span> {i?.ownership_address}</p>
                            </div>
                        </div>)
                    })}
                </div>
                <div className={`${styles.ProspectTaskTable} ${styles.Two} ProspectTaskTable`}>
                    {transactionList?.length > 0 && <table>
                        <tr>
                            <th onClick={(e) => changeSortOrder('description')}>
                                Transactions Name
                                {sortItem === 'description' && sortOrder === 'asc' && <ChevronDown />}
                                {sortItem === 'description' && sortOrder === 'desc' && <ChevronUp />}
                                {sortItem !== 'description' && <Code className={`${styles.Normal}`} />}
                            </th>
                            <th onClick={(e) => changeSortOrder('type')}>
                                Type
                                {sortItem === 'type' && sortOrder === 'asc' && <ChevronDown />}
                                {sortItem === 'type' && sortOrder === 'desc' && <ChevronUp />}
                                {sortItem !== 'type' && <Code className={`${styles.Normal}`} />}
                            </th>
                            <th onClick={(e) => changeSortOrder('amount')}>
                                Amount
                                {sortItem === 'amount' && sortOrder === 'asc' && <ChevronDown />}
                                {sortItem === 'amount' && sortOrder === 'desc' && <ChevronUp />}
                                {sortItem !== 'amount' && <Code className={`${styles.Normal}`} />}
                            </th>
                            <th onClick={(e) => changeSortOrder('transacted_at')}>
                                Date
                                {sortItem === 'transacted_at' && sortOrder === 'asc' && <ChevronDown />}
                                {sortItem === 'transacted_at' && sortOrder === 'desc' && <ChevronUp />}
                                {sortItem !== 'transacted_at' && <Code className={`${styles.Normal}`} />}
                            </th>
                        </tr>
                        {transactionList?.sort((a, b) => {
                            if (sortItem === 'description') {
                                if (sortOrder === 'desc') {
                                    return a.description < b.description ? 1 : -1;
                                } else {
                                    return a.description > b.description ? 1 : -1;
                                }
                            } else if (sortItem === 'type') {
                                if (sortOrder === 'desc') {
                                    return a.type < b.type ? 1 : -1;
                                } else {
                                    return a.type > b.type ? 1 : -1;
                                }
                            } else if (sortItem === 'amount') {
                                if (sortOrder === 'desc') {
                                    return a.amount < b.amount ? 1 : -1;
                                } else {
                                    return a.amount > b.amount ? 1 : -1;
                                }
                            } else if (sortItem === 'transacted_at') {
                                if (sortOrder === 'desc') {
                                    return a.transacted_at < b.transacted_at ? 1 : -1;
                                } else {
                                    return a.transacted_at > b.transacted_at ? 1 : -1;
                                }
                            } else {
                                return a.transacted_at < b.transacted_at ? 1 : -1;
                            }
                        })?.map((item, index) => {
                            return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<tr>
                                <td>
                                    <p className={`${styles.TableSmallTitle}`}>Transactions Name</p>
                                    <p className={`${styles.Des}`}>{item?.description}</p>
                                </td>
                                <td>
                                    <p className={`${styles.TableSmallTitle}`}>Type</p>
                                    {item?.type === 'credit' && <p className={`${styles.Credit}`}>Credit</p>}
                                    {item?.type === 'debit' && <p>Debit</p>}
                                </td>
                                <td>
                                    <p className={`${styles.TableSmallTitle}`}>Amount</p>
                                    <p>${item?.amount_str}</p>
                                </td>
                                <td>
                                    <p className={`${styles.TableSmallTitle}`}>Date</p>
                                    <p>{item?.time_str}</p>
                                </td>
                            </tr>) : null;
                        })}

                        <div className="Pagination TablePaginationNew List">
                            <TablePagination
                                count={transactionList.length}
                                color="primary"
                                page={page}
                                rowsPerPage={perPage}
                                onPageChange={(e, v) => setPage(v)}
                                rowsPerPageOptions={[
                                    { label: "10", value: 10 },
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "All", value: -1 },
                                ]}
                                onRowsPerPageChange={(e) => {
                                    setPage(0);
                                    setPerPage(e.target.value);
                                }}
                                labelRowsPerPage={"Transactions per Page:"}
                            />
                        </div>
                    </table>}
                    {transactionList?.length === 0 && (<div className={`${styles.NotesRow}`}>
                        <div className={`${styles.NoDataMain}`}>
                            <div className={`${styles.NoDataIMG}`}>
                                <img src="/static/img/bank-icon.svg" alt="" />
                            </div>
                            <p className={`${styles.NoDataText}`}>Nothing to see here!</p>
                            <p className={`${styles.NoDataTextSub}`}>Statements will appear once available.</p>
                        </div>
                    </div>)}
                </div>
            </>)}
        </>}
    </>)
}

export default ConnectedAccountsTab;