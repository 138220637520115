import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import NotesTab from "./NotesTab";
import ProspectTab from "./ProspectTab";
import TaskTab from "./TaskTab";
import { useAuth0 } from "@auth0/auth0-react";
import ConnectedAccountsTab from "./ConnectedAccountsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SimpleTabs = (props) => {
  const { user, isAuthenticated } = useAuth0();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={props.tabValue}
          onChange={props.handleTabChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab key={0} value={0} label="Tasks" {...a11yProps(0)} />
          {props?.prospectData?.all_applicants?.map((item, index) => {
            if (item?.is_submitted === 1) {
              return <Tab key={index + 1} value={index + 1} label={item?.full_name} {...a11yProps(index + 1)} />;
            } else {
              return null;
            }
          })}

          {props?.prospectData?.all_applicants?.map((item, index) => {
            return <Tab key={index + 1} value={props?.prospectData?.all_applicants?.length + index + 1} label={props?.prospectData?.all_applicants?.length > 1 ? 'Conected Account for ' + item?.first_name : 'Conected Accounts'} {...a11yProps(props?.prospectData?.all_applicants?.length + index + 1)} />;
          })}

          {(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:read") > -1) && <Tab key={props?.prospectData?.all_applicants?.length + 2} value={(props?.prospectData?.all_applicants?.length * 2) + 1} label={"Notes"} {...a11yProps((props?.prospectData?.all_applicants?.length * 2) + 1)} />}
        </Tabs>
        <TabPanel value={props.tabValue} index={0} key={0}>
          <TaskTab
            prospectData={props?.prospectData}
            updateTourList={props.updateTourList.bind(this)}
            setAddTourModalOpen={props.setAddTourModalOpen.bind(this)}
            setEditTourData={props.setEditTourData.bind(this)}
            setSnackbarMsg={props?.setSnackbarMsg.bind(this)}
            setSnackbarOpen={props?.setSnackbarOpen.bind(this)}
            setTourTimeSlotError={props?.setTourTimeSlotError.bind(this)}
            updateMandatoryChecks={props?.updateMandatoryChecks.bind(this)}
          />
        </TabPanel>

        {props?.prospectData?.all_applicants?.map((item, index) => {
          if (item?.is_submitted === 1) {
            return <TabPanel value={props.tabValue} index={index + 1} key={index + 1}>
              <ProspectTab applicantData={item} apartment={props?.prospectData?.apartment} applicant_index={index} no_of_applicants={props?.prospectData?.no_of_applicants} updateFileList={props?.updateFileList.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} />
            </TabPanel>;
          } else {
            return null;
          }
        })}

        {props?.prospectData?.all_applicants?.map((item, index) => {
          return <TabPanel value={props.tabValue} index={props?.prospectData?.all_applicants?.length + index + 1} key={props?.prospectData?.all_applicants?.length + index + 1}>
            <ConnectedAccountsTab prospect={item} setIsConnectAccountModalOpen={props?.setIsConnectAccountModalOpen.bind(this)} setSnackbarMsg={props?.setSnackbarMsg.bind(this)} setSnackbarOpen={props?.setSnackbarOpen.bind(this)} />
          </TabPanel>;
        })}

        {(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-note:read") > -1) && <TabPanel
          value={props.tabValue}
          index={(props?.prospectData?.all_applicants?.length * 2) + 1}
          key={(props?.prospectData?.all_applicants?.length * 2) + 1}
        >
          <NotesTab
            addNoteData={props?.addNoteData.bind(this)}
            editNoteData={props?.editNoteData.bind(this)}
            deleteNoteData={props?.deleteNoteData.bind(this)}
            applicant_id={props?.prospectData?.applicant_id}
            setSnackbarMsg={props?.setSnackbarMsg.bind(this)}
            setSnackbarOpen={props?.setSnackbarOpen.bind(this)}
            prospectData={props?.prospectData}
          />
        </TabPanel>}
      </AppBar>
    </div>
  );
};

export default SimpleTabs;
