import React, { useEffect, useState } from "react";
import { Link as Linkto, useHistory } from "react-router-dom";
import axios from 'axios';
import dayjs from "dayjs";
import styles from './connect.module.css';
import { Helmet } from "react-helmet";
import { Background, Parallax } from "react-parallax";

const RescheduleSuccess = ({ match }) => {
    const { str } = match?.params;
    const redirect = useHistory();
    const [propertyDetails, setPropertyDetails] = useState(null);

    useEffect(() => {
        const fetchByID = async () => {
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/fc-account-details/' + str).then(response => {
                const resData = response.data;
                if (resData?.property) {
                    setPropertyDetails(resData?.property);
                } else {
                    redirect.push('/connect-invalid');
                }
            }).catch(error => {
                redirect.push('/connect-invalid');
            });
        }
        fetchByID();
    }, [str, redirect]);

    return (<React.Fragment>
        <Helmet title="Bank Account Connected!" />

        <Parallax strength={300} className="RentalParallax">
            <Background className="custom-bg">
                <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
            </Background>
        </Parallax>

        <div className="WhiteBG">

            <div className={`${styles.RentalAppliArea}`}>

                <div className={`${styles.RescheduleMessageSec}`}>
                    <img src="/static/img/reschedule-success.png" alt="" />
                    <p className={`${styles.MessageTitle}`}>Bank Account Connected!</p>
                    <p className={`${styles.MessageText}`}>Your bank account has been successfully connected.</p>
                </div>


            </div>

            <div className={`${styles.FooterWrap}`}>
                <div className={`${styles.footerMain}`}>

                    <div className={`${styles.footerMainLeft}`}>
                        <Linkto to={'/'}><img src={(propertyDetails?.app_theme_options && propertyDetails?.app_theme_options?.image_url !== '') ? propertyDetails?.app_theme_options?.image_url : '/static/img/logo.svg'} alt="logo" /></Linkto>
                    </div>

                    <div className={`${styles.footerMainRight}`}>
                        <div>
                            <h3>CONTACT US</h3>
                            <ul>
                                <li><a href={`mailto: ${propertyDetails?.email}`}>{propertyDetails?.email}</a></li>
                                <li><a href={`tel: ${propertyDetails?.phone}`} >{propertyDetails?.phone}</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3>FIND US</h3>
                            <ul>
                                <li>{propertyDetails?.address} <br />{propertyDetails?.city}, {propertyDetails?.state} {propertyDetails?.zip}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>LEGAL</h3>
                            <ul>
                                <li><Linkto to={'/privacy-policy'}>Privacy Policy</Linkto></li>
                                <li><Linkto to={'/eula'}>End User License Agreement</Linkto></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>{propertyDetails?.name} © {dayjs().format("YYYY")}.<br />All rights reserved.</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </React.Fragment>);
}

export default RescheduleSuccess;