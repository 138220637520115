import { useState } from "react";
import styles from "../tenantlist.module.css";
import { Menu, MenuItem, Typography } from "@mui/material";
import { ChevronDown } from "react-feather";
import { Link } from "react-router-dom";

const PeoplePageTitle = ({ defaultPage }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <Typography display="inline" className={`${styles.pageTitle} ${styles.Mobile}`} onClick={handleClick}>
            {defaultPage} <ChevronDown />
        </Typography>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem className={`${styles.DropdownPageTitleMenu}`}><Link to="/people/prospects">Prospect</Link></MenuItem>
            <MenuItem className={`${styles.DropdownPageTitleMenu}`}><Link to="/people/prospects/leaderboards">Leaderboard</Link></MenuItem>
            <MenuItem className={`${styles.DropdownPageTitleMenu}`}><Link to="/people/tenants">Tenant</Link></MenuItem>
            <MenuItem className={`${styles.DropdownPageTitleMenu}`}><Link to="/people/users">User</Link></MenuItem>
        </Menu>
    </>
    )
}

export default PeoplePageTitle;