import React, { useState, useEffect } from "react";
import styles from "./rentalapplicationsuccess.module.css";
import Helmet from "react-helmet";
import { Parallax, Background } from "react-parallax";
import axios from "axios";
import moment from "moment";
import { Link as Linkto } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";

export default function RentalApplicationSuccess({ history, match }) {
  const { id, id2 } = match.params;
  const redirect = useHistory();
  const [propertDetails, setpropertDetails] = useState([]);
  const [prospectDetails, setProspectDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/application/property-det/" + id).then((response) => {
      const resData = response.data.propertyDet;
      setpropertDetails(resData);
    }).catch((error) => { });
  }, [id]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospect-details/" + id2).then((response) => {
      setProspectDetails(response.data.prospect);
    }).catch((error) => { });
  }, [id2]);

  useEffect(() => {
    if (prospectDetails && prospectDetails?.is_verified === 0) {
      setShowPopup(true);
    }
  }, [prospectDetails]);

  const verifyNow = () => {
    redirect.push('/verify/' + id2);
  }

  return (<React.Fragment>
    <Helmet title="Rental Applicant Success" />

    <Parallax strength={300} className="RentalParallax">
      <Background className="custom-bg">
        <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
      </Background>
    </Parallax>

    <div className="WhiteBG">
      <div className={`${styles.RentalAppliArea}`}>
        <div className={`${styles.RentalAppliSuccessSec}`}>
          <img src="/static/img/success.png" alt="" className={`${styles.SuccessImg}`} />
          <p className={`${styles.PaymentSuccessTitle}`}>Thank You!</p>
          <p className={`${styles.PaymentSuccessTitleSub}`}>Your application and payment has been received. It will take up to 4 days to process your application.</p>
        </div>
      </div>

      <div className={`${styles.FooterWrap}`}>
        <div className={`${styles.footerMain}`}>
          <div className={`${styles.footerMainLeft}`}>
            <Linkto to={"/"}>
              <img src={propertDetails?.image !== "" ? propertDetails?.image : "/static/img/logo.svg"} alt="logo" />
            </Linkto>
          </div>

          <div className={`${styles.footerMainRight}`}>
            <div>
              <h3>CONTACT US</h3>
              <ul>
                <li>
                  <a href={`mailto: ${propertDetails.email}`}>{propertDetails.email}</a>
                </li>
                <li>
                  <a href={`tel: ${propertDetails.phone}`}>{propertDetails.phone}</a>
                </li>
              </ul>
            </div>
            <div>
              <h3>FIND US</h3>
              <ul>
                <li>
                  {propertDetails.address} <br />
                  {propertDetails.city}, {propertDetails.state}{" "}
                  {propertDetails.zip}
                </li>
              </ul>
            </div>
            <div>
              <h3>LEGAL</h3>
              <ul>
                <li>
                  <Linkto to={"/privacy-policy"}>Privacy Policy</Linkto>
                </li>
                <li>
                  <Linkto to={"/eula"}>End User License Agreement</Linkto>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  {propertDetails?.name ? propertDetails?.name : 'PropertyDek'} © {moment().format("YYYY")}.<br />
                  All rights reserved.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      open={showPopup}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setShowPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Secure Your Tour: ID Verification<button onClick={(e) => setShowPopup(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">To ensure property security and finalize your tour, we need to verify your identity. This is a standard security measure.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => setShowPopup(false)}>Remind Me Later</Button>
        <Button className="SubmitPopupBU" onClick={(e) => verifyNow()}>Verify Identity Now</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>);
}
