import React, { useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import { Bar } from "react-chartjs-2";
import styles from "../dashboard.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DashCardLoader } from "../../../../components/LoaderC";

const MonthlyCasesData = ({ selectedProperty }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [noData, setNoData] = useState(true);
    const [contentLoading, setContentLoading] = useState(true);
    const [monthlyCases, setMonthlyCases] = useState(null);

    const data = {
        labels: monthlyCases ? monthlyCases?.labels : [],
        datasets: [
            {
                label: "Maintenance",
                backgroundColor: "#502688",
                borderColor: "#4782da",
                hoverBackgroundColor: "#502688",
                hoverBorderColor: "#4782da",
                data: monthlyCases ? monthlyCases?.data?.maintenance_requests : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            },
            {
                label: "Complaints",
                backgroundColor: "#E99A37",
                borderColor: "#4782da",
                hoverBackgroundColor: "#E99A37",
                hoverBorderColor: "#4782da",
                data: monthlyCases ? monthlyCases?.data?.complaints : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                marginBottom: -50,
            },
            {
                label: "Violations",
                backgroundColor: "#E23725",
                borderColor: "rgba(0, 0, 0, 0.15)",
                hoverBackgroundColor: "#E23725",
                hoverBorderColor: "rgba(0, 0, 0, 0.15)",
                data: monthlyCases ? monthlyCases?.data?.violations : [],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        cornerRadius: 0,
        legend: {
            display: true,
            position: "bottom",
            labels: {
                padding: 30,
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: "transparent",
                    },
                    stacked: true,
                    ticks: {
                        stepSize: 50,
                        fontColor: "rgba(0, 0, 0, 0.54)",
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        color: "transparent",
                    },
                    ticks: {
                        fontColor: "rgba(0, 0, 0, 0.54)",
                    },
                },
            ],
        },
    };

    useEffect(() => {
        const fetchData = async (pId) => {
            setContentLoading(true);
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            if (pId > 0) {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-monthly-case-data/" + pId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setMonthlyCases(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                });
            } else {
                await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-monthly-case-data", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    setContentLoading(false);
                    setMonthlyCases(response.data.data);
                }).catch((error) => {
                    setContentLoading(false);
                });
            }
        }


        if (selectedProperty > 0) {
            fetchData(selectedProperty);
        } else {
            fetchData(0);
        }
    }, [selectedProperty, getAccessTokenSilently]);

    useEffect(() => {
        if (monthlyCases) {
            let isNoRequests = monthlyCases?.data?.maintenance_requests[0] === 0 && monthlyCases?.data?.maintenance_requests[1] === 0 && monthlyCases?.data?.maintenance_requests[2] === 0 && monthlyCases?.data?.maintenance_requests[3] === 0 && monthlyCases?.data?.maintenance_requests[4] === 0 && monthlyCases?.data?.maintenance_requests[5] === 0 && monthlyCases?.data?.maintenance_requests[6] === 0 && monthlyCases?.data?.maintenance_requests[7] === 0 && monthlyCases?.data?.maintenance_requests[8] === 0 && monthlyCases?.data?.maintenance_requests[9] === 0 && monthlyCases?.data?.maintenance_requests[10] === 0 && monthlyCases?.data?.maintenance_requests[11] === 0;

            let isNoComplaints = monthlyCases?.data?.complaints[0] === 0 && monthlyCases?.data?.complaints[1] === 0 && monthlyCases?.data?.complaints[2] === 0 && monthlyCases?.data?.complaints[3] === 0 && monthlyCases?.data?.complaints[4] === 0 && monthlyCases?.data?.complaints[5] === 0 && monthlyCases?.data?.complaints[6] === 0 && monthlyCases?.data?.complaints[7] === 0 && monthlyCases?.data?.complaints[8] === 0 && monthlyCases?.data?.complaints[9] === 0 && monthlyCases?.data?.complaints[10] === 0 && monthlyCases?.data?.complaints[11] === 0;

            let isNoViolations = monthlyCases?.data?.violations[0] === 0 && monthlyCases?.data?.violations[1] === 0 && monthlyCases?.data?.violations[2] === 0 && monthlyCases?.data?.violations[3] === 0 && monthlyCases?.data?.violations[4] === 0 && monthlyCases?.data?.violations[5] === 0 && monthlyCases?.data?.violations[6] === 0 && monthlyCases?.data?.violations[7] === 0 && monthlyCases?.data?.violations[8] === 0 && monthlyCases?.data?.violations[9] === 0 && monthlyCases?.data?.violations[10] === 0 && monthlyCases?.data?.violations[11] === 0;

            if (isNoRequests && isNoComplaints && isNoViolations) {
                setNoData(true);
            } else {
                setNoData(false);
            }
        } else {
            setNoData(true);
        }
    }, [monthlyCases]);

    return (<div className={`${styles.ChartFullDiv} Barchart`}>
        {contentLoading && (<div className={`${styles.LoaderDash}`}>
            <DashCardLoader />
        </div>)}

        {!contentLoading && (<div className={`${styles.IfnoCard}`}>
            <div className={`${styles.InsideCardTwo}`}>
                <div className={`${styles.BatteryHead}`}>
                    <h6 className={`${styles.BatteryCardTitle}`}>Monthly Cases</h6>
                </div>
            </div>

            <CardContent style={{ display: noData ? 'none' : 'block' }}>
                <div style={{ height: '340px', width: '100%' }}>
                    <Bar data={data} options={options} />
                </div>
            </CardContent>

            {noData && (<div className={`${styles.NoDataSec}`} style={{ height: '378px' }}>
                <div className={`${styles.NoDataIMG}`}><img src="/static/img/no-case.png" alt="" /></div>
                <p className={`${styles.NoDataText}`}>Hooray!</p>
                <p className={`${styles.NoDataTextSub}`}>Zero Cases Reported.</p>
            </div>)}
        </div>)}
    </div>);
};

export default MonthlyCasesData;
