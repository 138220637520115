import React, { useEffect, useState } from "react";
import styles from "../../propertydetails.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../../components/Loader";
import { Controller, useForm } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import axios from "axios";
import { AddLogCallAPI } from "../../../../components/AddLogs";
import { toast } from "react-smart-toaster";
import { Eye, EyeOff } from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TenDigitField(props) {
    return (<NumberFormat
        customInput={TextField}
        prefix=""
        displayType="input"
        type="text"
        thousandSeparator={false}
        decimalScale={0}
        {...props}
    />);
}

function MoneyField(props) {
    return (
        <NumberFormat
            customInput={TextField}
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={false}
            allowNegative={false}
            decimalScale={0}
            {...props}
        />
    );
}

function MoneyFieldTwo(props) {
    return (
        <NumberFormat
            customInput={TextField}
            prefix=""
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            {...props}
        />
    );
}

const schema = yup.object().shape({
    grace_period: yup.string().required("This field is Required").test("non-zero-validate", "This field should be 1 or greater.", (value) => parseInt(value) > 0).test("max-30", "This field can not be more than 30.", (value) => {
        return parseInt(value?.replace(/,/g, "")) <= 30;
    }),
    type_of_fee: yup.string().required("This field is Required"),
    fee_amount: yup.string().when("type_of_fee", (type_of_fee) => {
        if (type_of_fee && parseInt(type_of_fee) === 0)
            return yup.string().required("This field is Required").test("max-4-digit", "Amount can not be more than 6 digits.", (value) => {
                return parseFloat(value?.replace(/,/g, "")) < 10000;
            });
        else
            return yup.string().required("This field is Required").test("max-100", "Percentage can not be more than 100.", (value) => {
                return parseFloat(value?.replace(/,/g, "")) <= 100;
            });
    })
});

const passcodeRegExp = /\b\d{6}\b/;
const schema2 = yup.object().shape({
    doorlock_password: yup.string().required("This field is Required").matches(passcodeRegExp, { message: "Please enter 6 digit passcode", excludeEmptyString: true })
});

const schema3 = yup.object().shape({
    min_credit_score: yup.string().required("This field is Required").max(3, "This field can not be more than 3 digits.").test("non-zero-validate", "This field should be 1 or greater.", (value) => parseInt(value) > 0),
    min_income_multiplier: yup.string().required("This field is Required").max(1, "This field can not be more than 1 digits.").test("non-zero-validate", "This field should be 1 or greater.", (value) => parseInt(value) > 0),
});

const PaymentSetTab = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [lockViewType, setLockViewType] = useState('password');
    const [isPasscode, setIsPasscode] = useState(0);

    const { handleSubmit, control, errors, setValue, watch } = useForm({
        shouldUnregister: false,
        resolver: yupResolver(schema),
        defaultValues: {
            type_of_fee: ''
        }
    });

    const { handleSubmit: handleSubmit2, control: control2, errors: errors2, setValue: setValue2 } = useForm({
        shouldUnregister: false,
        resolver: yupResolver(schema2)
    });

    const { handleSubmit: handleSubmit3, control: control3, errors: errors3, setValue: setValue3 } = useForm({
        shouldUnregister: false,
        resolver: yupResolver(schema3)
    });

    useEffect(() => {
        if (props?.late_fee_data) {
            setValue('grace_period', props?.late_fee_data?.grace_period);
            setValue('type_of_fee', props?.late_fee_data?.type_of_fee);
            setValue('fee_amount', props?.late_fee_data?.fee_amount);
        }

        if (props?.doorlock_password && props?.doorlock_password !== '') {
            setIsPasscode(1);
            setValue2('doorlock_password', props?.doorlock_password);
        }

        if (props?.prospect_criteria) {
            setValue3('min_credit_score', props?.prospect_criteria?.min_credit_score);
            setValue3('min_income_multiplier', props?.prospect_criteria?.min_income_multiplier);
        }
    }, [props?.late_fee_data, props?.doorlock_password, props?.prospect_criteria, setValue, setValue2, setValue3]);

    const onSubmit = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        if (data?.fee_amount) {
            data = { ...data, fee_amount: data?.fee_amount?.replace(/[,%]/g, "") };
        }
        return axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-payment-settings", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(prev => {
                return { ...prev, is_auto_late_fee: response.data?.data?.is_auto_late_fee, grace_period: response.data?.data?.grace_period, type_of_fee: response.data?.data?.type_of_fee, fee_amount: response.data?.data?.fee_amount };
            });

            let logData = {
                title: "Payment settings are updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });

    }

    const onSubmit2 = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        return axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-lock-password", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(prev => {
                return { ...prev, doorlock_password: response.data?.data?.doorlock_password };
            });

            let logData = {
                title: "Door lock passcode is updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);

            axios.get(process.env.REACT_APP_DEVICE_API_URL + "v1/ttlock/update-all-passcode/" + props?.property_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const [expanded, setExpanded] = useState("panel1");
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onSubmit3 = async (data) => {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        setLoading(true);
        return axios.put(process.env.REACT_APP_PROPERTY_API_URL + "v1/properties/" + props?.property_id + "/update-prospect-criteria", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            props.setSnackbarMsg(response.data.message);
            props.setSnackbarOpen(true);

            props.setPropertyData(prev => {
                return { ...prev, min_credit_score: response.data?.data?.min_credit_score, min_income_multiplier: response.data?.data?.min_income_multiplier };
            });

            let logData = {
                title: "Prespect screening criteria is updated",
                description: ["Property: " + props?.property_name],
            };
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<>
        {loading && <Loader />}

        <div className={`${styles.SettingsTabSec} SettingsTabSec`}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <h5 className={`${styles.SettingsTitle}`}>Late Fee Settings</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ maxWidth: '300px' }}>
                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <Controller
                                        name={`grace_period`}
                                        control={control}
                                        render={(field) => (
                                            <MoneyField
                                                onChange={(e) => field.onChange(e)}
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Grace period*"
                                                value={field?.value}
                                                inputProps={{ maxLength: 2 }}
                                            />
                                        )}
                                    />
                                    {errors.grace_period && (<p className={`${styles.ErrorM}`}>{errors.grace_period.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Fee Type*</InputLabel>
                                        <Controller
                                            name="type_of_fee"
                                            control={control}
                                            render={(field) => (
                                                <Select
                                                    {...field}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Fee Type"
                                                    onChange={(e) => {
                                                        field.onChange(e);

                                                        setValue('fee_amount', '');
                                                    }}
                                                >
                                                    <MenuItem value={0}>Fixed Amount</MenuItem>
                                                    <MenuItem value={1}>% of Outstanding Balance</MenuItem>
                                                    <MenuItem value={2}>% of Rent Bill</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    {errors.type_of_fee && (<p className={`${styles.ErrorM}`}>{errors.type_of_fee.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <Controller
                                        name={`fee_amount`}
                                        control={control}
                                        render={(field) => (
                                            <MoneyFieldTwo
                                                onChange={(e) => field.onChange(e)}
                                                id="outlined-basic"
                                                variant="outlined"
                                                label={watch('type_of_fee') === 0 ? "Amount*" : "Percentage*"}
                                                value={field?.value}
                                                fixedDecimalScale={watch('type_of_fee') === 0}
                                                suffix={watch('type_of_fee') === 0 ? '' : '%'}
                                            />
                                        )}
                                    />
                                    {errors.fee_amount && (<p className={`${styles.ErrorM}`}>{errors.fee_amount.message}</p>)}
                                </div>
                            </div>

                            <div className={`${styles.ButtonDiv}`}>
                                <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                            </div>
                        </div>
                    </form>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    <h5 className={`${styles.SettingsTitle}`}>Master Passcode for Smart Door Lock</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit2(onSubmit2)}>
                        <div style={{ maxWidth: '300px' }}>
                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <Controller
                                        name={`doorlock_password`}
                                        control={control2}
                                        render={(field) => (
                                            <NumberFormat
                                                {...field}
                                                customInput={TextField}
                                                label="Master Password*"
                                                id="outlined-basic"
                                                variant="outlined"
                                                prefix=""
                                                displayType="input"
                                                type={lockViewType}
                                                thousandSeparator={false}
                                                allowNegative={false}
                                                decimalScale={0}
                                                inputProps={{ maxLength: 6 }}
                                                disabled={isPasscode === 1}
                                            />
                                        )}
                                    />
                                    {lockViewType === "password" ? <Eye onClick={(e) => setLockViewType('text')} className={`${styles.eyeIcon}`} /> : <EyeOff onClick={(e) => setLockViewType('password')} className={`${styles.eyeIcon}`} />}
                                    {/* <button type="button" onClick={(e) => { setValue2('doorlock_password', ''); setIsPasscode(0); }}>Click to update</button> */}
                                    {errors2.doorlock_password && (<p className={`${styles.ErrorM}`}>{errors2.doorlock_password.message}</p>)}
                                </div>
                            </div>

                            <div>
                                {isPasscode === 0 && <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>}
                                {isPasscode === 1 && <button type="button" onClick={(e) => { setValue2('doorlock_password', ''); setIsPasscode(0); }} className={`${styles.ButtonVoiletSolid}`}>Click to update</button>}
                            </div>
                        </div>
                    </form >
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    <h5 className={`${styles.SettingsTitle}`}>Prospect Screening Criteria</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit3(onSubmit3)}>
                        <div style={{ maxWidth: '300px' }}>
                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <Controller
                                        name={`min_credit_score`}
                                        control={control3}
                                        render={(field) => (
                                            <TenDigitField format="###" value={field.value} onChange={(e) => field.onChange(e)} label="Minimum Credit Card" variant="outlined" />
                                        )}
                                    />
                                    {errors3.min_credit_score && (<p className={`${styles.ErrorM}`}>{errors3?.min_credit_score?.message}</p>)}
                                </div>
                            </div>
                            <div className={`${styles.FormRow}`}>
                                <div className={`${styles.FormGroupFullPay} FormGroup`}>
                                    <Controller
                                        name={`min_income_multiplier`}
                                        control={control3}
                                        render={(field) => (
                                            <TenDigitField format="#" value={field.value} onChange={(e) => field.onChange(e)} label="Income Multiple" variant="outlined" />
                                        )}
                                    />
                                    {errors3.min_income_multiplier && (<p className={`${styles.ErrorM}`}>{errors3?.min_income_multiplier?.message}</p>)}
                                </div>
                            </div>

                            <div>
                                <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                            </div>
                        </div>
                    </form >
                </AccordionDetails>
            </Accordion>
        </div>

        {/* <div className={`${styles.Permission}`}>
            <h5>Late Fee Settings</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ maxWidth: '260px' }}>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFullPay} FormGroup`}>
                            <Controller
                                name={`grace_period`}
                                control={control}
                                render={(field) => (
                                    <MoneyField
                                        onChange={(e) => field.onChange(e)}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Grace period*"
                                        value={field?.value}
                                        inputProps={{ maxLength: 2 }}
                                    />
                                )}
                            />
                            {errors.grace_period && (<p className={`${styles.ErrorM}`}>{errors.grace_period.message}</p>)}
                        </div>
                    </div>

                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFullPay} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Fee Type*</InputLabel>
                                <Controller
                                    name="type_of_fee"
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Fee Type"
                                            onChange={(e) => {
                                                field.onChange(e);

                                                setValue('fee_amount', '');
                                            }}
                                        >
                                            <MenuItem value={0}>Fixed Amount</MenuItem>
                                            <MenuItem value={1}>% of Outstanding Balance</MenuItem>
                                            <MenuItem value={2}>% of Rent Bill</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            {errors.type_of_fee && (<p className={`${styles.ErrorM}`}>{errors.type_of_fee.message}</p>)}
                        </div>
                    </div>

                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFullPay} FormGroup`}>
                            <Controller
                                name={`fee_amount`}
                                control={control}
                                render={(field) => (
                                    <MoneyFieldTwo
                                        onChange={(e) => field.onChange(e)}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label={watch('type_of_fee') === 0 ? "Amount*" : "Percentage*"}
                                        value={field?.value}
                                        fixedDecimalScale={watch('type_of_fee') === 0}
                                        suffix={watch('type_of_fee') === 0 ? '' : '%'}
                                    />
                                )}
                            />
                            {errors.fee_amount && (<p className={`${styles.ErrorM}`}>{errors.fee_amount.message}</p>)}
                        </div>
                    </div>

                    <div className={`${styles.ButtonDiv}`}>
                        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                    </div>
                </div>
            </form>
        </div > */}

        {/* <div className={`${styles.Permission}`}>
            <h5>Master Passcode For Doorlock</h5>
            <form onSubmit={handleSubmit2(onSubmit2)}>
                <div style={{ maxWidth: '260px' }}>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFullPay} FormGroup`}>
                            <Controller
                                name={`doorlock_password`}
                                control={control2}
                                render={(field) => (
                                    <NumberFormat
                                        {...field}
                                        customInput={TextField}
                                        label="Master Password*"
                                        id="outlined-basic"
                                        variant="outlined"
                                        prefix=""
                                        displayType="input"
                                        type={lockViewType}
                                        thousandSeparator={false}
                                        allowNegative={false}
                                        decimalScale={0}
                                        inputProps={{ maxLength: 6 }}
                                        disabled={isPasscode === 1}
                                    />
                                )}
                            />
                            {lockViewType === "password" ? <Eye onClick={(e) => setLockViewType('text')} /> : <EyeOff onClick={(e) => setLockViewType('password')} />}
                            <button type="button" onClick={(e) => { setValue2('doorlock_password', ''); setIsPasscode(0); }}>Click to update</button>
                            {errors2.doorlock_password && (<p className={`${styles.ErrorM}`}>{errors2.doorlock_password.message}</p>)}
                        </div>
                    </div>

                    <div className={`${styles.ButtonDiv}`}>
                        <button type="submit" className={`${styles.ButtonVoiletSolid}`}>Update</button>
                    </div>
                </div>
            </form >
        </div > */}
    </>)
}

export default PaymentSetTab;