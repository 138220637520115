import { Link as Linkto } from "react-router-dom";
import moment from 'moment';
import styles from './connect.module.css';
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Background, Parallax } from "react-parallax";

const RescheduleInvalid = ({ history, match }) => {
    const propertyDetails = useState(null);

    return (<React.Fragment>
        <Helmet title="Invalid Link Of Financial Connections" />

        <Parallax strength={300} className="RentalParallax">
            <Background className="custom-bg">
                <img src="/static/img/rental-app-bg.jpg" alt="fill murray" />
            </Background>
        </Parallax>

        <div className="WhiteBG">

            <div className={`${styles.RentalAppliArea}`}>

                <div className={`${styles.RescheduleMessageSec}`}>
                    <img src="/static/img/reschedule-invalid.png" alt="" />
                    <p className={`${styles.MessageTitle}`}>Invalid Link</p>
                    <p className={`${styles.MessageText}`}>The link you entered may be incorrect or the page may no longer exist.</p>
                </div>


            </div>

            <div className={`${styles.FooterWrap}`}>
                <div className={`${styles.footerMain}`}>

                    <div className={`${styles.footerMainLeft}`}>
                        <Linkto to={'/'}><img src={'/static/img/logo.svg'} alt="logo" /></Linkto>
                    </div>

                    <div className={`${styles.footerMainRight}`}>
                        <div>
                            <h3>CONTACT US</h3>
                            <ul>
                                <li><a href={`mailto: ${propertyDetails?.email}`}>{propertyDetails?.email}</a></li>
                                <li><a href={`tel: ${propertyDetails?.phone}`} >{propertyDetails?.phone}</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3>FIND US</h3>
                            <ul>
                                <li>{propertyDetails?.address} <br />{propertyDetails?.city}, {propertyDetails?.state} {propertyDetails?.zip}</li>
                            </ul>
                        </div>
                        <div>
                            <h3>LEGAL</h3>
                            <ul>
                                <li><Linkto to={'/privacy-policy'}>Privacy Policy</Linkto></li>
                                <li><Linkto to={'/eula'}>End User License Agreement</Linkto></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>PropertyDek © {moment().format("YYYY")}.<br />All rights reserved.</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </React.Fragment>)
}

export default RescheduleInvalid;