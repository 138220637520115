import { Dropdown } from "react-bootstrap";
import styles from "../leaderboard.module.css";
import { MoreVertical, PhoneCall, Slash, X } from "react-feather";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useDispatch, useSelector } from "react-redux";
import { communicationVoiceCallScreenOpen } from "../../../../redux/actions/communicationVoiceCallScreenAction";
import { setVoiceCallInfo } from "../../../../redux/actions/communicationVoiceCallInfo";

const ProspectCard = ({ item, setLoading, setLeaderboards }) => {
    const voiceCallInfo = useSelector((store) => store.communicationVoiceCallInfoReducer);
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [openLeaderBoardModal, setOpenLeaderBoardModal] = useState(false);
    const [openCallModal, setOpenCallModal] = useState(false);
    const [prospectInfoObj, setProspectInfoObj] = useState(null);

    const removeFromLeaderBoard = async () => {
        setLoading(true);
        setOpenLeaderBoardModal(false);

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/remove-from-leaderboard/" + item?.applicant_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            setLeaderboards(prev => {
                return prev?.filter(i => i?.applicant_id !== item?.applicant_id);
            })

        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const initiateVoiceCall = (item) => {
        if (voiceCallInfo) {
            toast.error("You are on anotehr call.");
        } else {
            setOpenCallModal(true);
            setProspectInfoObj(item);
        }
    }
    const initiateVoiceCallConfirm = () => {
        setOpenCallModal(false);
        let newProspectInfo = { id: prospectInfoObj?.applicant_id, full_name: prospectInfoObj?.full_name, initial: prospectInfoObj?.initial, phone: prospectInfoObj?.phone, user_avatar: prospectInfoObj?.user_avatar };
        dispatch(communicationVoiceCallScreenOpen(true));
        dispatch(setVoiceCallInfo(newProspectInfo));
    }

    return (<>
        <Link to={`/people/prospects/details/${item.applicant_id}`}>
            <div className={`${styles.ProspectCardHeadSectionSS}`}>
                <div className={`${styles.ProsIMGSec}`}>
                    {(item?.user_avatar !== "") ? <img src={item?.user_avatar} className={`${styles.defaultImg}`} alt="" /> : ((item?.initial !== "?") ? <p>{item?.initial}</p> : <img src="/static/img/DefaultLogo.png" className={`${styles.defaultImg}`} alt="" />)}
                </div>
                <div className={`${styles.HeadConSec}`}>
                    <div className={`${styles.prospectNameActionSec}`}>
                        <p className={`${styles.ProsUserName} ${styles.LimitName}`}> {item?.full_name !== "" ? item?.full_name : "-"} </p>
                        {item?.current_status === 0 && (<span className={`${styles.ProspectNonAppliedBadge}`}>Not Applied</span>)}
                        {item?.current_status === 1 && (<span className={`${styles.ProspectAppliedBadge}`}>Applied</span>)}
                        {item?.current_status === 2 && (<span className={`${styles.ProspectScreenedBadge}`}>Screened</span>)}
                        {item?.current_status === 3 && (<span className={`${styles.ProspectApprovedBadge}`}>Approved</span>)}
                        {item?.current_status === 4 && (<span className={`${styles.ProspectDeclinedBadge}`}>Declined</span>)}
                        <Dropdown onClick={(e) => { e.preventDefault(); }}>
                            <Dropdown.Toggle className={`${styles.ActionsBU} ProsDrBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>

                            <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => setOpenLeaderBoardModal(true)}><Slash /> Remove from Leaderboard</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className={`${styles.prospectNameActionSec}`}>
                        <Tooltip title="Start a voice call">
                            <p className={`${styles.ProsUserName} ${styles.CallLink}`} onClick={(e) => { e.preventDefault(); initiateVoiceCall(item); }}>{item?.phone}</p>
                        </Tooltip>
                        {(item?.afford_amount !== '' && item?.check_list?.income === 2) && <span className={`${styles.ProspectDeclinedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>}
                        {(item?.afford_amount !== '' && item?.check_list?.income === 1) && <span className={`${styles.ProspectApprovedBadge} ${styles.AffordBadge}`}>Afford: ${item?.afford_amount}</span>}
                    </div>
                    <div className={`${styles.ProsUserMandateSec}`}>
                        <Tooltip title={item?.credit_score !== "" ? "Credit Score - " + item?.credit_score : "Credit Score - N/A"}>
                            <span className={item?.check_list?.credit_score === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.credit_score === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                <img src="/static/img/credit-score-icon.svg" alt="" />
                            </span>
                        </Tooltip>
                        <Tooltip title={(item?.income_type === 'Voucher') ? (item?.voucher_amount !== "" ? "Voucher - $" + item?.formatted_voucher_amount : "Voucher - N/A") : (item?.income_per_year !== "" ? "Salary - $" + item?.formatted_income_per_year : "Salary - N/A")}>
                            <span className={item?.check_list?.income === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.income === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                <img src="/static/img/salary-icon.svg" alt="" />
                            </span>
                        </Tooltip>
                        <Tooltip title={item?.ever_had_bankruptcy !== "" ? "Bankruptcy - " + item?.ever_had_bankruptcy : "Bankruptcy - N/A"}>
                            <span className={item?.check_list?.bankruptcy === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.bankruptcy === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                <img src="/static/img/bankruptcy-icon.svg" alt="" />
                            </span>
                        </Tooltip>
                        <Tooltip title={item?.ever_case_filed !== "" ? "Housing Court Cases - " + item?.ever_case_filed : "Housing Court Cases - N/A"}>
                            <span className={item?.check_list?.housing_court_case === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : item?.check_list?.housing_court_case === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                                <img src="/static/img/court-cases-icon.svg" alt="" />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </Link>

        <Dialog
            open={openLeaderBoardModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenLeaderBoardModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to remove from leader board?
                <button onClick={(e) => setOpenLeaderBoardModal(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The Leaderboard Record will be remove from leader board list immediately. You can add to leader board it at any time.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => setOpenLeaderBoardModal(false)} className="CancelPopupBU">Cancel</Button>
                <Button onClick={(e) => removeFromLeaderBoard()} className="SubmitPopupBU">Confirm</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openCallModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => setOpenCallModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">
                Prospect Call Confirmation
                <button onClick={(e) => setOpenCallModal(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">Please confirm you wish to initiate a call to this prospect.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => setOpenCallModal(false)} className="CancelPopupBU">Cancel</Button>
                <Button onClick={(e) => initiateVoiceCallConfirm()} className="SubmitPopupBU">Proceed</Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default ProspectCard;