import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import styles from "./leaderboard.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ArrowLeft, ChevronDown, Home, MoreVertical, Plus, Trash2, X } from "react-feather";
import { Dropdown } from "react-bootstrap";
import Loader from "../../../components/Loader";
import Footer from "../../../components/Footer";
import ProspectCard from "./component/prospectcard";
import { TableLoader } from "../../../components/LoaderC";
import AddLeaderboard from "./component/addLeaderboard";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list?.children);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source?.children);
    const destClone = Array.from(destination?.children);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = [];
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
}

const LeaderBoards = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [itemList, setItemList] = useState([{ id: 0, title: 'Leader', children: [] }]);
    const [leaderboardList, setLeaderboardList] = useState([]);
    const [leaderboards, setLeaderboards] = useState([]);
    const [isListAddOpen, setListIsAddOpen] = useState(false);
    const [listTextVal, setListTextVal] = useState('');
    const [loading, setLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(true);
    const [isListEditOpen, setListIsEditOpen] = useState(false);
    const [listEditId, setListEditId] = useState(0);
    const [openListDeleteModal, setOpenListDeleteModal] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [openAddLeaderModal, setOpenAddLeaderModal] = useState(false);
    const [addListId, setAddListId] = useState(0);


    useEffect(() => {
        const fetchAllProperties = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-all-properties", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let propertyData = response.data.data;

                if (propertyData.length > 0) {
                    let selectedPropertyTemp = propertyData.filter(i => i?.value === 1);
                    if (selectedPropertyTemp.length === 0) {
                        selectedPropertyTemp = propertyData[0];
                    } else {
                        selectedPropertyTemp = selectedPropertyTemp[0];
                    }

                    setSelectedProperty(selectedPropertyTemp);
                }

                setPropertyList(propertyData);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        fetchAllProperties();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const fetchAllList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/leaderboard-list/" + selectedProperty?.value, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setContentLoading(false);
                setLeaderboardList(response.data.data);
            }).catch((error) => {
                setContentLoading(false);
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        if (selectedProperty?.value > 0)
            fetchAllList();
    }, [getAccessTokenSilently, selectedProperty?.value]);

    useEffect(() => {
        const fetchLeaderboards = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/leaderboard/" + selectedProperty?.value, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setLeaderboards(response.data.data);
            }).catch((error) => {
                if (typeof error.response !== "undefined")
                    toast.error(error.response.data.message);
            });
        };
        if (selectedProperty?.value > 0)
            fetchLeaderboards();
    }, [getAccessTokenSilently, selectedProperty?.value]);

    useEffect(() => {
        let itemListTemp = leaderboardList?.map(item => {
            return { id: item?.id, title: item?.name, children: [] };
        });

        itemListTemp = [{ id: 0, title: 'Leader', children: [] }].concat(itemListTemp);

        itemListTemp = itemListTemp?.map(item => {
            let cards = leaderboards?.filter(i => i.list_id === item?.id);
            cards = cards?.map(i => {
                return { ...i, id: 'item-' + i?.id };
            })

            return { ...item, children: cards };
        });

        setItemList(itemListTemp);
    }, [leaderboardList, leaderboards]);

    useEffect(() => {
        const handler = setTimeout(async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
            let sortedItemList = itemList?.map(i => {
                let childList = i?.children?.map(j => {
                    return { id: j?.leaderboard_id };
                });
                return { list_id: i?.id, children: childList };
            });

            if (sortedItemList?.length > 0) {
                axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/reorder-leaderboard", { data: JSON.stringify(sortedItemList) }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    console.log('Success');
                }).catch((error) => {
                    console.log('Error');
                });;
            }
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [itemList, getAccessTokenSilently]);

    function onDragEnd(result) {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(itemList[sInd], source.index, destination.index);

            setItemList(prev => {
                return prev?.map((i, ind) => {
                    if (ind === sInd) {
                        return { ...i, children: items };
                    }
                    return i;
                })
            });
        } else {
            const result = move(itemList[sInd], itemList[dInd], source, destination);

            setItemList(prev => {
                return prev?.map((i, ind) => {
                    if (ind === sInd) {
                        return { ...i, children: result[sInd] };
                    }
                    if (ind === dInd) {
                        return { ...i, children: result[dInd] };
                    }
                    return i;
                })
            });
        }
    }

    const addList = async () => {
        if (listTextVal === '') {
            toast.error('List title should not blank');
            return false;
        }
        setLoading(true);
        setListIsAddOpen(false);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        let data = { property_id: selectedProperty?.value, name: listTextVal };

        return axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/add-leaderboard-list", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            let resData = response.data.data;

            setLeaderboardList(prev => {
                return prev.concat(resData);
            });
            setListTextVal('');
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const updateList = async () => {
        if (listTextVal === '') {
            toast.error('List title should not blank');
            return false;
        }
        setLoading(true);
        setListIsEditOpen(false);

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/update-leaderboard-list/" + listEditId, { name: listTextVal }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            let resData = response.data.data;

            setItemList(prev => {
                return prev.map(i => {
                    if (i.id === listEditId) {
                        return { ...i, title: resData?.name };
                    }
                    return i;
                })
            });
            setListEditId(0);
            setListTextVal('');
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    const deleteList = async () => {
        setLoading(true);
        setOpenListDeleteModal(false);

        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/delete-leaderboard-list/" + listEditId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);

            let deletedList = itemList?.filter(i => i.id === listEditId);

            if (deletedList.length) {
                let deletedChildren = deletedList[0]?.children;
                deletedChildren = deletedChildren?.map(i => {
                    return { ...i, list_id: 0 };
                });

                if (deletedChildren.length > 0) {
                    setItemList(prev => {
                        return prev.map(i => {
                            if (i.id === 0) {
                                return { ...i, children: i?.children.concat(deletedChildren) };
                            }
                            return i;
                        });
                    });
                }
            }

            setItemList(prev => {
                return prev.filter(i => i.id !== listEditId);
            });
            setListEditId(0);
        }).catch((error) => {
            setLoading(false);
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
        });
    }

    return (<React.Fragment>
        <Helmet title="Leaderboard" />
        {loading && <Loader />}
        <div className={`${styles.pageTitleWrap}`}>
            <div className={`${styles.PageMainTTSec}`}>
                <Link to={`/people/prospects`} className={`${styles.BackArrow}`}><ArrowLeft /></Link>
                <Typography display="inline" className={`${styles.pageTitle}`} onClick={(e) => setAnchorEl(e.currentTarget)}>Leaderboard: {selectedProperty?.label} <ChevronDown /></Typography>
                <Menu id="basic-menu" MenuListProps={{ 'aria-labelledby': 'basic-button', }} anchorEl={anchorEl} open={open} onClose={(e) => setAnchorEl(null)}>
                    {propertyList?.map((i) => {
                        return <MenuItem className={`${styles.DropdownPageTitleMenu}`} onClick={(e) => { setSelectedProperty(i); setAnchorEl(null); }}>{i?.label}</MenuItem>
                    })}
                </Menu>
                {leaderboards.length > 0 && <p className={`${styles.CountText}`}>({leaderboards.length} Prospects)</p>}
            </div>
            <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}>
                        <span><Home /></span> People
                    </li>
                    <li className={`${styles.breadcrumbItem}`}>
                        <Link to={"/people/propects"}>Prospects</Link>
                    </li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>
                        Leaderboard
                    </li>
                </ol>
            </nav>
        </div>

        <div className={`${styles.BodyCon}`}>
            <div className={`${styles.mainCardDiv}`}>
                <div className={`${styles.mainCard}`}>
                    {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

                    {!contentLoading && (<div className="control-pane">
                        <div className={`${styles.Row}`}>
                            <div className={`${styles.TrelloRow}`}>

                                <DragDropContext onDragEnd={onDragEnd}>
                                    {itemList.map((el, ind) => (<Droppable key={ind} droppableId={`${ind}`}>
                                        {(provided, snapshot) => (<div className={`${styles.LeaderboardListSec}`} ref={provided.innerRef} {...provided.droppableProps}>
                                            <div className={`${styles.LeaderboardListSecUnder}`}>
                                                <div className={`${styles.Head}`}>
                                                    {el.id === 0 && <p>{el.title}</p>}
                                                    {el.id > 0 && <>
                                                        {(!isListEditOpen || listEditId !== el.id) && <>
                                                            <Link className={`${styles.AddTrelloListBUEdit}`} onClick={(e) => { setListIsAddOpen(false); setListIsEditOpen(true); setListEditId(el.id); setListTextVal(el.title); }}>{el.title}</Link>
                                                            <Dropdown>
                                                                <Dropdown.Toggle className={`${styles.ActionsBUTrelloList} ProsDrBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>

                                                                <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                                                                    <Dropdown.Item className={`${styles.ActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setOpenListDeleteModal(true); setListEditId(el.id); }}><Trash2 /> Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </>}
                                                        {(isListEditOpen && listEditId === el.id) && <div className={`${styles.CardListAddForm} ${styles.Edit}`}>
                                                            <input type="text" placeholder="Enter list name.." value={listTextVal} onChange={(e) => setListTextVal(e.target.value)} />
                                                            <button className={`${styles.CardListAddCanBU}`} onClick={(e) => { setListIsAddOpen(false); setListIsEditOpen(false); setListEditId(0); setListTextVal(''); }}>Cancel</button>
                                                            <button className={`${styles.CardListAddAddBU}`} onClick={(e) => updateList()}>Update</button>
                                                        </div>}
                                                    </>}
                                                </div>
                                                <div className={`${styles.TrelloLeaderCardRow}`}>
                                                    {el?.children?.map((item, index) => (<Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => (<div className={`${styles.ProspectCard}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                            <div className={`${styles.ProspectCardUnder}`}>
                                                                <ProspectCard item={item} setLoading={setLoading.bind(this)} setLeaderboards={setLeaderboards.bind(this)} />
                                                            </div>
                                                        </div>)}
                                                    </Draggable>))}

                                                </div>
                                                <Link className={`${styles.TrelloAddProspect}`} onClick={(e) => { setOpenAddLeaderModal(true); setAddListId(el?.id); }}>
                                                    <Plus /> <span>Add a prospect</span>
                                                </Link>
                                                {provided.placeholder}
                                            </div>
                                        </div>)}
                                    </Droppable>))}
                                </DragDropContext>

                                <div className={`${styles.LeaderboardListSec}`}>
                                    <div className={`${styles.LeaderboardListSecUnder}`}>
                                        {!isListAddOpen && <Link className={`${styles.AddTrelloListBU}`} onClick={(e) => { setListIsEditOpen(false); setListIsAddOpen(true); setListTextVal(''); }}>
                                            <Plus /> <span>Add another list</span>
                                        </Link>}

                                        {isListAddOpen && <div className={`${styles.CardListAddForm}`}>
                                            <input type="text" placeholder="Enter list name.." value={listTextVal} onChange={(e) => setListTextVal(e.target.value)} />
                                            <button className={`${styles.CardListAddCanBU}`} onClick={(e) => { setListIsAddOpen(false); setListTextVal(''); }}>Cancel</button>
                                            <button className={`${styles.CardListAddAddBU}`} onClick={(e) => addList()}>Add list</button>
                                        </div>}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>)}

                </div>
            </div>
        </div>

        <Dialog
            open={openListDeleteModal}
            fullWidth
            maxWidth="sm"
            onClose={(e) => { setOpenListDeleteModal(false); setListEditId(0); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the leaderboard list?
                <button onClick={(e) => { setOpenListDeleteModal(false); setListEditId(0); }}><X /></button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">The leaderboard list will be removed immediately. You can't undo this action. All associated cards will be moved to the default list.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="CancelPopupBU" onClick={(e) => { deleteList(); }}>Delete</Button>
                <Button className="SubmitPopupBU" onClick={(e) => { setOpenListDeleteModal(false); setListEditId(0); }}>Cancel</Button>
            </DialogActions>
        </Dialog>


        <Dialog
            open={openAddLeaderModal}
            fullWidth
            maxWidth="lg"
            onClose={(e) => setOpenAddLeaderModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp CustomWidthPopUs MobileForm"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setOpenAddLeaderModal(false)}><ArrowLeft /></button>
                <span>Add Leaderboard</span>
                <button onClick={(e) => setOpenAddLeaderModal(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <AddLeaderboard list_id={addListId} property_id={selectedProperty?.value} setOpenAddLeaderModal={setOpenAddLeaderModal.bind(this)} setLoading={setLoading.bind(this)} setLeaderboards={setLeaderboards.bind(this)} />
            </DialogContent>
        </Dialog>

        <Footer />
    </React.Fragment>);
}

export default LeaderBoards;