import React, { useEffect } from "react";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";
import axios from 'axios';

const ProspectVerification = ({ match }) => {
    const { str } = match?.params;
    const redirect = useHistory();

    useEffect(() => {
        const fetchByID = async () => {
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/prospect-verification-link/' + str).then(response => {
                const resData = response.data.data;
                if (resData !== '') {
                    window.location.href = resData;
                } else {
                    redirect.push('/verify-invalid');
                }
            }).catch(error => {
                redirect.push('/verify-invalid');
            });
        }
        fetchByID();
    }, [str, redirect]);

    return (<React.Fragment>
        <Loader />
    </React.Fragment>);
}

export default ProspectVerification;