import React from "react";
import { Link, withRouter } from "react-router-dom";
import styles from './notificationscommon.module.css';
import styled from "styled-components/macro";
import axios from 'axios';
import Loader from "./Loader";
import { Badge, Box, Button, IconButton, Popover as MuiPopover, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox } from "@material-ui/core";
import { Bell } from "react-feather";
import { Search, Filter, X, Trash2 } from 'react-feather';
import { toast } from "react-smart-toaster";
import Select from "react-select";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import { Dropdown } from 'react-bootstrap';

const iconList = {
  'complaint': '/static/img/NotiIcon3.png',
  'request': '/static/img/NotiIcon2.png',
  'application': '/static/img/NotiIcon4.png',
  'device-on': '/static/img/NotiIcon6.png',
  'device-off': '/static/img/NotiIcon5.png',
};

const urlList = {
  'complaint': '/cases/complaints/details/',
  'request': '/cases/maintenance-request/details/',
  'application': '/people/applicants/details/',
  'device-on': '/static/img/NotiIcon6.png',
  'device-off': '/static/img/NotiIcon5.png',
}
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
class NotificationsCommon extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.perPage = 8;
    this.state = { allData: [], data: [], pageCount: 0, page: 1, check: false, modalOpen: false, selectedItem: '', loading: false, contentLoading: true, filterPropertyList: [], filterApartmentList: [], filterTypeList: [{ value: '', label: 'Choose Type' }, { value: 'New Application Received', label: 'New Application Received' }, { value: 'Maintenance Request Recieved', label: 'Maintenance Request Recieved' }, { value: 'Complaint Received', label: 'Complaint Received' }, { value: 'Smart Device Online', label: 'Smart Device Online' }, { value: 'Smart Device Offline', label: 'Smart Device Offline' }], filterViewList: [{ value: '', label: 'All' }, { value: 'Active', label: 'Active' }, { value: 'Archived', label: 'Archived' }], filterData: { searchKey: '', type: '', property: '', apartment: '' }, filterShowValue: { Type: '', Property: '', Apartment: '' }, defaultValueView: { value: 'Active', label: 'Active' }, defaultValueProperty: { value: '', label: 'Choose Property' }, defaultValueapartment: null, defaultValueType: { value: '', label: 'Choose Type' }, isFilter: false, notification: '', notificationCount: 0, notificationDeleteModal: false, notificationDelID: '', isReadcheck: false, notificationReaddArr: [], isCheckedAllNew: false };
    this.audience = process.env.REACT_APP_NOTIFICATION_API_URL;
    const timezone_offset_minutesdata = new Date().getTimezoneOffset();
    this.timezone_offset_minutes = timezone_offset_minutesdata === 0 ? 0 : -timezone_offset_minutesdata;
  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    this.token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    this.stateOptions = [];
    this.cityOptions = [];
    try {
      this.setState({ ...this.state, contentLoading: true });
      await axios(this.audience + 'v1/notifications/get-latest-notifications?timezone_offset=' + this.timezone_offset_minutes, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let allData = response.data.data;
        let pageCount = (allData.length / this.perPage);
        if (pageCount > parseInt(pageCount))
          pageCount = (parseInt(pageCount) + 1);

        this.setState({ ...this.state, allData: allData, data: allData, pageCount: pageCount, contentLoading: false }, this.filterHandler);
      });
      axios(
        this.audience + 'v1/notifications/get-unread-no', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let notificationCount = response.data.data;
        this.setState({ ...this.state, notificationCount: notificationCount });
      });

      axios(
        this.audience + 'v1/properties', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        let propertyData = response.data.data;

        propertyData = [{ value: '', label: 'Choose Property', apartments: [] }].concat(propertyData);
        this.setState({ ...this.state, filterPropertyList: propertyData });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }

  notificationOpen() {
    this.setState({ ...this.state, notification: 'notificationOpen' });
  }

  closeNotification(type, event) {
    let filterData = this.state.filterData;
    let filterShowValue = this.state.filterShowValue;
    let defaultValueType = this.state.defaultValueType;
    let defaultValueProperty = this.state.defaultValueProperty;
    let defaultValueApartment = this.state.defaultValueApartment;
    let filterApartmentList = this.state.filterApartmentList;
    let notification = '';
    if (type === 'clear') {
      notification = 'notificationOpen'
    }

    let isFilter = false;
    filterData = { ...filterData, searchKey: '', type: '', property: '', apartment: '' };
    filterShowValue = { ...filterShowValue, Type: '', Property: '', Apartment: '' };
    defaultValueType = { value: '', label: 'Choose Type' };
    defaultValueProperty = { value: '', label: 'Choose Property' };
    defaultValueApartment = null;
    filterApartmentList = [];


    this.setState({ ...this.state, filterData: filterData, filterShowValue: filterShowValue, defaultValueType: defaultValueType, defaultValueProperty: defaultValueProperty, isFilter: isFilter, defaultValueApartment: defaultValueApartment, filterApartmentList: filterApartmentList, notification: notification }, this.filterHandler);
  }

  removeClassFun1(event) {
    event.stopPropagation();
    if (event.target.closest('#filterAreaID') === null) {
      if (this.state.Open === true) {
        this.setState({ ...this.state, Open: false });
      }
    }
  }
  onChangeHandler(e) {
    let filterData = this.state.filterData;
    filterData = { ...filterData, searchKey: e.target.value };
    this.setState({ ...this.state, filterData: filterData }, this.filterHandler);
  }

  handleChangeFilter = (stype, selectedOption) => {
    let filterData = this.state.filterData;
    let filterShowValue = this.state.filterShowValue;
    let defaultValueProperty = this.state.defaultValueProperty;
    let filterApartmentList = this.state.filterApartmentList;
    let defaultValueApartment = this.state.defaultValueApartment;
    let filterTypeList = this.state.filterTypeList;
    let defaultValueType = this.state.defaultValueType;
    let isFilter = false;
    if (selectedOption.value !== '') {
      isFilter = true;
    }
    if (stype === 'type') {
      filterData = { ...filterData, type: selectedOption.value };
      filterShowValue = { ...filterShowValue, Type: selectedOption.label };
      defaultValueType = { label: selectedOption.label, value: selectedOption.value };
    }
    if (stype === 'property') {
      filterData = { ...filterData, property: selectedOption.value };
      filterShowValue = { ...filterShowValue, Property: selectedOption.label };
      defaultValueProperty = { label: selectedOption.label, value: selectedOption.value };
      if (selectedOption.value !== '') {
        let property_id = selectedOption.value;
        let applist = this.state.filterPropertyList.filter(i => i.id === property_id);
        filterApartmentList = applist[0].apartments.filter(i => i.property_id === property_id);
        filterApartmentList = [{ value: '', label: 'All' }].concat(filterApartmentList);

        this.setState({ ...this.state, filterApartmentList: filterApartmentList, filterData: filterData, filterShowValue: filterShowValue });
      }
      if (selectedOption.value !== '') {
        filterApartmentList = [{ value: '', label: 'All' }].concat(selectedOption.apartments);
        defaultValueApartment = null;
        filterData = { ...filterData, apartment: '' };
        filterShowValue = { ...filterShowValue, Apartment: '' };
      } else {
        filterApartmentList = [];
        defaultValueApartment = null;
        filterData = { ...filterData, apartment: '' };
        filterShowValue = { ...filterShowValue, Apartment: '' };
      }
    }
    if (stype === 'apartment') {
      filterData = { ...filterData, apartment: selectedOption.value };
      filterShowValue = { ...filterShowValue, Apartment: selectedOption.label };
      defaultValueApartment = selectedOption;
    }
    if (stype === 'property' && selectedOption.value === '') {
      filterApartmentList = [];
      document.getElementsByClassName(" css-1uccc91-singleValue").innerHTML = 'Choose Apartment';
    }
    this.setState({ ...this.state, filterData: filterData, defaultValueProperty: defaultValueProperty, isFilter: isFilter, filterShowValue: filterShowValue, defaultValueApartment: defaultValueApartment, filterApartmentList: filterApartmentList, defaultValueType: defaultValueType, filterTypeList: filterTypeList }, this.filterHandler);
  };
  deleteFilter(stype) {
    let filterData = this.state.filterData;
    let filterShowValue = this.state.filterShowValue;
    let defaultValueType = this.state.defaultValueType;
    let defaultValueProperty = this.state.defaultValueProperty;
    let defaultValueApartment = this.state.defaultValueApartment;
    let filterApartmentList = this.state.filterApartmentList;


    let isFilter = this.state.isFilter;
    if (stype === 'Type') {
      filterData = { ...filterData, type: '' };
      filterShowValue = { ...filterShowValue, Type: '' };
      defaultValueType = { value: '', label: 'Choose Type' };
    }
    if (stype === 'Property') {
      filterData = { ...filterData, property: '', apartment: '' };
      filterShowValue = { ...filterShowValue, Property: '', Apartment: '' };
      defaultValueProperty = { value: '', label: 'Choose Property' };
      defaultValueApartment = null;
      filterApartmentList = [];
    }
    if (stype === 'Apartment') {
      filterData = { ...filterData, apartment: '' };
      defaultValueApartment = { value: '', label: 'Choose Apartment' };
      filterShowValue = { ...filterShowValue, Apartment: '' };
    }

    if (filterShowValue.Type === '' && filterShowValue.Property === '' && filterShowValue.Apartment === '') {
      isFilter = false;
    }
    this.setState({ ...this.state, filterData: filterData, filterShowValue: filterShowValue, defaultValueType: defaultValueType, defaultValueProperty: defaultValueProperty, isFilter: isFilter, defaultValueApartment: defaultValueApartment, filterApartmentList: filterApartmentList }, this.filterHandler);
  }
  filterHandler() {
    const { filterData } = this.state;
    let filteredData = this.state.allData;
    let isFilter = this.state.isFilter;
    if (filterData.searchKey !== '') {
      let inputVal = filterData.searchKey;
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.foreign_title.toLowerCase().includes(inputVal) || item.time_str.toLowerCase().includes(inputVal) || item.time_str2.toLowerCase().includes(inputVal);
      });
    }
    if (filterData.type !== '') {
      filteredData = filteredData.filter(i => i.title === filterData.type);
    }
    if (filterData.property !== '') {
      filteredData = filteredData.filter(i => i.property_id === filterData.property);
    }
    if (filterData.apartment !== '' && filterData.property !== '') {
      filteredData = filteredData.filter(i => i.apt_id === filterData.apartment);
    }
    this.setState({ ...this.state, data: filteredData, isFilter: isFilter });
  }
  deleteNotification(notificationDelID, event) {
    this.setState({ ...this.state, notificationDeleteModal: true, notificationDelID: notificationDelID });
  }
  delNotificationClose() {
    this.setState({ ...this.state, notificationDeleteModal: false, notificationDelID: '' });
  }

  delNotification() {
    this.setState({ ...this.state, notificationDeleteModal: false, loading: true });
    let allNotification = this.state.data.filter(item => item.id !== this.state.notificationDelID);
    this.setState({ ...this.state, data: allNotification, notificationDelID: '', loading: false });
    return axios.delete(this.audience + 'v1/notifications/' + this.state.notificationDelID, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      toast.success(response.data.message);
      let allNotification = this.state.data.filter(item => item.id !== this.state.notificationDelID);
      this.setState({ ...this.state, data: allNotification, notificationDelID: '', loading: false, notificationDeleteModal: false });
    }).catch(error => {
      if (typeof error.response !== 'undefined')
        toast.error(error.response.data.message);
    });
  }

  paginationChange = (event, value) => {
    this.setState({ ...this.state, page: value });
  };

  markAllCheckReadOld(event) {
    let checkArr = [];
    if (event.target.checked === true) {
      let allNotification = this.state.data
      allNotification.forEach(function (val, index) {
        checkArr.push(val.id);
      });
      var checkboxes = document.querySelectorAll('input[name="notificationCheckbox"]');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = event.checked;
      }
    } else {
      checkArr = [];
    }
  }

  markAllCheckRead(event) {
    let checkArr = [];
    if (event.target.checked) {
      let newData = this.state.data.map((item) => {
        checkArr.push(item.id);
        return { ...item, is_checked: 1 };
      });
      this.setState({ ...this.state, isCheckedAllNew: true, data: newData, notificationReaddArr: checkArr });
    } else {
      let newData = this.state.data.map((item) => {
        return { ...item, is_checked: 0 };
      });
      this.setState({ ...this.state, isCheckedAllNew: false, data: newData, notificationReaddArr: checkArr });
    }
  }
  markCheckRead(item, event) {
    let checkArr = [];
    let newData = [];
    if (event.target.checked) {
      newData = this.state.data.map((i) => {
        if (i === item) {
          checkArr.push(i.id);
          return { ...i, is_checked: 1 };
        } else if (i.is_checked === 1) {
          checkArr.push(i.id);
        }
        return i;
      });
    } else {
      newData = this.state.data.map((i) => {
        if (i === item) {
          return { ...i, is_checked: 0 };
        } else if (i.is_checked === 1) {
          checkArr.push(i.id);
        }
        return i;
      });
    }
    let isCheckedAll = newData.every(i => i.is_checked === 1);
    this.setState({ ...this.state, data: newData, isCheckedAllNew: isCheckedAll, notificationReaddArr: checkArr });
  }
  changeReadStatus(id, event) {
    let checkArr = this.state.notificationReaddArr;
    if (event.target.checked === true) {
      checkArr.push(id);
    } else {
      var values = checkArr.indexOf(id)
      checkArr.splice(values, 1);
    }
    this.setState({ ...this.state, notificationReaddArr: checkArr });
  }
  readSubmit() {
    this.setState({ ...this.state, loading: true });
    let checkArr = this.state.notificationReaddArr;
    let postData = {};
    if (checkArr.length !== 0) {
      postData = { ids: this.state.notificationReaddArr };

      return axios.put(this.audience + 'v1/notifications/read-as-by-ids', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let tempData = this.state.data.map(el => (
          checkArr.indexOf(el.id) > -1 ? { ...el, is_read: 1, is_checked: 0 } : el
        ))
        this.setState({ ...this.state, data: tempData, notificationCount: response.data.data, notificationReaddArr: [], loading: false });
      });
    }
  }
  markReadAll() {
    this.setState({ ...this.state, loading: true });
    let postData = {};
    if (this.state.data.length !== 0) {
      return axios.put(this.audience + 'v1/notifications/read-as-all', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        toast.success(response.data.message);
        let tempData = this.state.data.map(el => (
          { ...el, is_read: 1 }
        ));
        this.setState({ ...this.state, data: tempData, loading: false });
      });
    }
  }
  goDetails(item, event) {
    if (event.target.tagName !== 'INPUT') {
      let postData = {};
      postData = { ids: [item.id] };
      return axios.put(this.audience + 'v1/notifications/read-as-by-ids', postData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      }).then(response => {
        this.props.history.push(urlList[item.type] + item.foreign_id);
      }).catch(error => {
      });
    }
  }

  goDetails1(item) {
    let postData = {};
    postData = { ids: [item.id] };
    return axios.put(this.audience + 'v1/notifications/read-as-by-ids', postData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    }).then(response => {
      this.props.history.push(urlList[item.type] + item.foreign_id);
    }).catch(error => {
    });
  }

  render() {
    let activebtnbgcolor = '#EDEDED';
    let activebtncolor = '#ADADAD';
    if (this.state.notificationReaddArr.length !== 0) {
      activebtnbgcolor = '#230058';
      activebtncolor = '#fff';

    }
    return (<React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" onClick={this.notificationOpen.bind(this)} className="customHeaderIcon">
          <Indicator badgeContent={(this.state.notificationCount !== 0) ? this.state.notificationCount : 0}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover open={false} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {this.state.notificationCount} New Notifications
          </Typography>
        </NotificationHeader>
      </Popover>
      <div className={`${this.state.notification} ${styles.NotiMain}`} >
        <div className="NotiMainSlides">
          <div className="NotiHeader">

            <Button onClick={this.closeNotification.bind(this, 'close')}><X /></Button>
            <p>Notifications</p>
            <Link className={`${styles.ViewAllBU}`} size="small" to="/notifications">
              View All
            </Link>
          </div>
          <div className="NotesSearchArea">
            <div className="NotesSearchMain">
              <input type="text" placeholder="Search" className="searchInput" value={this.state.filterData.searchKey} onChange={this.onChangeHandler.bind(this)} />
              <Search />
            </div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="TableAddBUSecondary">
                <Filter />
              </Dropdown.Toggle>
              <Dropdown.Menu className="DropDownMenuBoxCus">
                <div className="ApplicantFilterSec">
                  <p>Filter By:<Button onClick={this.closeNotification.bind(this, 'clear')} className="ClearFilter">Clear All</Button></p>
                  <div className={`${styles.CustomSelect}`}>
                    <Select id="selApartment" onChange={this.handleChangeFilter.bind(this, 'type')} value={this.state.defaultValueType} options={this.state.filterTypeList} />
                  </div>
                  <div className={`${styles.CustomSelect}`}>
                    <Select onChange={this.handleChangeFilter.bind(this, 'property')} value={this.state.defaultValueProperty} options={this.state.filterPropertyList} />
                  </div>
                  <div className={`${styles.CustomSelect}`}>
                    <Select placeholder="Choose Apartment" id="selApartment" onChange={this.handleChangeFilter.bind(this, 'apartment')} value={this.state.defaultValueApartment} options={this.state.filterApartmentList} />
                  </div>
                  <Button className={`${styles.RaedBU}`} onClick={this.markReadAll.bind(this)}>Mark as read all</Button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {this.state.isFilter && <div className={`${styles.FilterShowingDiv}`}>
            <p className={`${styles.TitleFilterPill}`}>Filter By:</p>
            {Object.entries(this.state.filterShowValue).map(([key, val], i) => ((val !== '') && <span className={`${styles.FilterPill}`} key={i}>
              <span>{key}: {val}</span>
              <Button onClick={this.deleteFilter.bind(this, key)}><X /></Button>
            </span>))}
          </div>}

          <div className={`${styles.CheckSubArea}`}>
            <FormControlLabel control={<Checkbox />} label="" checked={(this.state.isCheckedAllNew > 0)} onChange={this.markAllCheckRead.bind(this)} />
            <Button className={`${styles.RaedBUSub}`} style={{ backgroundColor: activebtnbgcolor, color: activebtncolor }} disabled={(this.state.notificationReaddArr.length === 0)} onClick={this.readSubmit.bind(this)}>Mark as read</Button>
          </div>

          <div className="NotesRow">
            {this.state.data.map((item, index) => {
              let bgColors = '#ffffff';
              if (item.is_read === 0) {
                bgColors = '#efefef';
              }
              return <div key={index} className="NotesCard" style={{ backgroundColor: bgColors, cursor: 'pointer' }} >
                <div className="PropeCardInFR">
                  <div className="MainReqConDivBig" onClick={this.goDetails.bind(this, item)}>
                    <FormControlLabel control={<Checkbox name="notificationCheckbox" />} className={`${styles.NotiCheck}`} onChange={this.markCheckRead.bind(this, item)} checked={item.is_checked > 0} />
                    <img src={iconList[item.type]} alt="Icon" />
                    <div className="NotiCon">
                      <p className="InsideConName">{item.title}</p>
                      <p className="MainReqConDeName">{item.foreign_title}</p>
                      <p className="MainReqConDe">{item.property_name} - {item.apt_name}</p>
                      <p className="DateTS">{item.time_str}, {item.time_str2}</p>
                    </div>
                  </div>
                  <div className="MainReqConAction">
                    <Button onClick={this.deleteNotification.bind(this, item.id)} ><Trash2 /></Button>
                  </div>
                </div>
              </div>
            })}

            {this.state.data.length === 0 && <div className={`${styles.NoDataMain}`}>
              <div className={`${styles.NoDataIMG}`}>
                <img src="/static/img/nodata.svg" alt="No Data" />
              </div>
              <p className={`${styles.NoDataText}`}>No Data Found</p>
            </div>}
            <Dialog
              open={this.state.notificationDeleteModal}
              fullWidth
              maxWidth="sm"
              onClose={this.delNotificationClose.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete Notification<button onClick={this.delNotificationClose.bind(this)}><X /></button></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className={`${styles.ModalFormGroup}`}>
                    <label className={`${styles.FilterLabel}`}>Are you sure you want to delete this item?</label>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.delNotificationClose.bind(this)} className={`${styles.ModalBUCan}`}>Cancel</Button>
                <Button onClick={this.delNotification.bind(this)} className={`${styles.ModalBUSub}`}>Delete</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </React.Fragment >);
  }
}
//export default NotificationsCommon;
export default withAuthenticationRequired(withAuth0(withRouter(NotificationsCommon)), {
  onRedirecting: () => <Loader />,
});