import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Device } from "@twilio/voice-sdk";
import { setVoiceCallInfo } from "../../redux/actions/communicationVoiceCallInfo";
import styles from "./communication-voice.module.css";
import { Phone } from "react-feather";
import Loader from "../Loader";
import { toast } from "react-smart-toaster";
import { AddLogCallAPI } from "../AddLogs";

const strGenerate = () => {
  const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const CommunicationVoiceCall = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const voiceCallInfo = useSelector((store) => store.communicationVoiceCallInfoReducer);
  const [callStatus, setCallStatus] = useState('');
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("voice component", voiceCallInfo);

    const connectCall = async () => {
      console.log('Connect Call');
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      setLoading(true);

      axios.get(process.env.REACT_APP_COMMUNICATION_NODE_API_URL + "voice/get-call-token/" + voiceCallInfo?.id + '-' + strGenerate(), {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        let twilioToken = response.data.data.token;

        const newDevice = new Device(twilioToken);

        setDevice(newDevice);

        let pPhone = voiceCallInfo?.phone;
        pPhone = pPhone.replace(/\D/g, '');
        pPhone = '+1' + pPhone;

        newDevice.connect({
          params: {
            To: pPhone,
            From: process.env.REACT_APP_TWILIO_VOICE_NUMBER
          },
        }).then((callResponse) => {
          setLoading(false);

          callResponse.on("ringing", () => {
            console.log("Ringing...");
            setCallStatus('ringing');
          });

          callResponse.on("accept", () => {
            console.log("Call accepted");
            setCallStatus('accept');

            let logData = {
              title: "Voice call initiated",
              description: [
                "Name: " + (voiceCallInfo?.full_name !== "" ? voiceCallInfo?.full_name : "-"),
                "Phone: " + voiceCallInfo?.phone,
              ],
            };
            AddLogCallAPI(logData, token);
          });

          callResponse.on("disconnect", () => {
            console.log("Call disconnected");
            setCallStatus('disconnect');
          });

          callResponse.on("error", (error) => {
            console.error("Connection error:", error);
            setCallStatus('');
            toast.error("Call is not connected. Try again!");
            dispatch(setVoiceCallInfo(null));
          });
        }).catch((err2) => {
          setLoading(false);
          console.log(err2);
          toast.error("Call is not connected. Try again!");
          dispatch(setVoiceCallInfo(null));
        });

        newDevice.on("error", (error) => {
          console.error("Device error:", error);
          dispatch(setVoiceCallInfo(null));
        });
      }).catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Call is not connected. Try again!");
        dispatch(setVoiceCallInfo(null));
      });
    }

    if (voiceCallInfo) {
      connectCall();
    }
  }, [voiceCallInfo]);


  const disConnectCall = () => {
    device.disconnectAll();
    dispatch(setVoiceCallInfo(null));
  }

  return (<>
    {loading && <Loader />}
    {(callStatus === 'ringing' || callStatus === 'accept') && <div className={`${styles.CallBar} CallBar`}>
      <div className={`${styles.CallBarAvatar}`}>
        {voiceCallInfo?.user_avatar !== "" ? (<img src={voiceCallInfo?.user_avatar} alt="" />) : voiceCallInfo?.initial !== "?" ? (<p>{voiceCallInfo?.initial}</p>) : (<img src="/static/img/DefaultLogo.png" alt="" />)}
      </div>
      <div className={`${styles.CallBarDetails}`}>
        {voiceCallInfo?.full_name !== "" && <p className={`${styles.Name}`}>{voiceCallInfo?.full_name}</p>}
        <p className={`${styles.Phone}`}>{voiceCallInfo?.phone}</p>
      </div>
      {callStatus === 'ringing' && <p className={`${styles.CallRingText}`}>Ringing</p>}
      {callStatus === 'accept' && <p className={`${styles.CallCallingText}`}>Calling</p>}
      <button className={`${styles.CallDisBU}`} onClick={(e) => disConnectCall()}><Phone /></button>
    </div>}
  </>);
};

export default CommunicationVoiceCall;
