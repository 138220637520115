import React, { useEffect, useState } from "react";
import styles from "./renrolldetails.module.css";
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormGroup, FormControlLabel, Checkbox, Snackbar, Slide, IconButton, TablePagination } from "@mui/material";
import { Home, ChevronDown, X, ArrowLeft, Search, Plus } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { TableLoader } from "../../components/LoaderC";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";
import RecordPayment from "./paymentcomps/RecordPayment";
import AddBill from "./paymentcomps/AddBill";
import AddCredit from "./paymentcomps/AddCredit";
import { useHistory } from "react-router-dom";
import PaymentCard from "./paymentcomps/PaymentCard";

const RenRollDetails = ({ history, match }) => {
  const { id } = match.params;
  const redirect = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [rentRollData, setRentRollData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [filterredData, setFilterredData] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(8);
  const [filterData, setFilterData] = useState({ searchKey: "", payment_type: ['bill', 'credit', 'payment'] });
  const [addBillModalShow, setAddBillModalShow] = useState(false);
  const [addCreditModalShow, setAddCreditModalShow] = useState(false);
  const [addPaymentModalShow, setAddPaymentModalShow] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [taskExpanded, setTaskExpanded] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteType, setDeleteType] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [editId, setEditId] = useState(0);
  const [filterText, setFilterText] = useState("All");

  useEffect(() => {
    const fetchRentRollData = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setRentRollData(response.data.data);
      });
    }

    fetchRentRollData();
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    const fetchRentRollList = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      await axios(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + id + "/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setAllData(response.data.data);
        setContentLoading(false);
      }).catch(error => {
        setContentLoading(false);
      });
    }

    fetchRentRollList();
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    let filterredDataTemp = allData;

    filterredDataTemp = filterredDataTemp.sort(function (a, b) {
      if (a.date_new === b.date_new) {
        return a.id - b.id;
      } else {
        return new Date(a.date_new) - new Date(b.date_new);
      }
    });

    let balanceNew = 0;
    filterredDataTemp = filterredDataTemp?.map(i => {
      let dueAmount = 0;
      let payAmount = 0;
      if (i?.type === 1) {
        dueAmount = parseFloat(i?.amount);
        balanceNew += parseFloat(i?.amount);
      }
      if (i?.type === 2) {
        dueAmount = parseFloat(balanceNew);
        balanceNew -= parseFloat(i?.amount);
        payAmount = parseFloat(i?.amount);
      }
      let FdueAmount = dueAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
      let FbalanceNew = balanceNew.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
      let FpayAmount = payAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
      return { ...i, due_amount_new: FdueAmount, balance_amount_new: FbalanceNew, payment_amount_new: FpayAmount };
    });

    filterredDataTemp = filterredDataTemp.reverse();

    if (filterData?.searchKey !== "") {
      let inputVal = filterData?.searchKey;
      inputVal = inputVal.toLowerCase();
      filterredDataTemp = filterredDataTemp.filter((item) => {
        let invoiceId = 100000 + parseInt(item.id);
        return (item.description.toLowerCase().includes(inputVal) || item.formatted_amount.toLowerCase().includes(inputVal) || (item.type === 1 && invoiceId.toString().includes(inputVal)));
      });
    }

    if (filterData?.payment_type?.indexOf('bill') === -1) {
      filterredDataTemp = filterredDataTemp.filter(item => item?.type !== 1);
    }

    if (filterData?.payment_type?.indexOf('credit') === -1) {
      filterredDataTemp = filterredDataTemp.filter(item => item?.payment_type !== 'add-credit');
    }

    if (filterData?.payment_type?.indexOf('payment') === -1) {
      filterredDataTemp = filterredDataTemp.filter(item => item?.payment_type !== 'record-payment' && item?.payment_type !== 'online-payment');
    }

    setFilterredData(filterredDataTemp);
  }, [filterData, allData]);

  const handleChange = (panel) => (event, isTaskExpanded) => {
    setTaskExpanded(isTaskExpanded ? panel : false);
  }

  const confirmDel = async () => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    setDeleteModalShow(false);
    setLoading(true);
    return axios.delete(process.env.REACT_APP_PAYMENT_API_URL + "v1/rent-rolls/" + id + "/list/" + deleteId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      setAllData(prev => {
        return prev.filter(i => i?.id !== deleteId);
      });

      setDeleteId(0);
      setTaskExpanded(false);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  }

  const addData = (data) => {
    setAllData(prev => {
      return [data].concat(prev);
    });
  }

  const updateData = (data) => {
    setAllData(prev => {
      let newArray = prev.map(i => {
        if (i?.id === data?.id)
          return data;
        return i;
      });
      return newArray;
    });
  }

  return (<React.Fragment>
    {loading && <Loader />}

    <Helmet title={"Payment Rent Roll - " + rentRollData?.property_name + " - " + rentRollData?.apt_name} />

    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

      {!contentLoading && (<>
        <div className={`${styles.SMProCusRow}`}>
          <div className={`${styles.BreadcrumbHeadSec}`}>
            <nav aria-label="breadcrumb">
              <ol className={`${styles.breadcrumb}`}>
                <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Payments</li>
                <li className={`${styles.breadcrumbItem} ${styles.active}`}>Rent Roll: {rentRollData?.tenant_name}</li>
              </ol>
            </nav>
            <div className={`${styles.PageTitleSec}`}>
              <div>
                <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
                <Typography display="inline" className={`${styles.pageTitle}`}>Rent Roll: {rentRollData?.tenant_name}</Typography>
              </div>
            </div>
          </div>

          <div className={`${styles.RentRollDetailsSec}`}>
            <div className={`${styles.ButtonArea}`}>
              <div className={`${styles.ButtonAreaUnder}`}>
                <div className={`${styles.FilterSearchSec}`}>
                  <div className={`${styles.NotesSearchMain}`}>
                    <Search />
                    <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle className={`${styles.ActionsBULine} ActionsBULine`} id="dropdown-basic">Show: {filterText} <ChevronDown /></Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                      <FormGroup row className={`${styles.CheckFilter}`}>
                        <FormControlLabel
                          control={<Checkbox
                            name="checkedE"
                            color="primary"
                            checked={filterData?.payment_type?.indexOf('bill') > -1 && filterData?.payment_type?.indexOf('credit') > -1 && filterData?.payment_type?.indexOf('payment') > -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFilterData(prev => {
                                  return { ...prev, payment_type: ['bill', 'credit', 'payment'] }
                                });
                                setFilterText("All");
                              } else {
                                setFilterData(prev => {
                                  return { ...prev, payment_type: [] }
                                });
                                setFilterText("None");
                              }
                            }}
                          />}
                          label="All"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            name="checkedF"
                            color="primary"
                            checked={filterData?.payment_type?.indexOf('bill') > -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('bill') === -1) {
                                    paymentTypeTemp.push('bill');
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              } else {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('bill') > -1) {
                                    paymentTypeTemp.splice(paymentTypeTemp?.indexOf('bill'), 1);
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              }
                              if (filterData?.payment_type?.length === 3) {
                                setFilterText("All");
                              } else if (filterData?.payment_type?.length === 0) {
                                setFilterText("None");
                              } else {
                                setFilterText(filterData?.payment_type?.length + " Selected");
                              }
                            }}
                          />}
                          label="Bills"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            name="checkedG"
                            color="primary"
                            checked={filterData?.payment_type?.indexOf('credit') > -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('credit') === -1) {
                                    paymentTypeTemp.push('credit');
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              } else {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('credit') > -1) {
                                    paymentTypeTemp.splice(paymentTypeTemp?.indexOf('credit'), 1);
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              }
                              if (filterData?.payment_type?.length === 3) {
                                setFilterText("All");
                              } else if (filterData?.payment_type?.length === 0) {
                                setFilterText("None");
                              } else {
                                setFilterText(filterData?.payment_type?.length + " Selected");
                              }
                            }}
                          />}
                          label="Credits"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            name="checkedH"
                            color="primary"
                            checked={filterData?.payment_type?.indexOf('payment') > -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('payment') === -1) {
                                    paymentTypeTemp.push('payment');
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              } else {
                                setFilterData(prev => {
                                  let paymentTypeTemp = prev?.payment_type;
                                  if (paymentTypeTemp?.indexOf('payment') > -1) {
                                    paymentTypeTemp.splice(paymentTypeTemp?.indexOf('payment'), 1);
                                  }
                                  return { ...prev, payment_type: paymentTypeTemp }
                                });
                              }
                              if (filterData?.payment_type?.length === 3) {
                                setFilterText("All");
                              } else if (filterData?.payment_type?.length === 0) {
                                setFilterText("None");
                              } else {
                                setFilterText(filterData?.payment_type?.length + " Selected");
                              }
                            }}
                          />}
                          label="Payments"
                        />
                      </FormGroup>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown>
                  <Dropdown.Toggle className={`${styles.ActionsBU} ${styles.Red} ActionsBU Red`} id="dropdown-basic">
                    <Plus /> <span className={`${styles.AddBuText}`}>New</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                    <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setAddBillModalShow(true); setIsUpdate(false); setEditId(0); }}><img src="/static/img/bill-icon.svg" alt="" />Bill</Dropdown.Item>
                    <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setAddCreditModalShow(true); setIsUpdate(false); setEditId(0); }}><img src="/static/img/credit-icon.svg" alt="" />Credit</Dropdown.Item>
                    <Dropdown.Item className={`${styles.ActionsBUListItem}`} onClick={(e) => { setAddPaymentModalShow(true); setIsUpdate(false); setEditId(0); }}><img src="/static/img/bank-icon.svg" alt="" />Payment</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className={`${styles.RentRollListSec}`}>
              <div className="FormAccording DeviceAlert Payment Theme">
                {filterredData.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<PaymentCard data={item} panel={"panel" + index} taskExpanded={taskExpanded} handleChange={handleChange.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setDeleteModalShow={setDeleteModalShow.bind(this)} setDeleteId={setDeleteId.bind(this)} setDeleteType={setDeleteType.bind(this)} setIsUpdate={setIsUpdate.bind(this)} setEditId={setEditId.bind(this)} setAddBillModalShow={setAddBillModalShow.bind(this)} setAddCreditModalShow={setAddCreditModalShow.bind(this)} setAddPaymentModalShow={setAddPaymentModalShow.bind(this)} />) : null;
                })}

                {filterredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={filterredData.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Payments per Page:'}
                  />
                </div>)}

                {filterredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/no-payment.png" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Unfortunately!</p>
                  <p className={`${styles.NoDataTextSub}`}>We haven't received any payment at this time.</p>
                </div>)}

              </div>
            </div>
          </div>
        </div>
      </>)}
    </div>

    <Dialog
      fullWidth
      maxWidth="xs"
      open={addBillModalShow}
      onClose={(e) => setAddBillModalShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddBillModalShow(false)}><ArrowLeft /></button>
        {isUpdate ? 'Update Bill' : 'Add Bill'}
        <button onClick={(e) => setAddBillModalShow(false)}><X /></button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <AddBill rent_roll_id={id} setModalShow={setAddBillModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
      </DialogContent>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="xs"
      open={addCreditModalShow}
      onClose={(e) => setAddCreditModalShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddCreditModalShow(false)}><ArrowLeft /></button>
        {isUpdate ? 'Update Credit' : 'Add Credit'}
        <button onClick={(e) => setAddCreditModalShow(false)}><X /></button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <AddCredit rent_roll_id={id} setModalShow={setAddCreditModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
      </DialogContent>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="xs"
      open={addPaymentModalShow}
      onClose={(e) => setAddPaymentModalShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddPaymentModalShow(false)}><ArrowLeft /></button>
        {isUpdate ? 'Update Payment' : 'Record Payment'}
        <button onClick={(e) => setAddPaymentModalShow(false)}><X /></button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <RecordPayment rent_roll_id={id} setModalShow={setAddPaymentModalShow.bind(this)} setLoading={setLoading.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} addData={addData.bind(this)} updateData={updateData.bind(this)} isUpdate={isUpdate} editId={editId} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={deleteModalShow}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setDeleteModalShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the {deleteType}?<button onClick={(e) => setDeleteModalShow(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The {deleteType} will be removed immediately. You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => confirmDel()}>Delete</Button>
        <Button onClick={(e) => setDeleteModalShow(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment>);
}

export default withAuthenticationRequired(RenRollDetails, {
  onRedirecting: () => <Loader />,
});