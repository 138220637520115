export default function communicationVoiceCallScreenReducer(isOpen = false, actions) {

    switch (actions.type) {

        case 'screenopen':
            return isOpen = actions.payload;


        default:
            return isOpen
    }

}