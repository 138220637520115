import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Helmet } from "react-helmet";
import Loader from "../../../components/Loader";
import { useStripe } from "@stripe/react-stripe-js";

const Reschedule = ({ match, props }) => {
    const { str } = match?.params;
    const redirect = useHistory();
    const stripe = useStripe();

    useEffect(() => {
        const fetchByID = async () => {
            await axios.get(process.env.REACT_APP_APPLICANT_API_URL + 'v1/create-fc-session/' + str).then(response => {
                const resData = response.data;
                if (resData?.secret_session !== '') {
                    stripe.collectFinancialConnectionsAccounts({
                        clientSecret: resData?.secret_session
                    }).then(async (result) => {
                        if (result.error) {
                            redirect.push('/connect-invalid');
                        } else if (result.financialConnectionsSession.accounts.length === 0) {
                            redirect.push('/connect-cancel/' + str);
                        } else {
                            let accounts = result.financialConnectionsSession.accounts;
                            if (accounts.length > 0) {
                                let accountObjArr = [];
                                for (let n in accounts) {
                                    let account = accounts[n];
                                    accountObjArr.push({ 'fc_account_id': account['id'], 'display_name': account['display_name'], 'institution_name': account['institution_name'], 'last4': account['last4'], 'category': account['category'] });
                                }

                                await axios.post(process.env.REACT_APP_APPLICANT_API_URL + 'v1/add-fc-account', { 'accountObjArr': JSON.stringify(accountObjArr), 'verify_url_token': str }).then(response => {
                                    if (response.data.status === 'connected') {
                                        redirect.push('/connect-success/' + str);
                                    } else {
                                        redirect.push('/connect-error/' + str);
                                    }
                                }).catch(error => {
                                    redirect.push('/connect-cancel/' + str);
                                });
                            }
                        }
                    });
                } else {
                    redirect.push('/connect-invalid');
                }
            }).catch(error => {
                redirect.push('/connect-invalid');
            });
        }
        if (str !== '' && stripe) {
            fetchByID();
        }
    }, [str, redirect, stripe]);

    return (<React.Fragment>
        <Helmet title="Connect Your Bank Account" />
        <Loader />
    </React.Fragment>);
}

export default Reschedule;