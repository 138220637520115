import React, { useEffect, useState } from "react";
import styles from "../prospectdetails.module.css";
import { Edit2, Eye, MoreVertical, Plus, Trash2, X } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, FormControlLabel, AccordionDetails, AccordionSummary, Accordion } from "@mui/material";
import { useForm } from "react-hook-form";
import Loader from "../../../components/Loader";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { Dropdown } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddLogCallAPI } from "../../../components/AddLogs";

const TaskTab = (props) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [checks1, setChecks1] = React.useState(null);
  const [checks2, setChecks2] = React.useState(null);
  const [showSubmitButton, setShowSubmitButton] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  useEffect(() => {
    if (props?.prospectData?.all_applicants) {
      setChecks1({
        credit_background_check: props?.prospectData?.all_applicants[0]?.credit_background_check,
        employement_verification: props?.prospectData?.all_applicants[0]?.employement_verification,
        income_verification: props?.prospectData?.all_applicants[0]?.income_verification,
        landlord_verification: props?.prospectData?.all_applicants[0]?.landlord_verification,
        application_fee_collected: props?.prospectData?.all_applicants[0]?.application_fee_collected,
      });

      if (props?.prospectData?.all_applicants?.length > 1) {
        setChecks2({
          credit_background_check: props?.prospectData?.all_applicants[1]?.credit_background_check,
          employement_verification: props?.prospectData?.all_applicants[1]?.employement_verification,
          income_verification: props?.prospectData?.all_applicants[1]?.income_verification,
          landlord_verification: props?.prospectData?.all_applicants[1]?.landlord_verification,
          application_fee_collected: props?.prospectData?.all_applicants[1]?.application_fee_collected,
        });
      }
    }
  }, [props?.prospectData?.all_applicants]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const onSubmit = async (data) => {
    let mDataNew = {};
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let postData = {
      employement_verification: data?.checks[0].employement_verification ? 1 : 0,
      income_verification: data?.checks[0].income_verification ? 1 : 0,
      landlord_verification: data?.checks[0].landlord_verification ? 1 : 0,
    };

    return axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.prospectData?.all_applicants[0]?.id, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setShowSubmitButton(false);
      setLoading(false);
      let respData = response.data.data;

      mDataNew = { ...mDataNew, employement_verification_1: respData?.employement_verification };
      mDataNew = { ...mDataNew, income_verification_1: respData?.income_verification };
      mDataNew = { ...mDataNew, landlord_verification_1: respData?.landlord_verification };

      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      if (respData) {
        let logData = {
          title: "Mandatory checks are updated",
          description: [
            "Applicant: " + respData?.full_name,
            "Email: " + respData?.email,
            "Phone: " + respData?.phone,
            "Property: " + respData?.property.name,
            "Apartment: " + respData?.apartment.apt_no,
          ],
        };
        AddLogCallAPI(logData, token);
      }

      if (props?.prospectData?.all_applicants?.length > 1) {
        let postData = {
          employement_verification: data?.checks[1].employement_verification ? 1 : 0,
          income_verification: data?.checks[1].income_verification ? 1 : 0,
          landlord_verification: data?.checks[1].landlord_verification ? 1 : 0,
        };

        axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.prospectData?.all_applicants[1]?.id, postData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then((response) => {
          let respData2 = response.data.data;

          props?.setSnackbarMsg(response.data.message);
          props?.setSnackbarOpen(true);

          mDataNew = { ...mDataNew, employement_verification_2: respData2?.employement_verification };
          mDataNew = { ...mDataNew, income_verification_2: respData2?.income_verification };
          mDataNew = { ...mDataNew, landlord_verification_2: respData2?.landlord_verification };

          props?.updateMandatoryChecks(mDataNew);

          if (respData2) {
            let logData = {
              title: "Mandatory checks are updated",
              description: [
                "Applicant: " + respData2?.full_name,
                "Email: " + respData2?.email,
                "Phone: " + respData2?.phone,
                "Property: " + respData2?.property.name,
                "Apartment: " + respData2?.apartment.apt_no,
              ],
            };
            AddLogCallAPI(logData, token);
          }
        }).catch((error) => {
          if (typeof error.response !== "undefined")
            toast.error(error.response.data.message);
          setLoading(false);
        });
      } else {
        props?.updateMandatoryChecks(mDataNew);

        setLoading(false);
      }
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const checkChagne1 = (event, type) => {
    setShowSubmitButton(true);
    if (type === "credit_background_check") {
      if (event.target.checked) {
        setChecks1((prev) => {
          return { ...prev, credit_background_check: 1 };
        });
      } else {
        setChecks1((prev) => {
          return { ...prev, credit_background_check: 0 };
        });
      }
    }
    if (type === "employement_verification") {
      if (event.target.checked) {
        setChecks1((prev) => {
          return { ...prev, employement_verification: 1 };
        });
      } else {
        setChecks1((prev) => {
          return { ...prev, employement_verification: 0 };
        });
      }
    }
    if (type === "income_verification") {
      if (event.target.checked) {
        setChecks1((prev) => {
          return { ...prev, income_verification: 1 };
        });
      } else {
        setChecks1((prev) => {
          return { ...prev, income_verification: 0 };
        });
      }
    }
    if (type === "landlord_verification") {
      if (event.target.checked) {
        setChecks1((prev) => {
          return { ...prev, landlord_verification: 1 };
        });
      } else {
        setChecks1((prev) => {
          return { ...prev, landlord_verification: 0 };
        });
      }
    }
    if (type === "application_fee_collected") {
      if (event.target.checked) {
        setChecks1((prev) => {
          return { ...prev, application_fee_collected: 1 };
        });
      } else {
        setChecks1((prev) => {
          return { ...prev, application_fee_collected: 0 };
        });
      }
    }
  };

  const checkChagne2 = (event, type) => {
    setShowSubmitButton(true);
    if (type === "credit_background_check") {
      if (event.target.checked) {
        setChecks2((prev) => {
          return { ...prev, credit_background_check: 1 };
        });
      } else {
        setChecks2((prev) => {
          return { ...prev, credit_background_check: 0 };
        });
      }
    }
    if (type === "employement_verification") {
      if (event.target.checked) {
        setChecks2((prev) => {
          return { ...prev, employement_verification: 1 };
        });
      } else {
        setChecks2((prev) => {
          return { ...prev, employement_verification: 0 };
        });
      }
    }
    if (type === "income_verification") {
      if (event.target.checked) {
        setChecks2((prev) => {
          return { ...prev, income_verification: 1 };
        });
      } else {
        setChecks2((prev) => {
          return { ...prev, income_verification: 0 };
        });
      }
    }
    if (type === "landlord_verification") {
      if (event.target.checked) {
        setChecks2((prev) => {
          return { ...prev, landlord_verification: 1 };
        });
      } else {
        setChecks2((prev) => {
          return { ...prev, landlord_verification: 0 };
        });
      }
    }
    if (type === "application_fee_collected") {
      if (event.target.checked) {
        setChecks2((prev) => {
          return { ...prev, application_fee_collected: 1 };
        });
      } else {
        setChecks2((prev) => {
          return { ...prev, application_fee_collected: 0 };
        });
      }
    }
  };

  const deleteConfirm = async () => {
    setOpenDeleteModal(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.prospectData?.applicant_id + "/tours/" + deleteId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);

      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      let allTours = props?.prospectData?.tours?.filter(
        (item) => item.id !== deleteId
      );

      props.updateTourList(allTours);
      setDeleteId(0);

      let logData = {
        title: "A prospect tour is deleted",
        description: [
          "Name: " + props?.prospectData?.all_applicants[0]?.full_name,
          "Email: " + props?.prospectData?.all_applicants[0]?.email,
          "Phone: " + props?.prospectData?.all_applicants[0]?.phone,
          "Date/Time: " +
          response.data.data?.formatted_date +
          " " +
          response.data.data?.formatted_time,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const cngTourCheck = async (id, e) => {
    let postData = {};
    if (e.target.checked) {
      postData = { is_completed: 1 };
    } else {
      postData = { is_completed: 0 };
    }
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + props?.prospectData?.applicant_id + "/tours/" + id, postData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      props?.setSnackbarMsg(response.data.message);
      props?.setSnackbarOpen(true);

      let tourlist = props?.prospectData?.tours;
      tourlist = tourlist?.map((item) => {
        if (id === item?.id) {
          return response.data.data;
        }
        return item;
      });
      props.updateTourList(tourlist);

      let logData = {
        title: "A prospect tour is completed",
        description: [
          "Name: " + props?.prospectData?.all_applicants[0]?.full_name,
          "Email: " + props?.prospectData?.all_applicants[0]?.email,
          "Phone: " + props?.prospectData?.all_applicants[0]?.phone,
          "Date/Time: " +
          response.data.data?.formatted_date +
          " " +
          response.data.data?.formatted_time,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
    });
  };

  const openTenantReportFile = async (applicantId) => {
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-report-link/" + applicantId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);
      window.open(response?.data?.url);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  return (<>
    {loading && <Loader />}

    <div className="FormAccording ProspectTask Theme">
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <div className={`${styles.FormAccoHead}`}>
            <p className={`${styles.FormAccoHeadTitle}`}>Checklist</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
          <form id="myForm1" onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.ProspectTaskTable} ProspectTaskTable`}>
              <table>
                <tbody>
                  <tr>
                    <th>Actions</th>
                    <th>{props?.prospectData?.all_applicants[0]?.full_name}</th>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<th>{props?.prospectData?.all_applicants[1]?.full_name}</th>)}
                  </tr>
                  <tr>
                    <td><p>Application Fee Collected</p></td>
                    <td>
                      <FormControlLabel
                        disabled
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks1?.application_fee_collected === 1}
                            name={`checks.${0}.application_fee_collected`}
                            onChange={(e) => checkChagne1(e, "application_fee_collected")}
                          />
                        }
                      />
                    </td>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<td>
                      <FormControlLabel
                        disabled
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks2?.application_fee_collected === 1}
                            name={`checks.${1}.application_fee_collected`}
                            onChange={(e) =>
                              checkChagne2(e, "application_fee_collected")
                            }
                          />
                        }
                      />
                    </td>)}
                  </tr>
                  <tr>
                    <td><p>Credit & Background Report Received</p></td>
                    <td>
                      {checks1?.credit_background_check === 1 && (<button className={`${styles.ReportBU}`} onClick={(e) => openTenantReportFile(props?.prospectData?.all_applicants[0]?.id)}>
                        <Eye />View Reports
                      </button>)}
                      {checks1?.credit_background_check === 0 && checks1?.application_fee_collected === 1 && (<span className={`${styles.Statusbadge} ${styles.InProgress}`}>In-progress</span>)}
                      {checks1?.credit_background_check === 0 && checks1?.application_fee_collected === 0 && (<FormControlLabel
                        disabled
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            name={`checks.${0}.credit_background_check`}
                          />
                        }
                      />)}
                    </td>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<td>
                      {checks2?.credit_background_check === 1 && (<button className={`${styles.ReportBU}`} onClick={(e) => openTenantReportFile(props?.prospectData?.all_applicants[1]?.id)}>
                        <Eye />View Reports
                      </button>)}
                      {checks2?.credit_background_check === 0 && checks2?.application_fee_collected === 1 && (<span className={`${styles.Statusbadge} ${styles.InProgress}`}>In-progress</span>)}
                      {checks2?.credit_background_check === 0 && checks2?.application_fee_collected === 0 && (<FormControlLabel
                        disabled
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            name={`checks.${1}.credit_background_check`}
                          />
                        }
                      />)}
                    </td>)}
                  </tr>
                  <tr>
                    <td><p>Employment Verification</p></td>
                    <td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks1?.employement_verification === 1}
                            name={`checks.${0}.employement_verification`}
                            onChange={(e) => checkChagne1(e, "employement_verification")}
                          />
                        }
                      />
                    </td>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks2?.employement_verification === 1}
                            name={`checks.${1}.employement_verification`}
                            onChange={(e) => checkChagne2(e, "employement_verification")}
                          />
                        }
                      />
                    </td>)}
                  </tr>
                  <tr>
                    <td><p>Income Verification</p></td>
                    <td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks1?.income_verification === 1}
                            name={`checks.${0}.income_verification`}
                            onChange={(e) => checkChagne1(e, "income_verification")}
                          />
                        }
                      />
                    </td>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks2?.income_verification === 1}
                            name={`checks.${1}.income_verification`}
                            onChange={(e) => checkChagne2(e, "income_verification")}
                          />
                        }
                      />
                    </td>)}
                  </tr>
                  <tr>
                    <td><p>Landlord Verification</p></td>
                    <td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks1?.landlord_verification === 1}
                            name={`checks.${0}.landlord_verification`}
                            onChange={(e) => checkChagne1(e, "landlord_verification")}
                          />
                        }
                      />
                    </td>
                    {props?.prospectData?.all_applicants?.length > 1 && props?.prospectData?.all_applicants[1]?.is_submitted === 1 && (<td>
                      <FormControlLabel
                        disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1) ? true : false}
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            inputRef={register}
                            checked={checks2?.landlord_verification === 1}
                            name={`checks.${1}.landlord_verification`}
                            onChange={(e) => checkChagne2(e, "landlord_verification")}
                          />
                        }
                      />
                    </td>)}
                  </tr>
                </tbody>
              </table>
            </div>
            {showSubmitButton && (<div className={`${styles.CheckApplyBU}`}>
              <button type="submit">Apply</button>
            </div>)}
          </form>
        </AccordionDetails>
      </Accordion>

      {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-tour:read") > -1 && (<Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <div className={`${styles.FormAccoHead}`}>
            <p className={`${styles.FormAccoHeadTitle}`}>Tours</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className={`${styles.CussAccoRow}`}>
          <div className={`${styles.ProspectTaskTable} ProspectTaskTable`}>
            {props?.prospectData?.tours?.length > 0 && (<table>
              <tbody>
                <tr>
                  <th>Created By</th>
                  <th>Date</th>
                  <th>Completed</th>
                </tr>
                {props?.prospectData?.tours?.map((item) => {
                  return (<tr>
                    <td>
                      <div className={`${styles.TourNameSec}`}>
                        <p className={`${styles.Emoji}`}>{item?.user_initial}</p>
                        <p className={`${styles.TourCretorName}`}>{item?.user_fullname}</p>
                      </div>
                    </td>
                    <td>
                      <p>{item?.formatted_date}<span>{item?.formatted_time}</span></p>
                    </td>
                    <td>
                      <FormControlLabel
                        className={`${styles.ProsCheckSec}`}
                        control={
                          <Checkbox
                            defaultChecked={item?.is_completed === 1}
                            onChange={cngTourCheck.bind(this, item?.id)}
                            disabled={!(isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-tour:update") > -1)}
                          />
                        }
                      />
                      {(item?.is_completed === 1) && <span className={`${styles.TourcompletedBadge}`}>Completed</span>}
                      {(item?.is_completed === 0 && item?.is_confirmed === 1) && <span className={`${styles.TourconfirmedBadge}`}>Confirmed</span>}
                      {(item?.is_completed === 0 && item?.is_confirmed === 0) && <span className={`${styles.TourpendingBadge}`}>Pending</span>}
                      {isAuthenticated && (user["https://propertydek.com/permissions"].indexOf("prospect-tour:update") > -1 || user["https://propertydek.com/permissions"].indexOf("prospect-tour:delete") > -1) && (<Dropdown className={`${styles.ProEditSec}`}>
                        <Dropdown.Toggle className={`${styles.EditActionsBU} ${styles.Table} ProsDrBU`} id="dropdown-basic">
                          <MoreVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                          {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-tour:update") > -1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { props?.setAddTourModalOpen(true); props?.setEditTourData(item); }}>
                            <Edit2 /> Edit
                          </Dropdown.Item>)}
                          {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-tour:delete") > -1 && (<Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setOpenDeleteModal(true); setDeleteId(item?.id); }}>
                            <Trash2 /> Delete
                          </Dropdown.Item>)}
                        </Dropdown.Menu>
                      </Dropdown>)}
                    </td>
                  </tr>);
                })}
              </tbody>
            </table>)}
            {props?.prospectData?.tours?.length === 0 && (<div className={`${styles.NotesRow}`}>
              <div className={`${styles.NoDataMain}`}>
                <div className={`${styles.NoDataIMG}`}>
                  <img src="/static/img/NoTaskFound.png" alt="" />                  
                </div>
                <p className={`${styles.NoDataText}`}>No Tour Found</p>
                {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect-tour:create") > -1 && (<button className={`${styles.AddLeaseBU}`} onClick={(e) => { props.setAddTourModalOpen(true); props.setTourTimeSlotError(""); props.setEditTourData(null); }}>
                  <Plus /> Add Tour
                </button>)}
              </div>
            </div>)}
          </div>
        </AccordionDetails>
      </Accordion>)}
    </div>

    <Dialog
      open={openDeleteModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
      onClose={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}
    >
      <DialogTitle id="alert-dialog-title">Delete Tour<button onClick={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="ModalFormGroup"
          style={{ marginBottom: "0px", padding: "25px 0" }}
        >
          <label className="PopupBodyText">Are you sure you want to delete this item?</label>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => { deleteConfirm(); }}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => { setOpenDeleteModal(false); setDeleteId(0); }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default TaskTab;
