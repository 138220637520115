import React, { useEffect, useRef, useState } from "react";
import styles from "./tour.module.css";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Dialog, FormControl, InputLabel, MenuItem, Select, Typography, Snackbar, Slide, IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Flag, Home, Plus, Star, X } from "react-feather";
import { Dropdown } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Loader from "../../components/Loader";
import OpenHouseFrom from "./Components/OpenHouseForm";
import OpenTourFrom from "./Components/OpenTourForm";
import axios from "axios";
import { toast } from "react-smart-toaster";
import dayjs from "dayjs";
import Footer from "../../components/Footer";
import EventView from "./Components/EventView";
import { Helmet } from "react-helmet";
import { Tooltip } from "bootstrap";
import { AddLogCallAPI } from "../../components/AddLogs";

let tooltipInstance = null;

const Calendar = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openHouseFormOpen, setOpenHouseFormOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState(dayjs());
  const [addTourFormOpen, setAddTourFormOpen] = useState(false);
  const [viewEventOpen, setViewEventOpen] = useState(false);
  const [editEventOpen, setEditEventOpen] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const [monthDateArr, setMonthDateArr] = useState([dayjs().startOf("month").format("YYYY-MM-DD"), dayjs().endOf("month").format("YYYY-MM-DD")]);
  const [allEvents, setAllEvents] = useState([]);
  const [openHouseEvents, setOpenHouseEvents] = useState([]);
  const [tourEvents, setTourEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [isTour, setIsTour] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    const fetchAllProperties = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/get-all-properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setAllProperties(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchAllTours = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/tour-list/" + monthDateArr[0] + "/" + monthDateArr[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let tourArr = response.data.data?.map((item) => {
          return {
            id: "T" + item?.id,
            title: item?.prospect_name,
            start: item?.date_time,
            extendedProps: {
              tour_id: item?.id,
              open_house_id: 0,
              prospect_id: item?.applicant_id,
              property_id: item?.property_id,
              apt_id: item?.apt_id,
              property_name: item?.property_name,
              apt_no: item?.apt_no,
              prospect_name: item?.prospect_name,
              prospect_email: item?.prospect_email,
              prospect_phone: item?.prospect_phone,
              date_time: item?.date_time,
              is_confirmed: item?.is_confirmed,
              is_completed: item?.is_completed,
              conversation_id: item?.prospect_conversation_id
            },
          };
        });
        setTourEvents(tourArr);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllTours();
  }, [getAccessTokenSilently, monthDateArr]);

  useEffect(() => {
    const fetchAllOpenHouses = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/open-house-list/" + monthDateArr[0] + "/" + monthDateArr[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        let ohArr = response.data.data?.map((item) => {
          return {
            id: "OH" + item?.id,
            title: "Open House",
            start: item?.date_time,
            extendedProps: {
              tour_id: 0,
              open_house_id: item?.id,
              prospect_id: 0,
              property_id: item?.property_id,
              property_name: item?.property_name,
              date_time: item?.date_time,
            },
          };
        });
        setOpenHouseEvents(ohArr);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    };
    fetchAllOpenHouses();
  }, [getAccessTokenSilently, monthDateArr]);

  useEffect(() => {
    let allEventsTemp = openHouseEvents.concat(tourEvents);
    setAllEvents(allEventsTemp);

    if (selectedProperty > 0) {
      let openHouseEventsTemp = openHouseEvents.map((i) => {
        if (i?.extendedProps?.property_id === selectedProperty) {
          return i;
        }
        return null;
      });
      openHouseEventsTemp = openHouseEventsTemp.filter((i) => i !== null);

      let tourEventsTemp = tourEvents.map((i) => {
        if (i?.extendedProps?.property_id === selectedProperty) {
          return i;
        }
        return null;
      });
      tourEventsTemp = tourEventsTemp.filter((i) => i !== null);

      let allEventsTemp = openHouseEventsTemp.concat(tourEventsTemp);
      setAllEvents(allEventsTemp);
    } else {
      let allEventsTemp = openHouseEvents.concat(tourEvents);
      setAllEvents(allEventsTemp);
    }
  }, [openHouseEvents, tourEvents, selectedProperty]);

  const onAddOpenHouse = (data) => {
    setOpenHouseEvents((prev) => {
      return prev.concat([
        {
          id: "OH" + data?.id,
          title: "Open House",
          start: data?.date_time,
          extendedProps: {
            tour_id: 0,
            open_house_id: data?.id,
            prospect_id: 0,
            property_id: data?.property_id,
            property_name: data?.property_name,
            date_time: data?.date_time,
          },
        },
      ]);
    });
  };

  const onAddTour = (data) => {
    let tourEventTemp = data?.map((item) => {
      return {
        id: "T" + item?.id,
        title: item?.prospect_name,
        start: item?.date_time,
        extendedProps: {
          tour_id: item?.id,
          open_house_id: 0,
          prospect_id: item?.applicant_id,
          property_id: item?.property_id,
          apt_id: item?.apt_id,
          property_name: item?.property_name,
          apt_no: item?.apt_no,
          prospect_name: item?.prospect_name,
          prospect_email: item?.prospect_email,
          prospect_phone: item?.prospect_phone,
          date_time: item?.date_time,
          is_confirmed: item?.is_confirmed,
          is_completed: item?.is_completed,
          conversation_id: item?.prospect_conversation_id
        },
      };
    });

    setTourEvents((prev) => {
      return prev.concat(tourEventTemp);
    });
  };

  const viewEvent = (ev) => {
    setViewEventOpen(true);
    setSelectedEvent(ev._def);

    if (ev._def?.extendedProps?.tour_id > 0) {
      setIsTour(true);
    } else {
      setIsTour(false);
    }
  };

  const onUpdateOpenHouse = (data) => {
    setOpenHouseEvents((prev) => {
      return prev.map((i) => {
        if (i?.extendedProps?.open_house_id === data?.id) {
          return {
            id: "OH" + data?.id,
            title: "Open House",
            start: data?.date_time,
            extendedProps: {
              tour_id: 0,
              open_house_id: data?.id,
              prospect_id: 0,
              property_id: data?.property_id,
              property_name: data?.property_name,
              date_time: data?.date_time,
            },
          };
        }
        return i;
      });
    });
  };

  const onUpdateTour = (data) => {
    setTourEvents((prev) => {
      return prev.map((i) => {
        if (i?.extendedProps?.tour_id === data?.id) {
          return {
            id: "T" + data?.id,
            title: data?.prospect_name,
            start: data?.date_time,
            extendedProps: {
              tour_id: data?.id,
              open_house_id: 0,
              prospect_id: data?.applicant_id,
              property_id: data?.property_id,
              apt_id: data?.apt_id,
              property_name: data?.property_name,
              apt_no: data?.apt_no,
              prospect_name: data?.prospect_name,
              prospect_email: data?.prospect_email,
              prospect_phone: data?.prospect_phone,
              date_time: data?.date_time,
              is_confirmed: data?.is_confirmed,
              is_completed: data?.is_completed,
              conversation_id: data?.prospect_conversation_id
            },
          };
        }
        return i;
      });
    });
  };

  const deleteConfirm = async () => {
    if (isTour) {
      let tour_id = selectedEvent?.extendedProps?.tour_id;
      if (tour_id) {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/tour/" + tour_id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          setLoading(false);
          setSnackbarMsg(response.data.message);
          setSnackbarOpen(true);

          setOpenDeleteModal(false);

          setTourEvents((prev) => {
            return prev.filter((i) => i?.extendedProps?.tour_id !== tour_id);
          });
        }).catch((error) => {
          setLoading(false);
          if (typeof error.response !== "undefined")
            toast.error(error.response.data.message);
        });
      }
    } else {
      let open_house_id = selectedEvent?.extendedProps?.open_house_id;
      if (open_house_id) {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/open-houses/" + open_house_id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          setLoading(false);
          setSnackbarMsg(response.data.message);
          setSnackbarOpen(true);

          setOpenDeleteModal(false);

          setOpenHouseEvents((prev) => {
            return prev.filter(
              (i) => i?.extendedProps?.open_house_id !== open_house_id
            );
          });
        }).catch((error) => {
          setLoading(false);
          if (typeof error.response !== "undefined")
            toast.error(error.response.data.message);
        });
      }
    }
  };

  const completeConfirm = async () => {
    let tour_id = selectedEvent?.extendedProps?.tour_id;
    let prospect_id = selectedEvent?.extendedProps?.prospect_id;
    if (tour_id) {
      setLoading(true);
      setOpenCompleteModal(false);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      return axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + prospect_id + "/tours/" + tour_id, { is_completed: 1 }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setLoading(false);
        setSnackbarMsg(response.data.message);
        setSnackbarOpen(true);

        setTourEvents((prev) => {
          return prev.map((i) => {
            if (i?.extendedProps?.tour_id === tour_id) {
              let extendedProps = i?.extendedProps;
              extendedProps = { ...extendedProps, is_completed: 1 };
              return { ...i, extendedProps: extendedProps };
            }
            return i;
          });
        });

        let logData = {
          title: "A prospect tour is completed",
          description: [
            "Name: " + selectedEvent?.extendedProps?.prospect_name,
            "Date/Time: " + selectedEvent?.extendedProps?.date_time
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
  }

  const handleWindowResize = (view) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (window.innerWidth > 1200) {
        calendarApi.changeView("dayGridMonth");
      } else {
        calendarApi.changeView("timeGridDay");
      }
    }
  }

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (window.innerWidth > 1200) {
        calendarApi.changeView("dayGridMonth");
      } else {
        calendarApi.changeView("timeGridDay");
      }
    }
  }, []);

  const handleMouseEnter = (info) => {
    if (info.event.extendedProps?.tour_id && info.event.extendedProps?.tour_id > 0) {
      tooltipInstance = new Tooltip(info.el, {
        title: (info.event.extendedProps?.is_completed === 1 ? 'Completed' : (info.event.extendedProps?.is_confirmed === 1 ? 'ID Verified' : 'ID Unverified')),
        html: true,
        placement: "bottom",
        trigger: "hover",
        container: "body"
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title="Schedule" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Schedule</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Schedule</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.mainCard}`}>
      <div className={`${styles.DahhboardHead}`}>
        <div className={`${styles.SwitchDiv} FormGroup Filter`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Property</InputLabel>
            <Select
              label="Select Property"
              value={selectedProperty}
              onChange={(e) => setSelectedProperty(e.target.value)}
            >
              <MenuItem key={0} value={0}>All Properties</MenuItem>
              {allProperties?.map((item) => {
                return (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
              })}
            </Select>
          </FormControl>
        </div>
        {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("schedule-tour:crud") > -1 && <div className={`${styles.CalendarActionBU}`}>
          <Dropdown>
            <Dropdown.Toggle className={`${styles.ActionsBU} ActionsBU`}>
              <Plus /> Add
            </Dropdown.Toggle>
            <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
              <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setDefaultDate(dayjs()); setAddTourFormOpen(true); }}>
                <Flag /> Tour
              </Dropdown.Item>
              <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setOpenHouseFormOpen(true)}>
                <Star /> Open House
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>}
      </div>
      <div className={`${styles.card}`}>
        <div className={`${styles.FullCalendar} FullCalendar`}>
          <FullCalendar
            ref={calendarRef}
            dayMaxEventRows={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={"dayGridMonth"}
            headerToolbar={{
              center: 'prev,next',
              left: 'title',
              right: 'dayGridMonth,dayGridWeek,timeGridDay'
            }}
            allDaySlot={false}
            events={allEvents}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short",
            }}
            eventClassNames={(arg) => {
              let arrList = [];
              if (arg?.event?.extendedProps?.tour_id === 0) {
                arrList = arrList.concat(['fc-openhouse-custom']);
              } else {
                arrList = arrList.concat(['fc-tour-custom']);
              }

              if (arg?.event?.extendedProps?.is_completed && arg?.event?.extendedProps?.is_completed === 1) {
                arrList = arrList.concat(['fc-tour-completed']);
              } else if (arg?.event?.extendedProps?.is_confirmed && arg?.event?.extendedProps?.is_confirmed === 1) {
                arrList = arrList.concat(['fc-tour-confirmed']);
              }

              return arrList;
            }}
            eventContent={(eventInfo) => {
              return (<>
                <div class="fc-event-title">{eventInfo.event.title}</div>
                <div class="fc-event-time"> - {dayjs(eventInfo?.event?.start).format("hh:mm A")}</div>
              </>);
            }}
            eventClick={(info) => {
              viewEvent(info.event);
            }}
            datesSet={(payload) => {
              setMonthDateArr([dayjs(payload?.start).format("YYYY-MM-DD"), dayjs(payload?.end).format("YYYY-MM-DD")]);
            }}
            windowResize={handleWindowResize}
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseLeave}
            dateClick={(info) => {
              if (dayjs().isSame(dayjs(info?.date), 'day') || dayjs().isBefore(dayjs(info?.date), 'day')) {
                setDefaultDate(dayjs(info?.date));
                setAddTourFormOpen(true);
              }
            }}
          />
        </div>
      </div>
    </div>

    <Dialog
      open={addTourFormOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddTourFormOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      <OpenTourFrom
        setAddTourFormOpen={setAddTourFormOpen.bind(this)}
        allProperties={allProperties}
        selectedProperty={selectedProperty}
        setSnackbarOpen={setSnackbarOpen.bind(this)}
        setSnackbarMsg={setSnackbarMsg.bind(this)}
        onSubmit={onAddTour.bind(this)}
        is_edit={false}
        defaultDate={defaultDate}
      />
    </Dialog>

    <Dialog
      open={openHouseFormOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenHouseFormOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      <OpenHouseFrom
        setOpenHouseFormOpen={setOpenHouseFormOpen.bind(this)}
        allProperties={allProperties}
        selectedProperty={selectedProperty}
        setSnackbarOpen={setSnackbarOpen.bind(this)}
        setSnackbarMsg={setSnackbarMsg.bind(this)}
        onSubmit={onAddOpenHouse.bind(this)}
        is_edit={false}
        selectedEvent={selectedEvent}
      />
    </Dialog>

    <Dialog
      open={viewEventOpen}
      fullWidth
      maxWidth="xs"
      onClose={(e) => setViewEventOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <EventView
        setOpenHouseFormOpen={setViewEventOpen.bind(this)}
        selectedEvent={selectedEvent}
        isTour={isTour}
        setOpenDeleteModal={setOpenDeleteModal.bind(this)}
        setOpenCompleteModal={setOpenCompleteModal.bind(this)}
        setEditEventOpen={setEditEventOpen.bind(this)}
      />
    </Dialog>

    <Dialog
      open={editEventOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setEditEventOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      {isTour && (
        <OpenTourFrom
          setAddTourFormOpen={setEditEventOpen.bind(this)}
          allProperties={allProperties}
          selectedProperty={selectedProperty}
          setSnackbarOpen={setSnackbarOpen.bind(this)}
          setSnackbarMsg={setSnackbarMsg.bind(this)}
          onSubmit={onUpdateTour.bind(this)}
          is_edit={true}
          selectedEvent={selectedEvent}
          defaultDate={dayjs()}
        />
      )}
      {!isTour && (
        <OpenHouseFrom
          setOpenHouseFormOpen={setEditEventOpen.bind(this)}
          allProperties={allProperties}
          selectedProperty={0}
          setSnackbarOpen={setSnackbarOpen.bind(this)}
          setSnackbarMsg={setSnackbarMsg.bind(this)}
          onSubmit={onUpdateOpenHouse.bind(this)}
          is_edit={true}
          selectedEvent={selectedEvent}
        />
      )}
    </Dialog>

    <Dialog
      open={openDeleteModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenDeleteModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the {isTour ? "Tour" : "Open House"} Record?<button onClick={(e) => setOpenDeleteModal(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The {isTour ? "Tour" : "Open House"} Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={deleteConfirm}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => setOpenDeleteModal(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openCompleteModal}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setOpenCompleteModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to mark as complete?<button onClick={(e) => setOpenCompleteModal(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Tour Record will be marked as complete immediately. You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => setOpenCompleteModal(false)}>Cancel</Button>
        <Button className="SubmitPopupBU" onClick={completeConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />
    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(Calendar, {
  onRedirecting: () => <Loader />,
});