import { useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../redux/actions/userInfoAction';
//import { usePubNub } from 'pubnub-react';
export default function GetUserInfo() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const dispacth = useDispatch();
    //const pubnub = usePubNub();

    useEffect(() => {
        const getInfo = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });


            await axios.get(process.env.REACT_APP_COMMUNICATION_API_URL + 'v1/user-details', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                console.log(response.data.data)

                dispacth(setUserInfo(response.data.data));

            }).catch(error => {
                console.log(error)
            });
        }
        if (isAuthenticated) { 
            getInfo();

        }
        
    }, [dispacth, isAuthenticated]);


    /*function subsCribeChannel(ownerId, aptId) {
        pubnub.subscribe({ channels: [ownerId + aptId], });
    }*/


    return (<></>)
}